import React, { useState,useEffect } from 'react';
import LeadQueue from '../leadQueue';
import WorkPhone from '../workPhone';
import SessionConvo from './conversation';
import LeadCardInfo from './leadCardInfo';
import { fetchWithDefaults } from '../../../config/fetchDefault';

function DialWindow({title,data, getLead, leadData,agentId, formatLastContacted,formatNumber}) {
    const [conversationModal, setconversationModal] = useState([]);
    const [convoModalExpand, setConvoModalExpand] = useState(false);
    const [leftRows, setLeftRows] = useState([]);
    const [customColumns, setCustomColumns] = useState([]);
    const [rightRows, setRightRows] = useState([]);
    const messageModal = async (lead) =>{
        if(convoModalExpand==false){
            setConvoModalExpand(true);
            try {
                const response = await fetchWithDefaults(`/getConvos?lead=${lead}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();

                setconversationModal(jsonData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        else{
            setConvoModalExpand(false);
        }
    }
    const getColumns = async () => {
        try {
            const response = await fetchWithDefaults(`/findColumns?agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            setCustomColumns(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const categorizeRows = () => {
        // Reset leftRows and rightRows
        setLeftRows([]);
        setRightRows([]);

        // Iterate through the data and categorize rows
        customColumns.forEach((leadItem) => {
            if (leadItem.left === null) {
                // Push to the 'right' element
                setRightRows((prevRightRows) => [...prevRightRows, leadItem]);
            } else if (leadItem.right === null) {
                // Push to the 'left' element
                setLeftRows((prevLeftRows) => [...prevLeftRows, leadItem]);
            }
        });

    };
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        
        // Check if the date is valid before formatting
        if (!isNaN(dateObject.getTime())) {
          // Format the date as mm/dd/YYYY
          return dateObject.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        } else {
          return dateString // You can customize the message for invalid dates
        }
    };
    const formatField=(inputString)=>{
        if (inputString.includes('co_borrower_')) {
            // Remove "borrower_" and capitalize the remaining string
            return inputString.replace('co_borrower_', 'Co Lead ').replace(/\b\w/g, (c) => c.toUpperCase());
       
        } else if (inputString.includes('borrower_')) {
        // Remove "co_borrower_" and capitalize the remaining string
            return inputString.replace('borrower_', 'Lead ').replace(/\b\w/g, (c) => c.toUpperCase());
        } else {
        // Capitalize the entire string
            return inputString.replace(/\b\w/g, (c) => c.toUpperCase());
        }
    }
    useEffect(() => {
        // Fetch data when the component mounts
        getColumns();
    }, []);
    useEffect(() => {
        categorizeRows();

    }, [customColumns]);
    useEffect(() => {


    }, [leftRows]);
    return (
        <>
            <div className="leftSideBarWrapper">
                <div className="workPhoneWrapper">
                    <WorkPhone />
                </div>
                <div className="leadQueueWrapper">
                    <LeadQueue getLead={getLead} messageModal={messageModal} title={title} data={data}/>
                </div>
            </div>
            <div className={`messageModal ${convoModalExpand ? 'showMessageModal' : ''}`}>
                <SessionConvo conversationModal={conversationModal} />
            </div>
            <div className="centerBarWrapper">
                <LeadCardInfo formatDate={formatDate} formatLastContacted={formatLastContacted} leadData={leadData} formatField={formatField} formatNumber={formatNumber}/>
            </div>
        </>
    );
}

export default DialWindow;