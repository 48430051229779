import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";

function FilterHolderBlock({filters,getMyFilter,title, open}) {
    //const filters=useSelector((state) => state.filters.filters);
    const [isOpen, setIsOpen] = useState(true);
    
    const innerWrapperRef = useRef(null);
    
    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    const handleOptionClick = (option) => {
        setIsOpen(!isOpen);
    };
    
    useEffect( () => {
        setIsOpen(open);
    },[])

    return (
        <span className="quilityNestedBlockWrapper" style={{ height: isOpen && getHeight() !==0 ? getHeight()+90 : '35px',maxHeight:'335px'}}>
            <div className="quilityNestedBlockHeader" onClick={()=>handleOptionClick()}>
                <div className="quilityParentBlockTitle">{title}</div>
                <span className="quilityBlockIcon"><FontAwesomeIcon className={`selectBoxIcon ${isOpen ? 'rotate':''}`} icon={faChevronUp}/></span>
            </div>
            <div className="quilityNestedBlockContent"  ref={innerWrapperRef} style={{maxHeight:'300px',overflow: 'auto'}}>
                {filters && filters.map((filter, index) => (
                    <div className="quilityDataLine" key={`filterOption${index}`} style={{display: 'flex'}}>
                        <div className="quilityDataLineTitle" style={{flex: '2'}}>{filter.name}</div>
                        <div className="quilityDataLineValue" style={{flex: '1',cursor:'pointer'}} onClick={()=>getMyFilter(filter.params,false,filter.name, filter)}>View</div>
                        <div className="quilityDataLineValue" style={{cursor:'pointer'}} onClick={()=>getMyFilter(filter.params,true,filter.name, filter)}>Dial</div>
                    </div>  
                ))}
            </div>
        </span>
    );
}

export default FilterHolderBlock;
