import React, {useState, useRef, useEffect} from 'react';
import '../../css/teams.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faTriangle } from '@fortawesome/pro-regular-svg-icons';
import Member from './detailComponents/member';

function TeamSection({title, color, team, details}) {

    const [isOpen, setIsOpen] = useState(false);
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);

    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    const innerWrapperRef = useRef(290);

    const openUp = () => {
        setIsOpen(!isOpen);
    };
    function removeBackslashes(inputString) {
        if (typeof inputString !== 'string') {
          return inputString;
        }
        return inputString.replace(/\\/g, '');
    }
    useEffect(() => { 

        const timeoutId = setTimeout(() => {
            if (innerWrapperRef.current) {
                setInnerWrapperHeight(innerWrapperRef.current.clientHeight);   
            }
        }, 250);
        return () => clearTimeout(timeoutId);
        
    }, [isOpen]);
    console.log(innerWrapperHeight);
    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`} style={{ height: isOpen && getHeight() !==0 ? getHeight()+35 : '35px' }} >
            <div className="quilityParentBlockHeader" onClick={()=>openUp()}>
               
                <div className="quilityParentBlockTitle" style={{width:'200px'}}>{team.name==='undefined' ? 'My Agency' : removeBackslashes(team.name)}</div>
                <span className="quilityBlockIcon"><FontAwesomeIcon className={`selectBoxIcon ${isOpen ? 'rotate':''}`} icon={faChevronUp}/></span>

                {team && (
                    <div className="typeWrapper">
                        <span className="typeBlock">Agents <span className="dataBlock">{team.members.length}</span></span>
                    </div>
                )}
            </div>
            
            <div className="quilityNestedBlockContent"  ref={innerWrapperRef}>
                {team.members.map((member, index) => (
                    <Member details={details} key={`teamMembers${index}`} member={member}/>
                ))}
            </div>
        </div>
    );
}

export default TeamSection;


