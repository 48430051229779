import React, {useState, useRef, useEffect} from 'react';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector, useDispatch } from 'react-redux';
import { SuccessAlert } from '../../../successAlert';


function DispositionBlock({removeTag,addTag,setSelectedTags,selectedTags, theCall, from, updateStatusName, handlCallAccepted, click, incrementQueue, queuePos, leadId, tags}) {    
    const dispatch= useDispatch();
    const agentInfo=useSelector((state) => state.agent.userData);
    const textingNumbers=useSelector((state) => state.agent.textingNumbers);
    const statuses=useSelector((state) => state.status.status);
    const [isOpen, setIsOpen] = useState(false);
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const innerWrapperRef = useRef(null);
    const [successTrigger, setSuccessTrigger] = useState(false);
    
    const openUp = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (innerWrapperRef.current) {
            setInnerWrapperHeight(innerWrapperRef.current.clientHeight);
        }
    }, [isOpen]);

    useEffect(() => {
        if(from==='session'){
            setIsOpen(true);
        }
    }, []);
    /*const groupByCondition = (array, property, condition, name) => {
        return array.reduce((accumulator, item) => {
            // Check if the item meets the specified condition
            if (condition(item)) {
            const key = item[property];
        
            // Check if the key doesn't exist in the accumulator, create an array for it
            if (!accumulator[name]) {
                accumulator[name] = [];
            }
        
            // Push the current object to the array for the specific key
            accumulator[name].push(item);
            }
        
            return accumulator;
        }, {});
    };
    
    const client = groupByCondition(statuses, 'application', item => item.application === 1,'client');
    const contact = groupByCondition(statuses, 'type', item => item.contact === 1 && item.type === 'active' && (item.application === 0 || item.application === null) && (item.appointment === 0 || item.appointment === null), 'contact');
    const noContact = groupByCondition(statuses, 'type', item => item.contact === 0 && item.type === 'active' && (item.application === 0 || item.application === null) && (item.appointment === 0 || item.appointment === null), 'no contact');
    const appointment = groupByCondition(statuses, 'appointment', item => item.appointment === 1, 'appointment');
    const dead = groupByCondition(statuses, 'type', item => item.type === 'dead', 'dead');
    */
    const statusLead= async (status)=>{
        
        theCall.disconnect();
        const statusId=status.id;
        const workflowId=status.workflow;
        const reminderId=status.reminder;

        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    leadId:leadId,
                    statusId:statusId,
                    workflowId:workflowId,
                    reminderId:reminderId,
                    status:status,
                    teamId:agentInfo.teamId,
                    from:from,
                    tags:tags,
                    textingNumbers:textingNumbers
                }),
            };
            
            const response = await fetchWithDefaults('/statusLead', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setSuccessTrigger(true);
            const jsonData = await response.json();
            incrementQueue(queuePos);
            handlCallAccepted(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const statusLead2= async (status)=>{
        //splitting the tags and find the the ones that are associated with dial amounts
        let callTag
        if(tags){
            callTag = tags.split(',').map(pair => {
                const [id, name] = pair.split('*');
                return { id, name };
            });
        }
        else{
            callTag=null;
        }

        handlCallAccepted(false);
        
        const statusId=status.id;
        const workflowId=status.workflow;
        const reminderId=status.reminder;

        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    leadId:leadId,
                    statusId:statusId,
                    workflowId:workflowId,
                    reminderId:reminderId,
                    status:status,
                    teamId:agentInfo.teamId,
                    from:from,
                    tags:callTag,
                    textingNumbers:textingNumbers
                }),
            };
            //setSuccessTrigger(true);
            const response = await fetchWithDefaults('/statusLead', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
           
            setSuccessTrigger(true);
            incrementQueue(queuePos);

            /*await jsonData.removeTag && jsonData.removeTag.forEach(tag => {
                removeTag(tag.id)
            });*/
            if(jsonData.addTag){
                addTag(jsonData.addTag, dispatch);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const statusLead3= async (status)=>{
        const statusId=status.id;
        const workflowId=status.workflow;
        const reminderId=status.reminder;
        updateStatusName(status.name);
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    leadId:leadId,
                    statusId:statusId,
                    workflowId:workflowId,
                    reminderId:reminderId,
                    status:status,
                    teamId:agentInfo.teamId,
                    from:'',
                    tags:selectedTags,
                    textingNumbers:textingNumbers
                }),
            };
            
            const response = await fetchWithDefaults('/statusLead', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            await jsonData.removeTag && jsonData.removeTag.forEach(tag => {
                removeTag(tag.id)
            });
            if(jsonData.addTag){
                addTag(jsonData.addTag, dispatch, setSelectedTags, selectedTags);
            }
            
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            <SuccessAlert message={'Status Changed'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger}/>
            <div className="statusButtonBlock" >
                {statuses.map((status, index) => (
                    <div key={`innerStatus${index}`} className="statusItem solidQuilityActiveButton" onClick={() => click ? statusLead2(status) : from==='dash' ? statusLead3(status) : statusLead(status)}>{status.name}</div>
                ))}
            </div>
        </>
    );
    
}

export default DispositionBlock;
                            
/*{statuses.map((item, index) => ( 
                    <div className="queueStatus">{item.name}</div>
                ))}*/



/*
//<div className="statusButtonBlock" style={{ height: isOpen && from==='session' ? '160px' : isOpen ? innerWrapperHeight + 25 : '0px', padding: 'unset', overflow: 'auto'}}>
//<div key={innerIndex} className="statusItem" onClick={() => click ? statusLead2(status) : from==='dash' ? statusLead3(status) : statusLead(status)}>{status.name}</div>
<div className="quilityParentBlockHeader" onClick={()=>openUp()} style={{backgroundColor: 'var(--quility-button-color)'}}>
                <div className="quilityParentBlockTitle" style={{color:'var(--quility-color-button-text-color)'}}>Disposition Actions</div>
                <span className='quilityBlockIcon' style={{color:'var(--quility-color-button-text-color)'}}>
                    <FontAwesomeIcon className={`transition ${isOpen ? 'rotate' : ''}`} icon={faChevronDown}/>
                </span>
            </div>

            */