import React, { useState, useEffect } from 'react';
import '../../css/phone.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneHangup, faKeyboard, faVoicemail, faMicrophoneLines, faVolumeSlash } from '@fortawesome/pro-regular-svg-icons';


function StaticPhone({theCall,selectedNumber, callState, currentLead, expanded}) {
    const [muted, setMute] = useState(false);
    const [openKeypad, setOpenKeypad] = useState(false);
    
    const hangup= async ()=>{
        theCall.disconnect();
    }
    const handleKeypad=()=>{
        setOpenKeypad(!openKeypad);
    }
    const mute= async ()=>{
        setMute((prevMute) => !prevMute);
        if(muted===true){
            theCall.mute(false);
        }
        else{
            theCall.mute(true);
        }
    }
    return (
       <>
        { currentLead!=null ? (
                <div className={`staticPhoneWrapper ${expanded ? 'openPhone' : ''}`}>
                    <div className="wrapperTopBar">
                        <div className="wrapperTopBarTitle">Session Active</div>
                    </div>
                    <div className="softPhoneLine">
                        <span className="lineTitle">Call With: </span>
                        <span className="callStatus">{currentLead.borrower_first} {currentLead.borrower_last}</span>
                    </div>
                    <div className="softPhoneLine">
                        <span className="lineTitle">Call Status: </span>
                        <span className="callStatus">
                            {callState}
                        </span>
                    </div>
                    <div className="softPhoneLine">
                        <span className="lineTitle">Last Contact: </span>
                        <span className="callStatus">{currentLead.last_contacted ? currentLead.last_contacted : 'Never Contacted'}</span>
                    </div>
                    <div className="softPhoneButtonWrapper">
                        <div className="phoneButtons">
                            <span className={`queuePhoneButton ${openKeypad ? 'active' : ''}`} onClick={()=>handleKeypad()}><FontAwesomeIcon icon={faKeyboard} /></span>
                            {openKeypad &&(
                                <div className="keypadHolder">
                                    <div className="keypadHeader">
                                        Keypad
                                    </div>
                                    <div className="keypadContent">
                                        <div className="keypadNumber"><span className="innerKeypad">1</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">2</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">3</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">4</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">5</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">6</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">7</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">8</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">9</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">#</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">0</span></div>
                                        <div className="keypadNumber"><span className="innerKeypad">*</span></div>
                                    </div>
                                </div>
                            )}
                            <span className="queuePhoneButton"><FontAwesomeIcon icon={faVoicemail} /></span>
                            <span className="queuePhoneButton"><FontAwesomeIcon icon={faMicrophoneLines} /></span>
                            <span className={`queuePhoneButton ${muted ? 'active' : ''}`}><FontAwesomeIcon icon={faVolumeSlash} onClick={()=>mute()}/></span>
                            <span className="queuePhoneButton"><FontAwesomeIcon icon={faPhoneHangup} onClick={()=>hangup()} /></span>
                        </div>
                    </div>
                </div>
            ):(
                null
            )}

        </>
    );

}

export default StaticPhone;