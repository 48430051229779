import React, {useState} from "react";
import { fetchWithDefaults } from '../config/fetchDefault';

function BugReport({openBugReport, agentId}) {
    const [title, setTitle] = useState('');
    const [showThanks, setShowThanks] = useState(false);
    const [description, setDescription] = useState('');

    const handleTitle = (event) => {
        setTitle(event.target.value);
    };
    const handleDescription = (event) => {
        setDescription(event.target.value);
    };
    const submitReport= async()=>{
       try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    title:title,
                    description:description
                }) 
            };
          
            const response = await fetchWithDefaults('/submitBug', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            console.log(jsonData);
            if(jsonData.status===true){
                setShowThanks(!showThanks);

                setTimeout(() => {
                    openBugReport();
                    setShowThanks(!showThanks);
                }, 1750);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <div className="reportContent">      
            <div className="closeWindow" onClick={()=>openBugReport()}>X</div>
            <div className="title">Beta Bug Report!</div>
            <div className={`thanks ${showThanks ? 'showThanks':''}`}>Thanks! Your Report has been received!</div>
            <div className="reportLine">
                <div className="subTitle" style={{textAlign: 'left'}}>Subject</div> 
                <div className="reportLineInputWrapper">
                    <input 
                        type="text" 
                        className="bugInput" 
                        placeholder="What is the bug about?"
                        value={title}
                        onChange={handleTitle}
                    />
                </div>
            </div> 
            <div className="reportLine">
                <div className="subTitle" style={{textAlign: 'left'}}>Description</div> 
                <div className="reportLineInputWrapper">
                    <textarea 
                        type="text" 
                        className="bugInput" 
                        style={{height:'300px'}} 
                        placeholder="Please be as specific as possbile"
                        value={description}
                        onChange={handleDescription}    
                    >

                    </textarea>
                </div>
            </div>  
            <div className="buttonWrapper">
                <div className="accentButton" style={{margin: 'auto',marginTop: '40px'}} onClick={()=>submitReport()}>Submit Report</div>
            </div>    
        </div>  
    );
}

export default BugReport;

