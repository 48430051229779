import React, {useState, useEffect} from 'react';
import ToggleSwitch from '../elements/toggleSwitch';
import { setQueue, setBackup } from '../../features/queueSlice';
import { sortIntoTiers,createMasterArray,sortObjectIntoArrays } from '../../utilities/smartSort';
import { useDispatch, useSelector } from 'react-redux';

function SmartSort({data}) {
    const dispatch = useDispatch();
    const [isToggled, setisToggled] = useState(false);
    const [backUpQueue, setBackupQueue] = useState(data);
    const backup=useSelector((state) => state.queue.originalQueue);
    const queue=useSelector((state) => state.queue.queue);
    
    
    const smartSort= async ()=>{
        dispatch(setBackup(data));
        const sortByLastContacted= await sortObjectIntoArrays(queue,'lead_type');
        const sortedByLastContactedWithTiers = await sortIntoTiers(sortByLastContacted);
        console.log(sortedByLastContactedWithTiers);
        const master = await createMasterArray(sortedByLastContactedWithTiers);
        console.log(master);
        dispatch(setQueue(master));
    }
    const handleToggle=()=>{
        setisToggled(!isToggled);
        console.log(backup);
        if(isToggled){
            dispatch(setQueue(backup));
        }
        else{
            console.log(queue);
            smartSort();
        }
    }
    useEffect( () => {
        if(isToggled){
            smartSort();
        }
    },[])
    
    return (
        <span className={`toggleWrapper ${isToggled ? 'activeToggle': ''}`}>
            <div className="toggleTitle">Smart Sort</div>
            <ToggleSwitch isToggled={isToggled} title={'hi'} handleToggle={handleToggle}/>
        </span>
    );
}

export default SmartSort;
