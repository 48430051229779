import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';



function Applications({details,formatDate, openLead,maxList,isMin}) {
    const [open, setOpen] = useState(false);
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    } 
    return (
        <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
            <div className='quilityNestedBlockHeader'>
                <div className="quilityParentBlockTitle" style={{width: '170px'}}>Applications</div>
                <span style={{marginLeft:'20px'}}>
                    <span className="typeBlock">{details.length} Apps</span>
                </span>
                <span className='quilityBlockIcon'><FontAwesomeIcon className={`transition ${open ? 'rotate' : ''}`} icon={faChevronUp}/></span>
            </div>

            <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                {details.map((detail, index) => (
                    <div className="detailItem" key={"callback"+index}>
                        <span className="detailTitle">{detail.statusChangeFirst} {detail.statusChangeLast}</span>
                        <span className="detailDate">{formatDate(detail.statusDate)}</span>
                        <span className="detailTimezone">{detail.statusName}</span>
                        <span className="ViewLead" onClick={(event) => { event.stopPropagation(); openLead(detail.statusChangeLead); }}>View Lead</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Applications;