import React, { useState, useEffect } from 'react';
import '../../css/queue.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersLine } from '@fortawesome/pro-regular-svg-icons';
import RightQueueHeader from './elements/rightQueueHeader';
import QueueScripts from './elements/scripts';
import LeadCardData from './elements/leadCard';
import QueueConvo from './elements/conversation';
import EditEvent from './elements/sections/editEvent';
import AddEvent from './elements/sections/addEvent';
import QueueNotes from './elements/notes';
import LeadsQueue from '../leads/leadsQueue.js';
import { fetchWithDefaults } from '../../config/fetchDefault';
import areaCodes from '../areaCodes';
import DialModule from './dialModule.js';
import { useSelector, useDispatch } from 'react-redux';
import { setQueue } from '../../features/queueSlice';
import Recommendation from './elements/sections/recommendation.js';




function QueueModule({setLoading, calledQueue, refreshQueue, newAppt, setNewAppt, setCalledQueue, setHoldingQueue, setLeadCard, expandSession, textingNumbers,handleUpdateQueueLead, openQueue, openLead, holdingQueue, expanded, open, setMyQueue, workParams, device, formatDate}) {
    const dispatch = useDispatch();
    const [updateQueuePos, setUpdateQueuePos] = useState(false);
    const [results, setResults] = useState(false);
    const [timestamp, setTimestamp] = useState(false);
    
    const queue=useSelector((state) => state.queue.queue);
    const resetQueuePositions = () => {
        const updatedData = queue.map((item, index) => {
            return {
                ...item,
                queuePos: index,
            };
        });
        setCurrentLead(updatedData[0]);
        setCurrentScript(updatedData[0].script);
        setCurrentLeadId(updatedData[0].lead_id);
        setUpdateQueuePos(true);
        dispatch(setQueue(updatedData));
    };
    const agentInfo=useSelector((state) => state.agent.userData);
    const numberInfo=useSelector((state) => state.agent.phoneNumbers);
    const [selected, setSelected] = useState(0);
    const [smartQueue, setSmartQueue] = useState('smart');
    const [calendar, setCalendar] = useState(agentInfo.defaultCalendar);
    /*const [states, setStates]=useState(false);
    const [contacts, setContacts]=useState(false);
    const [types, setTypes]=useState(false);*/
    const [dialAmount, setDialAmount]=useState(false);
    const [currentScript, setCurrentScript]=useState(null);
    const [amountSelector, setAmountSelector]=useState(false);
    const [currentLead, setCurrentLead]=useState([]);
    const [currentLeadId, setCurrentLeadId]=useState(false);
    const [dialGroupDone, setDialGroupDone]=useState(false);
    const [conversations, setConversations] = useState([]);
    const [filterParams, setFilterParams] = useState(workParams);
    const [callAccepted, setCallAccepted]=useState(false);
    const [newObjection, setNewObjection]=useState(false);

    const agentId = agentInfo.agentId;
    const teamId = agentInfo.teamId;
    const [call, setCall] = useState(null);
    const [event, setEvent] = useState(false);
    const [newEvent, setNewEvent] = useState(false);
    const [date, setDate] = useState(false);
    const [time, setTime] = useState(false);
    
    const initialNumbers = '';

    const defaultNumber = numberInfo[0].number;
    const [selectedNumber, setSelectedNumber] = useState(defaultNumber);
    //const [filterOpen, setFilterOpen] = useState(false);
    const [callState, setCallState] = useState('Disconnected');

    
    const setAppt= async (event,video)=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    team: teamId,
                    leadId:currentLeadId,
                    event:event,
                    video:video
                }),
            };
            const response = await fetchWithDefaults('/setAppointment', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setNewAppt(jsonData.event)
            setNewEvent(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    function searchAreaCode(areaCode) {
        const code = areaCode.toString().substring(0, 3);
        const foundState = areaCodes.find(state => {
        return Object.values(state).flat().includes(parseInt(code));
        });

        // If the area code is found, return the array of area codes
        // Otherwise, return an empty array
        return foundState ? Object.values(foundState)[0] : [];
    }
    function getFirstNonNullValue(...values) {
        for (const value of values) {
            if (value !== null && value !== undefined) {
                return value;
            }
        }
        return null; // Return null if all values are null or undefined
    }
    useEffect(() => {
        if(currentLead ){
            //gatherConversation();
            if(currentLead.call_from !== null && currentLead.call_from !==undefined){
                setSelectedNumber(currentLead.call_from);
            }
            else{
                if(currentLead.borrower_cell && numberInfo){
                    const clientNumberToSearch=getFirstNonNullValue(currentLead.borrower_cell,currentLead.borrower_home,currentLead.borrower_work);
                    if(clientNumberToSearch !== null){
                        const callingState=searchAreaCode(clientNumberToSearch)
                        let callFrom;
                        for (let i = 0; i < numberInfo.length; i++) {
                            const number = numberInfo[i].number;
                            const areaCode = parseInt(number.substring(2, 5));
                            if (callingState && callingState.includes(areaCode)) {
                                callFrom = number;
                                setSelectedNumber(callFrom);
                                break; 
                            }
                        }
                        
                    }
                }
            }
        }
    }, [currentLeadId]);

    const incrementQueue=(currentIndex)=>{
        if (currentIndex >= 0 && currentIndex < queue.length - 1) {
            // Get the next lead based on the currentIndex
            const nextLead = queue[currentIndex + 1];
         
            const updatedQueue = queue.map(item => {
                if (item.lead_id === currentLead.lead_id) {
                    return { ...item, called: true };
                }
                return item;
            });
            setHoldingQueue(updatedQueue);
            dispatch(setQueue(updatedQueue));
            setCurrentLead(nextLead);
            setCurrentLeadId(nextLead['lead_id']);
        } else {
            dispatch(setQueue(false));
            //setMyQueue(false);
            setCalledQueue([]);
        }
    }
    const handleNewObjection=(objection)=>{
        setNewObjection(objection);
    }
  
    
    
    const selectTab=(index)=>{
        setSelected(index)
    }
    
    
    const openDialAmount=()=>{
        setAmountSelector(!amountSelector);
    }
    
    const closeObjection=()=>{
        setNewObjection(false);
    }
    useEffect(() => {
        if(queue){
            setCurrentLead(queue[0]);
            setCurrentScript(queue[0].script);
            setCurrentLeadId(queue[0].lead_id);
        }
    },[filterParams,refreshQueue,updateQueuePos])
    useEffect(() => {
        if(expanded){
            resetQueuePositions();
            
        }
    },[expanded])
    let outgoingCall='';
    const makeDial= async (toNumber, leadName, fromNum)=>{
        let dialFrom;
        if(fromNum){
            dialFrom=fromNum;
        }
        else{
            dialFrom=selectedNumber;
        }
        //var param = { "recordSide":$("#recordSide").val(),"recordCalls":$("#recordCalls").val(),"record":$("#record").val()};
        outgoingCall= await device.connect({ params: { To: '+1'+toNumber , callerId: dialFrom, user:agentId, tone: "default", leadId:currentLeadId }});
        setCall(outgoingCall);
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    team: teamId,
                    agentNumber:dialFrom,
                    clientNumber:toNumber,
                    leadId:currentLeadId
                }),
            };
            
            const response = await fetchWithDefaults('/queueDial', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const dialPackage=[{makeDial:makeDial,setDialGroupDone:setDialGroupDone, incrementQueue:incrementQueue, myQueue: queue, currentLead:currentLead, dialAmount:dialAmount, device:device}];
    const handlCallAccepted=(type)=>{
        setCallAccepted(type);
    }
   /* const toggleFilter = () => {
        setFilterOpen(!filterOpen);
    };*/
    const newEventPackage=[{setNewEvent:setNewEvent, setTime:setTime, setDate:setDate}]

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTimestamp = Math.floor(Date.now() / 1000);
            setTimestamp(currentTimestamp);
        }, 1000);
  
        return () => clearInterval(interval);
    }, []);
    
    const expiration=(parseInt(currentLead.exclusivity) - parseInt(timestamp));
    if(queue && updateQueuePos){
        return (
            <div className={` ${open ? 'queueModuleWrapper' : 'queueModuleTab'}`}>         
                {open ? (
                    <div className={`queueWrapper ${expanded ? 'fullScreen' : ''}`}>
                        <div className="quilityCloseWindow oppositeColor" onClick={()=>openQueue()}>X</div>
                        <div className="innerQueueWrapper">
                            <div className="actualQueue">
                                <div className="queueTabs">
                                    <div className={`queueTitle quilityActive`}>
                                        <div className="titleInner">My Queue</div>
                                    </div>
                                </div>
                                <DialModule callState={callState} setSelectedNumber={setSelectedNumber} expanded={expanded} dialAmount={dialAmount} setMyQueue={setMyQueue} amountSelector={amountSelector} device={device} selectedNumber={selectedNumber} call={call} currentLead={currentLead}  setDialAmount={setDialAmount} openDialAmount={openDialAmount} handlCallAccepted={handlCallAccepted} expandSession={expandSession} />
                                <div className="queueFilterButtons">
                                    
                                </div>
                                
                                {smartQueue == 'smart' ? (
                                    <LeadsQueue setLeadCard={setLeadCard} formatDate={formatDate} data={queue} setMyQueue={setMyQueue} calledQueue={calledQueue} openLead={openLead} queueExpanded={expanded} holdingQueue={holdingQueue.results}/>
                                ) : (
                                    <LeadsQueue setLeadCard={setLeadCard} formatDate={formatDate} data={queue} calledQueue={calledQueue} openLead={openLead} queueExpanded={expanded} holdingQueue={holdingQueue.results}/>
                                )}
                            </div>
                            {expanded ? (
                                <>
                                    <div className="centerQueue">
                                        <LeadCardData setResults={setResults} textingNumbers={textingNumbers} newAppt={newAppt} setNewAppt={setNewAppt} setCallState={setCallState} handleUpdateQueueLead={handleUpdateQueueLead} makeDial={makeDial} selectedNumber={selectedNumber} newEventPackage={newEventPackage} setEvent={setEvent} handlCallAccepted={handlCallAccepted} callAccepted={callAccepted} dialPackage={dialPackage} dialGroupDone={dialGroupDone} lead={currentLead} theCall={call} leadId={currentLead.lead_id} />
                                    </div>
                                    <div className="rightQueue">
                                        <RightQueueHeader selected={selected} selectTab={selectTab}/>
                                        {selected==0 ? (
                                            <QueueScripts handleNewObjection={handleNewObjection} currentLead={currentLead} scriptId={currentScript}/>
                                        ) : selected==1 ? (
                                            <QueueConvo expiration={expiration} setLoading={setLoading} currentLead={currentLead} lead={currentLead} textingNumbers={textingNumbers} setConversations={setConversations} conversations={conversations} agentId={agentId} teamId={teamId} currentLeadId={currentLeadId} />
                                        ) : selected==2 ?(
                                            <QueueNotes notes={currentLead.notes} leadId={currentLead.lead_id} agentId={agentId} plan={currentLead.policy_plan}/>
                                        ) : (
                                            //<Recommendation results={results} />
                                            null
                                        )}
                                        
                                    </div>
                                </>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='innerTab' onClick={()=>openQueue()}>
                        <span className="tabTitle">My Queue</span><span className="tabIcon"><FontAwesomeIcon icon={faUsersLine} /></span>
                    </div>
                )}
                {event &&(
                    <EditEvent setEvent={setEvent} calendar={calendar} event={event} />
                )}
                {newEvent &&(
                    <AddEvent setAppt={setAppt} calendar={calendar} setNewEvent={setNewEvent} agentTimezone={agentInfo.timezone} date={date} time={time} currentLead={currentLead} />
                )}
                {newObjection &&(
                    <div className="objectionViewWrapper">
                        <div className="objectionInnerWrapper">
                            <div className="objectionTitle">{newObjection.name}</div>
                            <div className="closeWindow " onClick={()=>closeObjection()}>X</div>
                            <div className="objectionContent" dangerouslySetInnerHTML={{ __html: newObjection.content }}></div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default QueueModule;


/***
 * <FilteringOptions queueExpanded={expanded} setHoldingQueue={setHoldingQueue} holdingQueue={holdingQueue} filterOpen={filterOpen}/>
 * 
 * 
 * <div className={`filterIcon ${filterOpen ? 'quilityActive' : ''}`} onClick={()=>toggleFilter()}><FontAwesomeIcon icon={faFilterList} /></div>
                                    {holdingQueue && filterOpen && (
                                        <>
                                            <div className="saveAsQueue solidQuilityActiveButton" onClick={()=>addToQueue(holdingQueue.search)}>Add ({holdingQueue.results.length})</div>
                                            <div className="saveAsQueue solidQuilityActiveButton"  onClick={()=>replaceQueue(holdingQueue.search)}>Replace</div>
                                            <div className="saveAsQueue solidQuilityDestructiveButton" onClick={()=>handlingClearHolding(false)}>Clear </div>
                                        </>

                                    )}
                                    {!filterOpen && (
                                        setHoldingQueue(false)
                                    )}
 * 
 * 
 */