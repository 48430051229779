import React, {useEffect, useState} from 'react';
import '../../css/leadCard.css';
import { gatherLeadAppointmentData } from '../../utilities/appointmentFunctions';
import { unixTommddhia } from '../../utilities/dashFunction';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/pro-regular-svg-icons";


function LeadCardAppointments({appointments, lead}) {
    const [foundAppts, setFoundAppts] = useState([])
    let appointmentHolder=[];
    if(appointments){
        appointmentHolder=appointments.split(',');
    }
    // const agentId=useSelector((state) => state.agent.userData).agentId;
    //const [leadsTags, setLeadsTags] = useState([]);

    useEffect(() => {
        ///This funciton is going to gather the actual appointment information for the lead.
        async function fetchData() {
            const response = await gatherLeadAppointmentData(lead);
            setFoundAppts(response)
        }
        fetchData();
    }, []);
    return (
        <div className="appointmentsWrapper">
            <div className="tagIcon"><FontAwesomeIcon icon={faCalendarCheck} /></div>
            <div className="appointmentWrapperContent">
                {foundAppts.length > 0 && foundAppts.map((appointment, index) => (
                    <div className="appointmentWrap">
                        <div className="dataFieldTitle" style={{maxWidth:'unset',width:'100%'}}>{appointment.title}</div>
                        <div className="leadInfoLine">
                            <div className="dataFieldTitle">Type: </div>
                            <div className="leadData">{appointment.type}</div>
                        </div>
                        <div className="leadInfoLine">
                            <div className="dataFieldTitle">Date: </div>
                            <div className="leadData">{unixTommddhia(appointment.date)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LeadCardAppointments;