import React, { useState } from 'react';
import { fetchWithDefaults } from './config/fetchDefault';

function UpgradePassword({agentUsername,setAgentIdNumber,saveData,setSignedIn}) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [requirements, setRequirements] = useState({
        length: false,
        uppercase: false,
        specialChar: false,
        match: false,
    });
  
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
    
        // Check password requirements
        const lengthCheck = newPassword.length >= 12;
        const uppercaseCheck = /[A-Z]/.test(newPassword);
        const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
    
        setRequirements({
            length: lengthCheck,
            uppercase: uppercaseCheck,
            specialChar: specialCharCheck,
            match: confirmPassword === newPassword,
        });
    
        setPassword(newPassword);
    };
  
    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
    
        // Check if passwords match
        setRequirements({
            ...requirements,
            match: password === newConfirmPassword,
        });
    
        setConfirmPassword(newConfirmPassword);
    };
    const submitPassword= async()=>{
        if (requirements.match) {
            try {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({ 
                        username: agentUsername,
                        password: confirmPassword,
                    }) 
                };
                
                const response = await fetchWithDefaults('/upgradePassword', requestOptions);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                setAgentIdNumber(jsonData[0].id);
                saveData(jsonData[0].cipher);
                setSignedIn(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            
        } else {

        }
        
    }
    return (
        <div className="warningWrapper">
            <div className="warningBody" style={{height: '350px',maxWidth:'750px'}}>
                <div className="warningHeader">
                    Update your password
                </div>
                <div className="warningContent">
                    Please update your password below.
                    <div className="inputHolder">
                        <div>
                            <div style={{margin: '20px 0px 10px'}}>Password:</div>
                            <input
                                type="password"
                                className="standardInputBox"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </div>
                        <div>
                            <div style={{margin: '20px 0px 10px'}}>Confirm Password:</div>
                            <input
                                type="password"
                                className="standardInputBox"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                style={{
                                    borderColor: requirements.match ? 'green' : 'red',
                                }}
                            />
                        </div>

                        <div className="requirements">
                            <ul>
                            <li style={{ color: requirements.length ? 'green' : 'red' }}>Minimum 12 characters</li>
                            <li style={{ color: requirements.uppercase ? 'green' : 'red' }}>At least one uppercase letter</li>
                            <li style={{ color: requirements.specialChar ? 'green' : 'red' }}>At least one special character</li>
                            <li style={{ color: requirements.match ? 'green' : 'red' }}>Passwords match</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="warningButtonHolder">
                    <div className="warningButtonWrapper">

                        <div className="accentButton warningButton" key={'warning2'} onClick={()=> submitPassword()}>Submit</div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpgradePassword;