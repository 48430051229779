import { fetchWithDefaults } from '../config/fetchDefault';

const runVM = async (profile, setResults, leadId) => {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                leadId:leadId,
                profile:profile,
            }) 
        };
      
        const response = await fetchWithDefaults('/virtualMentor/search', requestOptions);
        
        if (!response.ok) {
            return false;
        }
        const jsonData = await response.json();
        if(jsonData){
            setResults(jsonData);
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
};
export {runVM};