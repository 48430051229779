import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangle} from '@fortawesome/pro-regular-svg-icons';


function EditEvent({event,setEvent}) {
    const [wholeEvent, setWholeEvent] = useState(event[0]);
    const handleInputChange = (fieldName, value) => {
        setWholeEvent(prevEvent => ({
            ...prevEvent,
            [fieldName]: value // Update the specific field in the wholeEvent object
        }));
    };
    const handleParticipantEmailChange = (index, value) => {
        const updatedParticipants = [...wholeEvent.participant]; // Copy the array of participants
        updatedParticipants[index] = {
            ...updatedParticipants[index],
            email: value // Update the email of the specific participant
        };
        setWholeEvent(prevEvent => ({
            ...prevEvent,
            participant: updatedParticipants // Update the participants array in the wholeEvent object
        }));
    };
    const handleRemoveParticipant = (index) => {
        const updatedParticipants = wholeEvent.participant.filter((_, i) => i !== index);
        setWholeEvent(prevEvent => ({
            ...prevEvent,
            participant: updatedParticipants
        }));
    };
    const getTimezoneFromTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = { timeZoneName: 'long' };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const timeZoneName = formatter.formatToParts(date).find(part => part.type === 'timeZoneName').value;
        
        // Extract the continent/city part from the timeZoneName
        const matches = timeZoneName.match(/\b[A-Z][a-z]+\/[A-Z][a-z]+\b/);
        return matches ? matches[0] : timeZoneName;
    };
    function splitDateTime(dateTimeString) {
        const [datePart, timePart] = dateTimeString.split('T');
        return {
            date: datePart.split('-')[0],
            time: timePart ? timePart.split('-')[0] : ''
        };
    }
    const generateTimeSlots = () => {
        const timeSlots = [];
        const startTime = new Date();
        startTime.setHours(6, 0, 0, 0); // Set to midnight
        const endTime = new Date();
        endTime.setHours(23, 45, 0, 0); // Set to 11:45 PM

        const interval = 15 * 60 * 1000; // 15 minutes in milliseconds

        let currentTime = new Date(startTime);
        while (currentTime <= endTime) {
        timeSlots.push(currentTime);
        currentTime = new Date(currentTime.getTime() + interval);
        }

        return timeSlots;
    };
    const formatTime = (time) => {
        const hours = time.getHours();
        const minutes = time.getMinutes();
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert 0 to 12
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
    };
    function convertTo24HourTime(dateString) {
        const date = new Date(dateString);
        const formattedTime = date.toLocaleTimeString('en-US', {hour12: false});
        return formattedTime;
    }
    const handleCancel=()=>{
        setEvent(false);
    }
    return (
        <div className="eventWindow">
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Meeting Title</div>
                <input
                    type="text"
                    className="eventInput"
                    value={wholeEvent.Summary}
                    onChange={e => handleInputChange("Summary", e.target.value)}
                />
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Timezone</div>
                <input
                    type="text"
                    className="eventInput"
                    value={getTimezoneFromTimestamp(wholeEvent.Start)}
                    onChange={e => handleInputChange("Start", e.target.value)}
                />
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Location</div>
                <input
                    type="text"
                    className="eventInput"
                    value={wholeEvent.Location}
                    onChange={e => handleInputChange("Location", e.target.value)}
                />
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Participants</div>
                {wholeEvent.participant.map((person, index) => (
                    <div className="participantWrapper">
                        <span className="remove" onClick={() => handleRemoveParticipant(index)}>x</span>
                        <input
                            type="text"
                            className="eventInput"
                            value={person.email}
                            onChange={e => handleParticipantEmailChange(index, e.target.value)}
                        />
                    </div>
                ))}
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Meeting Comments</div>
                <textarea
                    type="text"
                    className="eventInput eventTextarea"
                    value={wholeEvent.Description}
                    onChange={e => handleInputChange("Description", e.target.value)}
                />
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Meeting Date & Tssime</div>
                <input
                    type="date"
                    className="eventInput halfSize"
                    value={splitDateTime(wholeEvent.Start).date}
                    onChange={e => handleInputChange("Start", e.target.value)}
                    style={{width:'30%'}}
                />
                <span className="spacer">
                    @ 
                </span>
                <select className="timeSelect">
                    {generateTimeSlots().map((time, index) => {
                        const selected = convertTo24HourTime(time) === splitDateTime(wholeEvent.Start).time;
                        return (
                            <option key={index} value={convertTo24HourTime(time)} selected={selected}>
                                {formatTime(new Date(time))}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Duration</div>
                <input
                    type="text"
                    className="eventInput"
                    value={wholeEvent.duration}
                    onChange={e => handleInputChange("duration", e.target.value)}
                    style={{width:'75px'}}
                />
                <span className="spacer" style={{padding: '5px 10px'}}>Minutes</span>
            </div>
            <div className="buttonWrapper" style={{textAlign: 'center', padding:'40px'}}>
                <div className="standardButton cancelButton" onClick={()=>handleCancel()}>Cancel</div>
                <div className="standardButton saveButton">Save</div>
            </div>
        </div>
    );
    
}

export default EditEvent;
