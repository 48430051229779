import { fetchWithDefaults } from '../config/fetchDefault';
import { setUserData, setPhoneNumbers, setActiveCalendar, setTextNumbers } from '../features/agentSlice';
import { setConvoFlow } from '../features/conversationFlowSlice';
import { setStates } from '../features/leadStateSlice';
import { setLeadType } from '../features/leadTypeSlice';
import { setObjections } from '../features/objectionsSlice';
import { setReminders } from '../features/remindersSlice';
import { setScopes } from '../features/scopesSlice';
import { setScripts } from '../features/scriptsSlice';
import { setStatus } from '../features/statusSlice';
import { setTags } from '../features/tagSlice';
import { setTexts } from '../features/textSlice';
import { setVariables } from '../features/variableSlice';
import { setWorkflows } from '../features/workflowSlice';
import { setConversations } from '../features/conversationSlice';
import { setEmails } from '../features/emailSlice';
import { setFilters, setActiveFilterName } from '../features/filterSlice';

const setActiveFilter = (name, dispatch) => {
    dispatch(setActiveFilterName(name));
}
const fetchAppInformation = async (setAppInfo, dispatch, agentIdNumber, organization) => {
    try {
        const response = await fetchWithDefaults(`/appInfo?agent=${agentIdNumber}&organization=${organization}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        dispatch(setActiveCalendar(jsonData[0].agent[0].defaultCalendar));
        dispatch(setUserData(jsonData[0].agent[0]));
        dispatch(setPhoneNumbers(jsonData[0].agent[1]));
        dispatch(setConvoFlow(jsonData[0].convoFlow));
        dispatch(setLeadType(jsonData[0].leadTypes));
        dispatch(setObjections(jsonData[0].objections));
        dispatch(setStates(jsonData[0].states));
        dispatch(setReminders(jsonData[0].reminders));
        dispatch(setScopes(jsonData[0].scopes));
        dispatch(setScripts(jsonData[0].scripts));
        dispatch(setStatus(jsonData[0].status));
        dispatch(setTags(jsonData[0].tags));
        dispatch(setTexts(jsonData[0].texts));
        dispatch(setVariables(jsonData[0].variables));
        dispatch(setWorkflows(jsonData[0].workflows));
        dispatch(setEmails(jsonData[0].emails));
        setAppInfo(jsonData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const fetchActivity = async (agentIdNumber,setActivity) => {

    try {
        const response = await fetchWithDefaults(`/Activity?agent=${agentIdNumber}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setActivity(jsonData);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    
}
const getConversations = async (handleSetUnread, agentIdNumber, dispatch) => {
    if(agentIdNumber!==false){
        try {
            const response = await fetchWithDefaults(`/getConvos?agent=${agentIdNumber}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let unreadMessageCount = 0;
            const jsonData = await response.json();
            // Iterate through the JSON data and count the objects with message_read = 'no'
            if(jsonData.length !== undefined){
                jsonData.forEach(obj => {
                    if (obj.message_read === 'no') {
                        unreadMessageCount++;
                    }
                });
            }
            handleSetUnread(parseInt(unreadMessageCount));
            if(!jsonData.status){
                if(jsonData.status !== false){
                    dispatch(setConversations(jsonData));
                }
            }
            

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
}


const fetchFilterData = async (dispatch,agentIdNumber) => {
    const response = await fetchWithDefaults('/mainPageData?agent='+agentIdNumber);
    if (!response.ok) {

        throw new Error('Network response was not ok');
    }
    const jsonData = await response.json();
    if(jsonData.length > 0){
        dispatch(setFilters(jsonData));
    } 
};
const saveTextingNumbers = async (dispatch, numbers) => {
    dispatch(setTextNumbers(numbers));
}
export {fetchAppInformation, getConversations, fetchFilterData, fetchActivity, setActiveFilter, saveTextingNumbers};