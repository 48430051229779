import React, { useState, useEffect } from 'react';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const ConditionInfo = ({ addCondition, usedMedications, addUsedMedications }) => {
    const [usedCondition, setUsedCondition] = useState('');
    const [conditions, setConditions] = useState([]);
    const [filteredConditions, setFilteredConditions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const fetchData = async () => {
        try {
            const response = await fetchWithDefaults(`/medicalConditions`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setConditions(jsonData);
            setFilteredConditions(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (event) => {
        const input = event.target.value;
        setUsedCondition(input);

        // Filter conditions based on user input
        const filtered = conditions.filter(condition =>
            condition.search_name && condition.search_name.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredConditions(filtered);

        // Open the dropdown if there are filtered conditions
        setIsDropdownOpen(filtered.length > 0);
    };

    const handleConditionSelect = (condition) => {
        addCondition(condition);
        setUsedCondition('');
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{ width: '50%',display:'inline-block',verticalAlign: 'top'}}>
            <div className="hybridInputWrapper">
                <input
                    type="text"
                    className="qualifierFieldInput"
                    value={usedCondition}
                    onChange={handleInputChange}
                    placeholder="Condition name..."
                    style={{ paddingRight: '40px' }}
                />
                <span className="medSearchIcon" onClick={()=>handleConditionSelect(usedCondition)}><FontAwesomeIcon icon={faPlus} /></span>
            </div>
            {isDropdownOpen && (
                <div className="qualDropDownMenu">
                    {filteredConditions.map((condition, index) => (
                        console.log(condition),
                        <div key={index} className="qualDropdownItem" onClick={() => handleConditionSelect(condition.search_name)}>
                            {condition.search_name} 
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ConditionInfo;
