import React, { useState,useEffect } from "react";
import '../../css/trust.css';
import {formatPhoneNumber} from '../../utilities/dashFunction.js';
import {deleteService, addServiceNumber, deleteServiceNumber} from '../../utilities/trustHubFunctions.js';
import { useSelector } from 'react-redux';

    
    
function MessagingService({data, fetchServices, setNumberChoice, serviceInfo,formatString,setAsCompliant, setNewCampaign, newService}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const serviceInUse=false;
    const [activeService, setActiveService] = useState(false);
    const [services, setServices] = useState(false);
    useEffect(() => {
        if (serviceInfo.length > 0) {
            setServices(serviceInfo);
        }
    }, [serviceInfo]);
    useEffect(() => {
        fetchServices()
    },[newService])
    const removeService=(camp, service)=>{
        if(camp==='VERIFIED'){
            alert("Can't Delete");
        }
        else{
            deleteService(service,agentId,setServices);
        }
    }
    const handleRemoveFromService=(numberSid, messageSid)=>{
        deleteServiceNumber(messageSid, agentId, numberSid, services, setServices);
    }
    return (
        <div className="mainBlockWrapper twoX">
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">Messaging Campaign</div>
            </div>
            
            <div className="mainBlockContent">
                {activeService && (
                    <div key={activeService.sid} className={`bigBlock a2p${activeService.status} inUse`} style={{marginTop: '25px'}}>            
                        <div className="quilityCloseWindow oulineQuilityDestructiveButton" onClick={()=>removeService(activeService.status, activeService.sid)}>X</div>
                        <div className="leadCardBlock">
                            <div className="innerWrapper">
                                <div className="leadInfoLine">
                                    <span className="dataFieldTitle">Name: </span>
                                    <span className="leadData">{activeService.name}</span>
                                </div>
                                <div className="leadInfoLine" style={{ backgroundColor: activeService.status != null ? '' : 'var(--quility-button-color)' }}>
                                    <span className="dataFieldTitle">Status: </span>
                                    <span className="leadData">{activeService.status != null ? formatString(activeService.status) : <span onClick={()=>setNewCampaign(activeService.sid)} style={{cursor:'pointer'}}>Click To Attach Campaign</span>}</span>
                                </div>
                                <div className="leadInfoLine">
                                    <span className="dataFieldTitle">Use Case: </span>
                                    <span className="leadData">{activeService.use_case != null ? formatString(activeService.use_case) : "No Use Case"}</span>
                                </div>
                                <div className="leadInfoLine">
                                    <span className="dataFieldTitle">Numbers: </span>
                                    <span className="leadData" style={{width: '57%', whiteSpace: 'unset',textDecoration: 'none'}}>
                                        {activeService.phoneNumbers.length > 0 ? (
                                            activeService.phoneNumbers.map((number, index) => (
                                                number.phoneNumber+', '
                                            ))
                                        ) : (
                                            <div className="addNumberButton" onClick={()=>setNumberChoice({type:activeService.name.includes('LOW_VOLUME') ? 'STANDARD' : 'sole',service:activeService.sid})}>+ Add Numbers</div>
                                        )}
                                    </span>
                                </div>
                                <div className="leadInfoLine" style={{backgroundColor: '#c5d9f099'}}>
                                    <span className="dataFieldTitle">In-Use: </span>
                                    <span className="leadData">Currently Active</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {services && services.map((item, index) => (
                    item.sid !== serviceInUse ? (
                        <div key={`services${index}`} className={`bigBlock a2p${item.status} ${item.sid === serviceInUse ? 'inUse' : ''}`} style={{marginTop: '25px'}}>            
                            <div className="quilityCloseWindow oulineQuilityDestructiveButton" onClick={()=>removeService(item.status, item.sid)}>X</div>
                            <div className="leadCardBlock">
                                <div className="innerWrapper">
                                    <div className="leadInfoLine">
                                        <span className="dataFieldTitle">Name: </span>
                                        <span className="leadData">{item.name}</span>
                                    </div>
                                    <div className="leadInfoLine" style={{ backgroundColor: item.status != null ? '' : 'var(--quility-button-color)' }}>
                                        <span className="dataFieldTitle">Status: </span>
                                        <span className="leadData">{item.status != null ? formatString(item.status) : <span onClick={()=>setNewCampaign(item.sid)} style={{cursor:'pointer'}}>Click To Attach Campaign</span>}</span>
                                    </div>
                                    <div className="leadInfoLine">
                                        <span className="dataFieldTitle">Use Case: </span>
                                        <span className="leadData">{item.use_case != null ? formatString(item.use_case) : "No Use Case"}</span>
                                    </div>
                                    <div className="leadInfoLine">
                                        <span className="dataFieldTitle">Numbers: </span>
                                        <span className="leadData" style={{textDecoration: 'none'}}>
                                            {item.phoneNumbers.length > 0 ? (
                                                <>
                                                <div className="addNumberButton" onClick={()=>setNumberChoice({type: item.name.includes('LOW_VOLUME') ? 'STANDARD' : 'sole',service:item.sid})}>+ Add Numbers</div>
                                                {item.phoneNumbers.map((number, index) => (
                                                    <div className="serviceNumber">{formatPhoneNumber(number.phoneNumber)} <span className="smallQuilityRemove oulineQuilityDestructiveButton" onClick={()=>handleRemoveFromService(number.sid, item.sid)}>x</span></div>
                                                ))}
                                                </>
                                            ) : (
                                                <div className="addNumberButton" onClick={()=>setNumberChoice({type: item.name.includes('LOW_VOLUME') ? 'STANDARD' : 'sole',service:item.sid})}>+ Add Numbers</div>
                                            )}
                                        </span>
                                    </div>
                                    <div className="leadInfoLine">
                                        <span className="dataFieldTitle">In-Use: </span>
                                        <span className="leadData" >{item.sid === data[0].soleBrand || item.sid === data[0].hubBrand ? 'Active' : <div className="quilitySmallInlineButton" style={{display: 'inline-block',height: '25px',lineHeight: '25px'}} onClick={()=>alert("HI")}>Make Default</div>}</span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    ): (
                        null
                    )
                    
                ))} 
            </div>
            <div className="blockFooter">
                <div
                    className="buttonWrapper"
                    style={{marginLeft: "10px" }}
                >
                    <div className="dataButtonBlock">Services: {services.length}</div>
                    <div className="actionButtonBlock" style={{marginTop: '5px'}}>
                        <div className="accentButton solidQuilityActiveButton" onClick={()=>setNewCampaign(true)}>
                            +  New Campaign
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default MessagingService;

/*<div className="leadInfoLine">
                                        <span className="dataFieldTitle">In-Use: </span>
                                        <span className="leadData" >{item.sid === data[0].soleBrand || item.sid === data[0].hubBrand ? 'Active' : <div className="quilitySmallInlineButton" style={{display: 'inline-block',height: '25px',lineHeight: '25px'}}>Make Default</div>}</span>
                                    </div>*/
