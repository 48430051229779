import React, {useState, useEffect} from 'react';
import '../../css/leadCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faMapLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { fetchWithDefaults } from '../../config/fetchDefault';

function ClientCard({data, agentId}) {
    const addressFields=['address','city','state','zip','county'];
    const topColumns=[{'borrower_first':"Lead First"},{'borrower_last':"Lead Last"}, {'borrower_cell':"Cell"},{'borrower_home':"Home"},{'email':"Email"}];
    const [editModes, setEditModes] = useState(new Array(topColumns.length).fill(false));
    const [editedValues, setEditedValues] = useState(new Array(topColumns.length).fill(''));

    const updateValue = async (lead, value, column) => {
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    lead: lead,
                    column: column,
                    value: value
                }) 
            };
            const response = await fetchWithDefaults('/updateLead', requestOptions);
        
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const jsonData = await response.json();
            console.log(jsonData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const enterEditMode = (index, initialValue) => {
        if(initialValue==='Click To Edit'){
            initialValue="";
        }
        const updatedEditModes = [...editModes];
        updatedEditModes[index] = true;
        setEditModes(updatedEditModes);

        const updatedEditedValues = [...editedValues];
        updatedEditedValues[index] = initialValue;
        setEditedValues(updatedEditedValues);
    };

    const exitEditMode = (index) => {
        const updatedEditModes = [...editModes];
        updatedEditModes[index] = false;
        setEditModes(updatedEditModes);
    };

    const handleInputChange = (index, e) => {
        const updatedEditedValues = [...editedValues];
        updatedEditedValues[index] = e.target.value;
        setEditedValues(updatedEditedValues);
    };

    const handleSave = (index, lead, column, value) => {
        updateValue(lead, value, column)
        exitEditMode(index);
    };

    ///format numbers
    function formatNumber(phoneNumber){
        const cleaned = phoneNumber.toString().replace(/\D/g, ''); // Remove all non-digit characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber; 
    }
    function formatLastContacted(dateTimeStr) {
        const date = new Date(dateTimeStr);

        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        const formattedHours = hours % 12 || 12;

        return `${month}/${day} @ ${formattedHours}:${minutes} ${ampm}`;
    }


    function formatBirthDate(inputDate) {
        // Create a Date object from the input date string
        const date = new Date(inputDate);
      
        // Get the month, day, and year
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
      
        // Format the date as mm/dd/YYYY
        const formattedDate = `${month}/${day}/${year}`;
      
        return formattedDate;
    }
      

    let address = '';
    return (
        <div className="leadCardContent">
            <div className="leadCardTitle">{data[0].borrower_first} {data[0].borrower_last}</div>
            <div className="leadTypeLabels">Lead Type: {data[0].lead_type}</div>
            <div className="focusLastContact">Last Contacted - <span className="lastCalledDate">{data[0].last_contacted ? formatLastContacted(data[0].last_contacted) : 'Never Contacted'} </span></div>
            <div className="leadTimezone">
            {data[0].timezone ? "Lead Timezone: "+data[0].timezone : 'Timezone Unknown - Zip Code Invalid'}    
            </div>
            <span className="print">
                <div className="propertyAddress">
                    {addressFields.map((columnItem, columnIndex) => {
                        let columnValue = columnItem;
                       // let columnName = columnValue.replace(/borrower_/g, "").replace(/co_borrower_/g, "Co Lead ").replace(/_/g, " ");
                        return data.map((leadItem, leadIndex) => (
                            address=address+" "+leadItem[columnValue],
                            <span key={leadIndex} className="focusAddress client leadDataText" id={columnValue}><span className="clickToEdit">{leadItem[columnValue]}</span><span className="editHover"><FontAwesomeIcon icon={faPen} /></span></span>
                        ));
                    })}
                    <span className="viewOnMap"><a className="mapsLink" href={"https://maps.google.com/maps?q="+address+""} target="_blank"><FontAwesomeIcon icon={faMapLocationDot} /></a></span>
                </div>
            </span>
            <div className="columnWrapper">
                <diiv className="singleColumn">
                    <div className="singleColumnTitle">Lead Information</div>
                    {topColumns.map((columnItem, columnIndex) => {
                        console.log(columnItem)
                    // Assign the columnValue to a variable
                        /*let columnValue = columnItem.column;

                        let columnName= columnValue.replace(/borrower_/g, "").replace(/co_borrower_/g, "Lead ").replace(/_/g, " ");
                        //let columnName="as";
                        return data.map((leadItem, leadIndex) => ( 
                            <div className="leadDataBlock appendable" key={leadIndex}>
                                <span className="focusBFirst borrower leadDataText" id={columnValue}>
                                    <span className="columnName">{columnName}</span>
                                    <span className="clickToEdit" onClick={(event) => enterEditMode(columnIndex,event.target.textContent)}>
                                    {editModes[columnIndex] ? (
                                        <input
                                            type="text"
                                            value={editedValues[columnIndex]}
                                            onChange={(e) => handleInputChange(columnIndex, e)}
                                            onBlur={() => handleSave(columnIndex, leadItem.lead_id, columnValue,editedValues[columnIndex])}
                                            autoFocus
                                        />
                                        ) : leadItem[columnValue] !== null && leadItem[columnValue] !== '' ? (
                                            columnValue.includes('home') || columnValue.includes('cell') || columnValue.includes('work') ? (
                                            formatNumber(leadItem[columnValue])
                                        ) : columnValue.includes('dob') ? (
                                            formatBirthDate(leadItem[columnValue])
                                        ) : (
                                            <>
                                                {editedValues[columnIndex] ? (
                                                    editedValues[columnIndex]
                                                ) : (
                                                    leadItem[columnValue]
                                                )}
                                            </>
                                        )
                                        ) : (
                                        <>
                                        {editedValues[columnIndex] ? (
                                                editedValues[columnIndex]
                                            ) : (
                                                <span className="clicktoedit">Click To Edit</span>
                                            )}
                                        </>
                                    )}
                                    </span>
                                    <span className="editHover"><FontAwesomeIcon icon={faPen} /></span>
                                </span>
                            </div>
                        ));*/
                    })}
                </diiv>
            </div>
        </div>
    );
}

export default ClientCard;