import React, {useState, useEffect} from 'react';
import '../../css/callLog.css';
import { gatherCallInfo } from '../../utilities/phoneRelatedFunctions';
import { formatPhoneNumber, unixTommddhia } from '../../utilities/dashFunction';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneMissed, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import image from '../../assets/icons/loading-loader.gif';



function MissedCalls({openLead}) {
    const dispatch = useDispatch();
    const agentInfo=useSelector((state) => state.agent.userData);
    const missedCall=useSelector((state) => state.missedCall.missedCall);
    const [expanded, setExpanded] = useState(false);
    const [callInfo, setCallInfo] = useState(false);
    const [mode, setMode] = useState('missed');
    const [callsLoading, setCallsLoading] = useState(false);
    useEffect( () => {
        if(expanded){
            gatherCallInfo(agentInfo.agentId, mode, setCallInfo, setCallsLoading);
        }
    },[expanded,mode])
    

    const handleOpen=()=>{
        setExpanded(!expanded);
        //setOpen(!open);
        //setExpanded(false)
        //dispatch(setConvoWindow(!convoWindow));
    }
    return (
        <>
            <div className={`callLogTab`}>
                <div className='innerTab' onClick={()=>handleOpen()}>
                    <span className="tabTitle">Call Log</span><span className="tabIcon"><FontAwesomeIcon icon={faPhoneMissed} />
                        {missedCall > 0 && (
                            <span className="alerts">{missedCall}</span>
                        )}
                    </span>
                </div>
            </div>

            
            <div className={`leaderBoardWrapper ${expanded ? 'openedLeaderBoard': ''}`}>
                {callsLoading && (
                    <div className="smallLoadingWrapper">
                        <div className="innerSmallLoadingWrapper">
                            <img src={image} alt="Loading Gif" style={{width: '100%'}} />
                        </div>
                    </div>
                )} 
                <div className="closeWindow" onClick={()=>handleOpen()} style={{zIndex: '2'}}>X</div> 
                <div className="leaderBoardHeader">
                    <div className="leaderBoardTitle">Call Log</div>
                    
                </div>
                <div className="convoEntireWrapper">
                    <div className={`conversationLeft ${expanded ? '' : ''}`}>
                        <div className="convoListInnerWrapper">
                            <div className="buttonWrapper" style={{textAlign:'center'}}>
                                <div className={`leaderType accentButton ${mode==='missed' ? 'activeButton' : null}`} style={{width:'100px'}} onClick={()=>setMode('missed')}>Missed Calls</div>
                                <div className={`leaderType accentButton ${mode==='all' ? 'activeButton' : null}`} style={{width:'100px'}} onClick={()=>setMode('all')}>All Calls</div>
                            </div>
                            <div className="callInfoBody">
                                {callInfo && callInfo.map((callItem, index) => (
                                    <div className="callItemWrapper" onClick={()=>openLead(callItem.lead_id)}>
                                        <div class="callLogBlock">
                                            {callItem.borrower_first ? (
                                                <span class="quilityDataLineTitle">{callItem.borrower_first} {callItem.borrower_last}</span>
                                            ) : (
                                                <span class="quilityDataLineTitle">{callItem.call_with}</span>
                                            )}
                                            <div className="subLineWrapper">
                                                {callItem.status==='missed-call' ? (
                                                    <span className={`directionIndication missed`}><FontAwesomeIcon icon={faPhoneMissed} /></span>
                                                ) : (
                                                    <span className={`directionIndication ${callItem.direction}`}><FontAwesomeIcon icon={faArrowRight} /></span>
                                                )}
                                                
                                                <span class="callWithItem">{formatPhoneNumber(callItem.number_used)}</span> - 
                                                <span class="callWithItem">{unixTommddhia(callItem.date)}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MissedCalls;
