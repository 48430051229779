import React, { useState,useEffect } from 'react';
import '../../css/workWindow.css';
import WorkTopBar from './workTopBar';
import WindowBlock from './windowBlock';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';


function WorkWindow({params,workType,toggleWorkWindow}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [windowFocus, setWindowFocus] = useState(workType);
    const [dialData, setDialData] = useState([]);
    const [leadData, setLeadData] = useState([]);
    
    
    const fetchContent = async (path) => {
        if(workType=='Dial'){
            try {
                const response = await fetchWithDefaults(`/getWorkLeads?agent=${agentId}&params=${params}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setDialData(jsonData);
                getLead(jsonData[0].lead_id);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    const getLead= async (lead)=>{
        try {
            const response = await fetchWithDefaults(`/getLead?lead=${lead}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setLeadData(jsonData)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    function formatNumber(phoneNumber){
        const cleaned = phoneNumber.toString().replace(/\D/g, ''); // Remove all non-digit characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber; 
    }

    function formatLastContacted(dateTimeStr) {
        const date = new Date(dateTimeStr);

        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        const formattedHours = hours % 12 || 12;

        return `${month}/${day} @ ${formattedHours}:${minutes} ${ampm}`;
    }


    function formatBirthDate(inputDate) {
        // Create a Date object from the input date string
        const date = new Date(inputDate);
      
        // Get the month, day, and year
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
      
        // Format the date as mm/dd/YYYY
        const formattedDate = `${month}/${day}/${year}`;
      
        return formattedDate;
    }
    if(workType != ''){

    }
    useEffect(() => {
        // Fetch data when the component mounts
        fetchContent();
    }, []);
    const toggleExpand = (title) => {
        setWindowFocus(title);
    };
   
    return (
        <div id="workWindowWrapper">
            <WorkTopBar agentId={agentId} windowFocus={windowFocus} toggleExpand={toggleExpand} toggleWorkWindow={toggleWorkWindow}/>
            <WindowBlock agentId={agentId} title={'Dial'} formatLastContacted={formatLastContacted} formatBirthDate={formatBirthDate} formatNumber={formatNumber} getLead={getLead} leadData={leadData} windowFocus={windowFocus} data={dialData} toggleExpand={toggleExpand} />
            <WindowBlock agentId={agentId} title={'Text'} formatLastContacted={formatLastContacted} formatBirthDate={formatBirthDate} formatNumber={formatNumber} getLead={getLead} leadData={leadData} windowFocus={windowFocus} data={dialData} toggleExpand={toggleExpand} />
            <WindowBlock agentId={agentId} title={'Automate'} windowFocus={windowFocus} data={dialData} toggleExpand={toggleExpand} />
            <span className="filters">
                
            </span>

        </div>
    );
}

export default WorkWindow;