

import React, { useState, useEffect } from 'react';
import DispositionBlock from './sections/dispositions';
import StartSession from './startSession';
import { useTimer } from './callTimer';


function Dial({addTag, device, from, theCall, handlCallAccepted, setCallState, makeDial, dialAmount, currentLead,incrementQueue}) { 
    const [currentPos, setCurrentPos] = useState(0);
    const [currentDial, setCurrentDial] = useState(1);
    const { seconds, clearTimer, resetTimer } = useTimer(null, () => {
        device.disconnectAll();
        setTimeout(() => {
            if(currentDial < dialAmount){
                resetTimer(15);
                makeDial(currentLead.phone); 
                setCurrentDial(currentDial+1);
            }
            else if(currentDial === dialAmount){
                setCurrentDial(currentDial+1);
                makeDial(currentLead.phone); 
                clearTimer();
            }
        }, 2500);
    });
    const [sessionStarted, setSessionStarted] = useState(false);
    const [click, setClick] = useState(false);

    function getFirstNumber(number1, number2, number3) {
        if (number1 !== null && number1 !== undefined) {
            return number1;
        }
        if (number2 !== null && number2 !== undefined) {
            return number2;
        }
        if (number3 !== null && number3 !== undefined) {
            return number3;
        }
        return null;
    }
   
    const toCall=getFirstNumber(currentLead.borrower_cell,currentLead.borrower_home,currentLead.borrower_work);
    const [timerRunning, setTimerRunning] = useState(false);

    

    useEffect(() => {
        if(currentLead && sessionStarted){

            if(!click){
                setCurrentDial(currentDial+1);
                setCallState('Ringing...');
                setCurrentPos(currentPos+1);
                makeDial(toCall); 
            }
        }
        if(dialAmount !==1 && sessionStarted){
            if(!click){
                resetTimer(15);
            }
        }

        return () => {
          // Do something when the component unmounts
        };
    }, [sessionStarted,currentLead, click]);
   
    useEffect(() => {
        
        if(theCall){
            theCall.on("accept", () => {
                setCallState('Connected');
                clearTimer();
            });
            theCall.on("disconnect", () => {
                setCallState('Disconnected');
                clearTimer();
            });
        }
    }, [theCall]);
   
    const handleStartSession=()=>{
        setSessionStarted(true);

    }
    const clickToDial=()=>{
        setSessionStarted(true);
        setClick(true);
    }

    return (
        <>
            {sessionStarted===false && (
                <StartSession clickToDial={clickToDial} handleStartSession={handleStartSession} />
            )}
            <div className="dispositionHolder">
                <DispositionBlock addTag={addTag} setCurrentDial={setCurrentDial} tags={currentLead.tag_ids} from={from} theCall={theCall} incrementQueue={incrementQueue} queuePos={currentLead.queuePos} click={click} leadId={currentLead.lead_id}  handlCallAccepted={handlCallAccepted} timerRunning={timerRunning}/>
            </div>
        </>
    );
}

export default Dial;
//