import React, { useState, useEffect } from "react";
import FilterButton from "../../elements/filterButton";
import RemoveableOption from "../../elements/removeableOption";
import { fetchWithDefaults } from '../../../config/fetchDefault';
import SmartSort from "../../leads/smartSort";
import { useSelector, useDispatch } from 'react-redux';
import { addFilter } from '../../../features/filterSlice';
import { setQueue } from '../../../features/queueSlice';
import {  setActiveFilter } from '../../../utilities/appSetup';

function FilteringOptions({ activeFilterName, filteredOptions, queueExpanded, removeOptions, filterOpen, jumpIntoQueue}) {
    const dispatch = useDispatch();
    const workflows=useSelector((state) => state.workflows.workflows);
    //const backupQueue=useSelector((state) => state.queue.originalQueue);
    const queue=useSelector((state) => state.queue.queue);
    const wholeQueue=useSelector((state) => state.queue.wholeQueue);
    const states=useSelector((state) => state.states.states);
    const status=useSelector((state) => state.status.status);
    const leadType=useSelector((state) => state.leadType.leadType);
    const tags=useSelector((state) => state.tags.tags);
    const agentInfo=useSelector((state) => state.agent.userData);
    const [nameModule, setNameModule] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchGroups, setSearchGroups] = useState([]);
    //const [resultCount, setResultCount] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [typeName, setTypeName] = useState(false);
    let filters=[];
    if(agentInfo !== ''){
        filters=[
            {name:'Regions', search:'state',values:[states]},
            {name:'Parent Status',search:'parent_status',values:[[{name:'New',value:'new'},{name:'Active',value:'active'},{name:'Client',value:'client'},{name:'Dead',value:'dead'}]]},
            {name:'Lead Status',search:'lead_status', values:[status]},
            {name:'Lead Type', search:'lead_type', values:[leadType]},
            {name:'Lead Tag',search:'tags', values:[tags]},
            {name:'Workflow',search:'workflow',values:[workflows]}
        ];
    }

    const addToHolder=(option,search)=>{
        const updatedObject = {
            ...option, // Spread the original object
            "search": search // Add the new property
        };
        let existingOptionIndex= -1;
        let indexToRemove;
        selectedOptions.forEach((opt, index) => {
            if (opt.states && opt.states === option.states) {
                existingOptionIndex=2;
                indexToRemove=index;
            }
            else if (opt.name && opt.name === option.name) {
                existingOptionIndex=2;
                indexToRemove=index;
            }
            else if (opt.lead_type && opt.lead_type === option.lead_type) {
                existingOptionIndex=2;
                indexToRemove=index;
            }
            else if (opt.id && opt.id === option.id) {
                existingOptionIndex=2;
                indexToRemove=index;
            }
        });
        if(option){
            if (existingOptionIndex === -1) {
                if(search==='parent_status' && option.name==='Dead'){
                   // dispatch(addGroupQueue(deadQueue));
                }
                
                const updatedOptions = prevOptions => {
                    const updatedGroups = [...prevOptions]; // Create a copy of prevOptions
                    console.log(prevOptions);
                    // Check if the group with the search key exists
                    const existingGroupIndex = updatedGroups.findIndex(group => group.hasOwnProperty(search));
                
                    if (existingGroupIndex !== -1) {
                        // If the group exists, check if the option already exists in the array
                        const existingOptionIndex = updatedGroups[existingGroupIndex][search].indexOf(option.id || option.states || option.value || option.lead_type || option.name);
                
                        if (existingOptionIndex === -1) {
                            // If the option doesn't exist, append it to the array
                            updatedGroups[existingGroupIndex][search].push(option.id || option.states || option.value || option.lead_type || option.name);
                        }
                    } else {
                        // If the group doesn't exist, create a new one
                        updatedGroups.push({ [search]: [option.id || option.states || option.value || option.lead_type || option.name] });
                    }
                
                    return updatedGroups;
                };
                
                const updatedOptionsArray = updatedOptions(selectedOptions);

                const filteredQueue = wholeQueue.filter(item => {
                    return updatedOptionsArray.every(filter => {
                        return Object.entries(filter).every(([key, values]) => {
                            return values.includes(item[key]);
                        });
                    });
                });

                dispatch(setQueue(filteredQueue));
                setSelectedOptions(prevOptions => [...prevOptions, updatedObject]);
            }
            else{
                remove(indexToRemove,option, search)
            }
        }
    } 
    const removeValue = (dataArray, key, valueToRemove) => {
        const newArray = dataArray.map(obj => {
            if (obj.hasOwnProperty(key)) {
                return {
                    ...obj,
                    [key]: obj[key].filter(value => value !== valueToRemove)
                };
            }
            return obj;
        });
        return newArray;
    };
    const remove=(indexToRemove,option, search)=>{
        const updatedData = removeValue(searchGroups, search, option.value || option.states || option.id || option.lead_type || option.name);
        const filteredData = updatedData.filter(obj => Object.values(obj).some(value => Array.isArray(value) && value.length > 0));
        setSearchGroups(filteredData);
        console.log(updatedData);
        const filteredQueue = wholeQueue.filter(item => {
            return filteredData.every(filter => {
                return Object.entries(filter).every(([key, values]) => {
                    return values.includes(item[key]);
                });
            });
        });
        dispatch(setQueue(filteredQueue));
        setSelectedOptions(prevOptions =>
            prevOptions.filter((_, index) => index !== indexToRemove)
        );
    }
    
    useEffect( () => {
        if(filteredOptions){
            setSelectedOptions(filteredOptions.searchParams);
            setSearchGroups(filteredOptions.searchGroups);
        }
        
    },[filteredOptions])
    
    useEffect( () => {
        
        if(removeOptions){
            setSelectedOptions([]);
            setSearchGroups([]);
        }
    },[removeOptions])
    const saveFilterName=async ()=>{
        if(filterName !== ''){
            try {
               
                const response = await fetchWithDefaults(
                    `/saveFilter`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json", 
                        },
                        body: JSON.stringify({ 
                            searchData: searchGroups,
                            agent:agentInfo.agentId,
                            filterName:filterName,
                            teamId:agentInfo.teamId,
                        })
                    }
                );
                
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                if(jsonData.status !==false){
                    dispatch(addFilter(jsonData));
                    setTypeName(false);
                    setFilterName('')
                    setNameModule(false);
                }
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
    const handleInputChange = (event) => {
        setFilterName(event.target.value);
    };
    const handleCancelName=()=>{
        setTypeName(false);
        setFilterName('')
    }
    const clearFilters=()=>{
        setSelectedOptions([])
        setActiveFilter('Last 90 Days', dispatch);
        dispatch(setQueue(wholeQueue));
    }
    return (
        <div className={`mainFilterBlockWrapper expandFilters ${filterOpen && queueExpanded ? 'filterQueueOpen' : filterOpen? 'expandFilters' : ''}`} style={{height: queueExpanded === undefined ? '' : queueExpanded && filterOpen ? '' : '0px', width: queueExpanded && filterOpen ? '' : queueExpanded ? '0px' : ''}}>
            <div className="bulkTitle">
                Filter Options 
                
                {nameModule && (
                    <div className="nameFilterWrapper solidQuilityActiveButton">
                        {typeName && (
                            <div className="filterInputWrapper">
                                <input 
                                    type="text" 
                                    className="qualifierFieldInput" 
                                    style={{width: 'calc(100% - 20px)'}}
                                    value={filterName} 
                                    onChange={handleInputChange} 
                                />
                            </div>
                        )}
                        {selectedOptions.length > 0 && (
                            <div className="nameFilterTitle" onClick={(e)=>{setTypeName(true);e.stopPropagation();}}><span className="saveListName saveFilterButtonOption" onClick={()=>saveFilterName()}>Save</span>{!typeName ? 'Filter' : <span className="cancelSave saveFilterButtonOption" onClick={(e)=>{handleCancelName();e.stopPropagation();}}>Cancel</span>}</div>
                        )}
                    </div>
                )}
                {selectedOptions && selectedOptions.length > 0 && (
                    <div className="clearFilters" onClick={()=>clearFilters()}>Clear Filters</div>
                )}
                {queue && (
                    !typeName && (
                        <span className="optionButtonsHolder">
                            <SmartSort data={queue}/>
                            {queue.length > 0 && (
                                <div className="jumpIntoButton solidQuilityActiveButton" onClick={()=>jumpIntoQueue()}>Jump Into Queue</div>
                            )}
                            
                        </span>
                    )
                )}
            </div>
            {selectedOptions && selectedOptions.length > 0 && (
                <div className="selectedHolder">
                    {activeFilterName !== 'Digital 10 Day' && activeFilterName !== 'Digital 14 Day +' && activeFilterName !== 'Analog 14 Day +' && activeFilterName !== 'Analog 10 Day' && activeFilterName !== 'Active Conversations' && (
                        <>
                            {selectedOptions.map((selected, index) => (
                                <RemoveableOption search={selected.search} name={selected.name || selected.states || selected.lead_type} remove={remove} item={selected} key={`removeableOption`+index} index={index}/>
                            ))}
                        </>
                    )}
                </div>
            )}
            <div className="filterOptionsBarWrapper">
                {filters.length > 0 && filters.map((filter, index) => (
                    <FilterButton title={filter.name || filter.lead_type} setNameModule={setNameModule} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} addToHolder={addToHolder} key={`removeableFilter`+index} search={filter.search} values={filter.values} type=""/>
                ))}
            </div>
        </div>
    );
}

export default FilteringOptions;
