import React, { useState, useEffect } from 'react';
import '../css/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBars, faBug, faUserCrown, faUserNinja } from '@fortawesome/pro-regular-svg-icons';
import { fetchWithDefaults } from '../config/fetchDefault';
import BugReport from './bugReport';
import image from '../assets/icons/funnel.png';
import image2 from '../assets/icons/Logo.png';
import { useSelector } from 'react-redux';


function HeaderBar({changeBarState, barState, signOut, openLead}) {
  const agentId=useSelector((state) => state.agent.userData).agentId;
  const [searchTerm, setSearchTerm] = useState('');
  const [bugReport, setBugReport] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [results, setResults] = useState(false);

  useEffect(() => {
    return () => {
      // Clear the typing timeout when component unmounts
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const handleInputChange = async (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    // If a request is pending, don't initiate a new one
    if (isRequestPending) {
      return;
    }

    // Clear the previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout
    setTypingTimeout(
      setTimeout(async () => {
        setIsRequestPending(true);

        try {
          const response = await fetchWithDefaults(`/leadSearch?search=${value}&agent=${agentId}`);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          if(jsonData.status===false){
            setResults([{borrower_first:'No Results'}]);
            setIsRequestPending(false);
          }
          else{
            setResults(jsonData);
            setIsRequestPending(false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }, 500)
    );
  };

  const openBugReport=()=>{
    setBugReport(!bugReport);
  };

  return (
    <div id="barWrapper">
      <div className="imageWrapper">
        <img src={image} alt="Main Logo" className="mainLogo"/>
      </div>
      <div className="searchBar">
        <input 
          type="text" 
          className="searchBarInput" 
          placeholder="Search leads..."
          value={searchTerm}
          onChange={handleInputChange}
        />
        <div className="resultsHolder">
          {results  && results.map((item, index) => (
            <div className="searchResult" onClick={()=>[setSearchTerm(''),setResults(false),openLead(item.lead_id)]} key={index}>
              <span className="searchName">{item.borrower_first} {item.borrower_last}</span>
              <span className="searchNumber">{item.phone}</span>
              <span className="searchState">{item.state}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="otherBarOptions">
        <div className="bugReportHolder" onClick={openBugReport}>Report Bug <FontAwesomeIcon className="colorIcon" style={{fontSize: '24px',verticalAlign: 'middle'}} icon={faBug}/></div>
      </div>
      {bugReport && (
          <div className="bugReportWrapper">
            <BugReport agentId={agentId} openBugReport={openBugReport}/>
          </div>
      )}
      <div className="barMenu" style={{marginRight: '20px'}}>
        <div className="barIcon" >
          <FontAwesomeIcon className="colorIcon" style={{fontSize: '26px'}} icon={faBars}/>
        </div>
        <div className="hiddenOptions">
          {barState ? (
            <>
              <div className="BarOption" onClick={changeBarState}>Dial <span><FontAwesomeIcon  className="colorIcon" icon={faUserNinja} style={{fontSize: '18px',marginleft: '5px'}}/></span></div>
            </>
          ) : (
            <>
              <div className="BarOption" onClick={changeBarState}>Admin <span><FontAwesomeIcon  className="colorIcon" icon={faUserCrown} style={{fontSize: '18px',marginleft: '5px'}}/></span></div>
            </>
          )}
          <div className="BarOption" onClick={signOut}>Sign Out <span><FontAwesomeIcon  className="colorIcon" icon={faArrowRightFromBracket} style={{fontSize: '18px',marginleft: '5px'}}/></span></div>
        </div>
      </div>
    </div>
  );
}

export default HeaderBar;
