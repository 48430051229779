import React, {useState,useEffect} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './checkout';
import { fetchWithDefaults } from '../../config/fetchDefault';

const stripePromise = loadStripe('pk_test_RGmoYxMaWO1N2K3JqJRTAFPQ');


export default function AddCard({cust,addACardDone}) {
    const [clientSecret, setClientSecret] = useState(false);
   
    

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const requestOptions = {
            method: 'POST'
        };
    
        const response = await fetchWithDefaults(`/create-payment-intent?cust=${cust}`, requestOptions);
        const data = await response.json();
        setClientSecret(data.clientSecret);

      } catch (error) {
        console.error('Error fetching clientSecret:', error);

      }
    };
    fetchClientSecret();
  }, []);

    const options = {
        clientSecret: clientSecret,
    };
  

  return (
    <>
    {clientSecret ? (
        <div className={`mainBlockWrapper`}>
        
        <div className="mainBlockHeader">
            <div className="mainBlockTitle">Add a Card</div>
        </div>
        
        <div className="mainBlockContent">

            <Elements stripe={stripePromise} options={options}>
                <div className="formWrapper">
                    <CheckoutForm addACardDone={addACardDone} clientSecret={clientSecret} />
                </div>
            </Elements>
         
        </div>
        <div className="blockFooter">
            
        </div>
    </div>
      
    ) : (
    null
    )
       
    }
    </>
  );
};
