import React, {useState} from 'react';
import '../../../css/scheduledCalls.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarLinesPen } from '@fortawesome/pro-regular-svg-icons';
import Warning from '../../warning';
import { fetchWithDefaults } from '../../../config/fetchDefault';


function ScheduledCalls({ data, openLead, formatDate}) {
    const [newData, setData] = useState(data);    
    const [warning, setWarning] = useState(false);
    const cancel = () => {
        setWarning(false);
    };
    const removeItem = (id,index) => {
        setWarning(id);
    };
    const confirm= async()=>{
       let callId=warning;
        try {
            const response = await fetchWithDefaults(`/cancelScheduledCall?callId=${callId}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status===true){

                // Remove the canceled call from the data prop
                const updatedData = data.map((item) => ({
                    ...item,
                    tasks: item.tasks.filter((call) => call.callId !== callId),
                }));
        
                setData(updatedData);
                setWarning(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const contactFormat = (inputDate) => {
        // Parse the input date string
        const dateObject = new Date(inputDate);
      
        // Format the date and time
        const formattedDate = `${(dateObject.getMonth() + 1).toString().padStart(2, '0')}/${
          dateObject.getDate().toString().padStart(2, '0')
        } @ ${
          dateObject.getHours() % 12 || 12
        }:${dateObject.getMinutes().toString().padStart(2, '0')}${
          dateObject.getHours() >= 12 ? 'pm' : 'am'
        }`;
      
        return formattedDate;
      };
    const formatUnixTimestamp = (unixTimestamp) => {
        if (!unixTimestamp || isNaN(unixTimestamp)) {
            return 'Never Contacted';
        }
        
        // Convert Unix timestamp to milliseconds
        const timestampInMilliseconds = unixTimestamp * 1000;
      
        // Create a new Date object
        const date = new Date(timestampInMilliseconds);
      
        // Format the date
        const month = date.getMonth() + 1; // Months are zero-based
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
      
        // Convert 24-hour time to 12-hour time
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;
      
        // Format the output
        const formattedDate = `${month}/${day} @ ${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}${ampm}`;
      
        return formattedDate;
    };
    return (
        <div className="outerWrapper" style={{textAlign: 'center'}}>
            {warning && 
                <Warning title={"Delete Call"} cancel={cancel} confirm={confirm} message={"Are you sure you want to remove this call?"} buttons={[{name:'Don\'t Delete',action:'cancel'},{name:"Confirm Delete", action:'confirm'}]} />
            }
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">
                    <FontAwesomeIcon icon={faCalendarLinesPen} className="colorIcon" />
                </span>
                Scheduled Events
            </span>
           
            <div className={`frontPageQuilityParentBlockWrapper`} style={{width: '70%'}}>
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">Scheduled Calls</div>
                </div>
                <div className="quilityParentBlockContent">
                    {newData[0].status !== false && newData.map((item, index) => (
                        <div key={`scheduledCall${index}`}>
                            <div className="dateGroupTitle">{formatDate(item.date)}</div>
                            {item.tasks.map((call, index) => (
                                <div className={`quilityNestedBlockWrapper`} key={`itemTasks${index}`} style={{marginTop: '25px'}}>
                                    <div className="quilityCloseWindow oulineQuilityDestructiveButton" onClick={() => removeItem(call.callId)} >X</div>
                                    <div class="quilityNestedBlockHeader">
                                        <div class="quilityParentBlockTitle">{call.borrower_first} {call.borrower_last}</div>
                                    </div>
                                    <div className="infoWrapper">
                                        <div className="quilityDataLine">
                                            <span className="itemTitle" style={{display: 'inline-block',width: '140px'}}>Lead Name: </span>
                                            {call.borrower_first} {call.borrower_last}
                                        </div>
                                        <div className="quilityDataLine">
                                            <span className="itemTitle" style={{display: 'inline-block',width: '140px'}}>Time Scheduled: </span>
                                            {formatUnixTimestamp(call.time)}
                                        </div>
                                        <div className="quilityDataLine">
                                            <span className="itemTitle" style={{display: 'inline-block',width: '140px'}}>Last Contacted: </span>
                                            {call.last_contacted !=null ? (
                                                    contactFormat(call.last_contacted)
                                            ) : (
                                                "Never contacted"
                                            )}
                                        </div>
                                        
                                        <span className="schedulefunctionButtons">
                                            <span className="wideBlockButton" onClick={() => openLead(call.lead_id)}>View Lead</span>
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div> 
                    ))}
                    {newData[0].status === false && (
                        <div className="title">You have no calls scheduled</div>
                    )}
                </div>
                <div className="blockFooter">
                    <div
                        className="buttonWrapper"
                        style={{ marginTop: " 5px", marginLeft: "10px" }}
                    >
                        <div className="dataButtonBlock">Scheduled Calls: {newData.length ? newData.length : 0}</div>
                        <div className="actionButtonBlock" style={{width: '150px'}}>
                           
                        </div>
                    </div>
                </div>

        </div>
    </div>

    );
}

export default ScheduledCalls;