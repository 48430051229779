import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle} from '@fortawesome/pro-regular-svg-icons';
import { fetchWithDefaults } from '../../../../config/fetchDefault';

function AttachFile({leadId,files}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [filesAttached, setFilesAttached] = useState([]);
    const [previewUrl, setPreviewUrl] = useState(null);
    const fileInputRef = useRef(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file) {
            if (isValidFile(file)) {
                setSelectedFile(file);
                previewFile(file);
            } else {
                alert("Invalid file type or size. Please select a valid file.");
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const isValidFile = (file) => {
        const allowedTypes = ["application/pdf", "text/csv", "application/vnd.ms-excel", "image/png", "image/jpeg"];
        const maxSize = 20 * 1024 * 1024; // 20 MB
        
        // Check if the file type and size are valid
        return allowedTypes.includes(file.type) && file.size <= maxSize;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && isValidFile(file)) {
            setSelectedFile(file);
            previewFile(file);
        } else {
            alert("Invalid file type or size. Please select a valid file.");
        }
    };

    const handleSubmit = async () => {
        if (selectedFile) {
            const formData = new FormData();
    
                // Append the selected file to the FormData object
            formData.append('file', selectedFile);
            try {
                const response = await fetch(`https://v2.ninjadialer.com:3333/attachFileToLead?lead=${leadId}`, {
                    method: 'POST',
                    body: formData,
                });
                const jsonData = await response.json();
                setFilesAttached(prevFilesAttached => [...prevFilesAttached, ...jsonData]);
            } catch (error) {
                console.error('Error:', error.message);
                // Handle error, if needed
            }
        } else {
            console.log("No file selected");
        }
    };

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleOpenFileDialog = () => {
        fileInputRef.current.click();
    };

    const handleRemoveFile = (e) => {
        e.stopPropagation();
        setSelectedFile(null);
        setPreviewUrl(null);
    };
    const gatherFiles=async()=>{
        const response = await fetchWithDefaults(`/gatherFiles?files=${btoa(files)}`);

        if (!response.ok) {
            console.log('Network response was not ok');
        }
        const jsonData = await response.json();
        const myArray = jsonData.map(item => ({
            name: item.name,
            file: item.id+'.'+item.ext
        }));
        setFilesAttached(myArray);
    }
    useEffect(() => {
        if(files && files.includes(',') > 0){
            gatherFiles();
        }
        else{
            setFilesAttached([]);
        }
    }, [leadId]);

    return (
        <div className="completeAttachWrapper" style={{height: '100%'}}>
            {Array.isArray(filesAttached) && filesAttached.length > 0 && (
                filesAttached.map((file, index) => (
                    <div key={index} className='attachedFile'>
                        <a href={`https://files.thehivelms.com/files/${file.file}`} target="_blank" style={{color: '#ffffff',textDecoration: 'none',fontWeight: '700'}}>{file.name}</a>
                    </div>
                ))
            )}

            <div className="attachFileDropZone" onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleOpenFileDialog}>
                <div className="attachFileText">Drag & Drop File Or Click Here</div>
                <div className="attachFileSubText">(.pdf, .csv, .xls, .png, .jpeg)</div>
                <input type="file" onChange={handleFileChange} style={{ display: "none" }} ref={fileInputRef} />
                {selectedFile && (
                    <div>
                        {selectedFile.type.startsWith("image/") ? (
                            <div className="preview" style={{ position: 'relative' }}>
                                <img src={previewUrl} alt="Preview" style={{ maxWidth: "50px", maxHeight: "50px" }} />
                                <span className="fileText">Selected File: {selectedFile.name}<span className="removeIcon"><FontAwesomeIcon icon={faTimesCircle} onClick={handleRemoveFile} /></span></span>

                            </div>
                        ) : (
                            <div className="preview" style={{ position: 'relative' }}><span className="fileText">Selected File: {selectedFile.name} <span className="removeIcon"><FontAwesomeIcon icon={faTimesCircle} onClick={handleRemoveFile} /></span></span></div>
                        )}
                    
                    <button className="accentButton" style={{ lineHeight: '25px' }} onClick={(e) => { e.stopPropagation(); handleSubmit(); }}>Upload</button>
                    </div>
                    
                )}
            </div>
        </div>
    );
}

export default AttachFile;
