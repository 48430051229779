import React from 'react';
import '../css/navigationBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Navigation({ navTiles,removeNavTile}) {

    return (
        <div className="navigationBarWrapper">
            {navTiles.map((tile,index)=> (
                <span key={`navBar${index}`} className={`navigationTile ${index !== navTiles.length - 1 ? 'pastTile' : ''}`} onClick={()=>removeNavTile(index)}>
                    <span className="navigationTitle">
                        <span className="navigationIcon">
                            <FontAwesomeIcon icon={tile.icon} className="colorIcon" />
                        </span>
                        {tile.name}
                    </span>
                </span>
            ))}
            
        </div>
    );
}

export default Navigation;
