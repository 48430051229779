import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector } from 'react-redux';


function AdditionalInfo({leadData, handleLeadData}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [isOpen, setIsOpen] = useState(false);
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const [customFields, setCustomFields] = useState({});
    const [editingField, setEditingField] = useState(null);
    const inputRef = useRef(null);
    const innerWrapperRef = useRef(null);

    const openUp = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (innerWrapperRef.current) {
            setInnerWrapperHeight(innerWrapperRef.current.clientHeight);
        }
    }, [isOpen]);

    useEffect(() => {
        if(leadData[0]['custom_fields'] !== ' N;' || leadData[0]['custom_fields'] !== '{}' || leadData[0]['custom_fields'] !== 'N; '){
            setCustomFields(JSON.parse(leadData[0]['custom_fields']));
        }
    }, []);

    const handleFieldClick = (key) => {
        setEditingField(key);
    };

    const handleFieldChange = (key, newValue) => {
        setCustomFields(prevFields => ({
            ...prevFields,
            [key]: newValue
        }));
        
    };
    const updateLeadData = async (field, value) => {
        try {        
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    lead:leadData[0].lead_id,
                    column:field,
                    value:value
                }) 
            };
          
            const response = await fetchWithDefaults('/updateLead', requestOptions);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
           
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        if (editingField && inputRef.current) {
          inputRef.current.focus();
        }
    }, [editingField]);
    useEffect(() => {
        console.log(customFields);
        /*if(customFields !=' N;' && customFields != '{}'){
            //handleLeadData('custom_fields',JSON.stringify(customFields));
            updateLeadData('custom_fields',JSON.stringify(customFields));
        }*/
    }, []);
    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`} >
            <div className="quilityNestedBlockHeader" onClick={openUp}>
                <div className="quilityParentBlockTitle">Lead Information</div>
                <span className='quilityBlockIcon'>
                    <FontAwesomeIcon className={`transition ${isOpen ? 'rotate' : ''}`} icon={faChevronDown}/>
                </span>
            </div>
            <div className="quilityNestedBlockContent" style={{ height: isOpen ? innerWrapperHeight + 25 : '0px' }}>
                <div className="innerWrapper" ref={innerWrapperRef}>
                    {Object.entries(customFields).map(([key, value]) => (
                        key !== 'Status' && key !== 'Type of Contact' && (
                            <div key={key} className="leadInfoLine" >
                                <span className="dataFieldTitle">{key.replace('_',' ')}</span>
                                {editingField === key ? (
                                    <input
                                        type="text"
                                        className="qualifierFieldInput"
                                        value={value}
                                        onChange={(e) => handleFieldChange(key, e.target.value)}
                                        onBlur={() => {
                                            updateLeadData('custom_fields', JSON.stringify(customFields));
                                            setEditingField(null);
                                        }}
                                        ref={inputRef}
                                    />
                                ) : (
                                    <span className="leadData" onClick={() => handleFieldClick(key)}>{value}</span>
                                )}
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AdditionalInfo;

