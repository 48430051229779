import { createSlice } from '@reduxjs/toolkit'

export const statesSlice = createSlice({
    name: 'states',
    initialState: {
        states: []
    },
    reducers: {
        setStates(state, action) {
            state.states = action.payload;
        },
    },
})

export const { setStates } = statesSlice.actions;

export default statesSlice.reducer

