import React, { useState } from 'react';
import LeadQueue from '../leadQueue';
import { fetchWithDefaults } from '../../../config/fetchDefault';

function TextWindow({title,data, agentId}) {
    const [queue, setQueue] = useState(data);
    const [activeButton, setActiveButton] = useState('all');
    const getTexts= async (type)=>{
        try {
            const response = await fetchWithDefaults(`/${type}Texts?agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setQueue(jsonData);
            setActiveButton(type);
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }
    return (
        <div className="leftSideBarWrapper">
            <div className="queueTextBar">
                <div className={`queueButton ${activeButton === 'all' ? 'softActive' : ''}`} onClick={()=>getTexts('all')}>All</div>
                <div className={`queueButton ${activeButton === 'unread' ? 'softActive' : ''}`} onClick={()=>getTexts('unread')}>Unread</div>
                <div className={`queueButton ${activeButton === 'recent' ? 'softActive' : ''}`} onClick={()=>getTexts('recent')}>Recent</div>
            </div>
            <div className="leadQueueWrapper">
                <LeadQueue agentId={agentId} title={title} data={queue}/>
            </div>
        </div>
    );
}

export default TextWindow;