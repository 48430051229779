import React, {useState} from 'react';
import '../../css/market.css';
import { faCartShoppingFast, faIdCard } from '@fortawesome/pro-regular-svg-icons';
import MarketSection from './marketSection';
import Navigation from '../navigationBar';
import CurrentPageHandler from './currentPageHandler';
import { fetchWithDefaults } from '../../config/fetchDefault';
import PastOrders from './pastOrders';

function Market({ data}) { 
    const [warning, setWarning] = useState(false);
    const [sections, setSection]=useState(data[0].sections);
    //const [sections, setSection]=useState(["Leads"]);
    const [leadPreview, setLeadPreview]=useState(data[0].results[0].leads[0]);
    const [workflowPreview, setWorkflowPreview]=useState(data[0].results[0].workflows[0]);
    const [navTiles, setNavTiles]=useState([{name:'Market', icon: faCartShoppingFast}]);
    const [currentPage, setCurrentPage]=useState(null);
    const [pathData, setPathData]=useState(null);


    const cancel = () => {
        setWarning(false);
    };
    const removeItem = (id,index) => {
        setWarning(id);
    };
    const setupNavTiles = (name,icon) => {
        const newNavTiles = [
            ...navTiles,
            { name: name, icon: faIdCard}
          ];
          setCurrentPage(name);
          setNavTiles(newNavTiles);
    };
    const removeNavTile = (indexToRemove) => {
        if(indexToRemove==0){
            setCurrentPage(null);
        }
        const updatedNavTiles = navTiles.filter((_, index) => index <= indexToRemove);
        setNavTiles(updatedNavTiles);
        setCurrentPage(navTiles[indexToRemove].name)

    };
    const confirm= async()=>{
       let callId=warning;
        try {
            const response = await fetchWithDefaults(`/cancelScheduledCall?callId=${callId}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status===true){

                // Remove the canceled call from the data prop
                const updatedData = data.map((item) => ({
                    ...item,
                    tasks: item.tasks.filter((call) => call.callId !== callId),
                }));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    
    return (
        <div className="outerWrapper">
            <div className="navHolder">
                <Navigation setupNavTiles={setupNavTiles} removeNavTile={removeNavTile} navTiles={navTiles}/>
            </div>
            <div
                className="mainBlockOuterWrapper"
            >
            {currentPage ? (
                <CurrentPageHandler pathData={pathData} setPathData={setPathData} page={currentPage} setCurrentPage={setCurrentPage} setupNavTiles={setupNavTiles}/>
            ) : (
                <>
                    <PastOrders/>
                    {sections ? (
                        sections.map((section, index) => (
                            <MarketSection key={`markets${index}`} setupNavTiles={setupNavTiles} title={section.name} workflowPreview={workflowPreview} leadPreview={leadPreview}/>
                        ))
                    ) : (
                        null
                    )}
                </>
            )}
            
        </div>
    </div>

    );
}

export default Market;
/*
<span className="navigationBar">
                <span className="navigationTile">
                    <span className="outerWrapperTitle">
                        <span className="outerWrapperIcon">
                            <FontAwesomeIcon icon={faCartShoppingFast} className="colorIcon" />
                        </span>
                        Marketplace
                    </span>
                </span>
            </span>*/