import React, { useState, useEffect } from "react";
import '../../css/elements.css';
import { useSelector, useDispatch } from 'react-redux';
import { setQueue } from '../../features/queueSlice'
import { fetchWithDefaults } from '../../config/fetchDefault';
import { sortIntoTiers, createMasterArray, sortObjectIntoArrays, getLast12MonthsTimestamps } from '../../utilities/smartSort';
import FilterHolderBlock from "./component/filterHolderBlock";

function FrontPremadeFilterBlock({title, setRefreshQueue, getMyFilter, jumpIntoQueue, setMyQueue, setLoading}) {
    const filters=useSelector((state) => state.filters.filters);
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [systemFilters, setSystemFilters] = useState(false);
    const [taskLists, setTaskLists] = useState([
        {name:'Digital 10 Day', params:'list', search:"d10"},
        {name:'Digital 14 Day +', params:'list', search:"d11"},
        {name:'Analog 10 Day', params:"list", search:"a10"},
        {name:'Analog 14 Day +', params:'list', search:"a11"},
        {name:'Active Conversations', params:'list', search:"resp"}
    ]);
    const dispatch = useDispatch();
   
    const gatherSmartList = async ()=>{
        try {
            setLoading(true);
            const response = await fetchWithDefaults(
                `/gatherSmartList?agent=${agentId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json", 
                    },
                }
            );
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json()
            if(jsonData.status===false){
                //setResultCount(0);
                //setHoldingQueue(false);
            }
            else{
                const smartSort= async ()=>{
                    const sortByLastContacted= await sortObjectIntoArrays(jsonData,'lead_type');
                    const sortedByLastContactedWithTiers = await sortIntoTiers(sortByLastContacted);
                    const master = await createMasterArray(sortedByLastContactedWithTiers);
                    const updatedJsonData = master.map((item, index) => ({
                        ...item,
                        queuePos: index
                    }));
                    dispatch(setQueue(updatedJsonData));
                    setRefreshQueue(updatedJsonData[0]);
                }
                smartSort();
                //setMyQueue(updatedJsonData);
            }
            jumpIntoQueue();
            setLoading(false);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect( () => {
        const dates= getLast12MonthsTimestamps();
        setSystemFilters(dates);
    },[])
    return (
        <div className={`frontPageQuilityParentBlockWrapper`}>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">{title}</div>
            </div>
            <div className="quilityParentBlockContent">
                <div className="dialSmartListButton solidQuilityActiveButton" onClick={()=>gatherSmartList()}>Dial Smart List</div>

                <FilterHolderBlock filters={filters} open={false} getMyFilter={getMyFilter} title='Custom Filters'/>
                <FilterHolderBlock filters={systemFilters} open={true} getMyFilter={getMyFilter} title='System Filters'/>
            </div>
        </div>
    );
}

export default FrontPremadeFilterBlock;

/*
<span className="quilityNestedBlockWrapper" style={{ height: isOpen && getHeight() !==0 ? getHeight()+90 : '35px',maxHeight:'335px'}}>
    <div className="quilityNestedBlockHeader" onClick={()=>handleOptionClick()}>
        <div className="quilityParentBlockTitle">Custom Filters</div>
        <span className="quilityBlockIcon"><FontAwesomeIcon className={`selectBoxIcon ${isOpen ? 'rotate':''}`} icon={faChevronUp}/></span>
    </div>
    <div className="quilityNestedBlockContent"  ref={innerWrapperRef} style={{maxHeight:'300px',overflow: 'auto'}}>
        {filters && filters.map((filter, index) => (
            <div className="quilityDataLine" key={`filterOption${index}`} style={{display: 'flex'}}>
                <div className="quilityDataLineTitle" style={{flex: '2'}}>{filter.name}</div>
                <div className="quilityDataLineValue" style={{flex: '1',cursor:'pointer'}} onClick={()=>getMyFilter(filter.params,false,filter.name)}>View</div>
                <div className="quilityDataLineValue" style={{cursor:'pointer'}} onClick={()=>getMyFilter(filter.params,true,filter.name)}>Dial</div>
            </div>  
        ))}
    </div>
</span>
<span className="quilityNestedBlockWrapper" style={{ height: isOpen && getHeight() !==0 ? getHeight()+90 : '35px',maxHeight:'335px'}}>
    <div className="quilityNestedBlockHeader" onClick={()=>handleOptionClick()}>
        <div className="quilityParentBlockTitle">Premade Filters</div>
        <span className="quilityBlockIcon"><FontAwesomeIcon className={`selectBoxIcon ${isOpen ? 'rotate':''}`} icon={faChevronUp}/></span>
    </div>
    <div className="quilityNestedBlockContent"  ref={innerWrapperRef} style={{maxHeight:'300px',overflow: 'auto'}}>
        {filters && filters.map((filter, index) => (
            <div className="quilityDataLine" key={`filterOption${index}`} style={{display: 'flex'}}>
                <div className="quilityDataLineTitle" style={{flex: '2'}}>{filter.name}</div>
                <div className="quilityDataLineValue" style={{flex: '1',cursor:'pointer'}} onClick={()=>getMyFilter(filter.params,false,filter.name)}>View</div>
                <div className="quilityDataLineValue" style={{cursor:'pointer'}} onClick={()=>getMyFilter(filter.params,true,filter.name)}>Dial</div>
            </div>  
        ))}
    </div>
</span>
*/




