const getMarketInfo= async (fetchWithDefaults, agentIdNumber,setMarketData)=>{
    try {
        const response = await fetchWithDefaults('/getMarket?agent='+agentIdNumber);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setMarketData(jsonData)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export { getMarketInfo };