import React from 'react';
import '../../css/workWindow.css';


function WorkTopBar({toggleExpand,toggleWorkWindow}) {
    
    return (
        <div id="workWindowTopBarWrapper">
            <div className="workWindowButton" onClick={()=>toggleWorkWindow(false)}>
                Close Window
            </div>
            <div className="workWindowButton" onClick={()=>toggleExpand("Dial")}>
                Dial
            </div>
            <div className="workWindowButton" onClick={()=>toggleExpand("Text")}>
                Text
            </div>
        </div>
    );
}

export default WorkTopBar;