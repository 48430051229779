import React, {useState, useEffect} from 'react';
import '../../../css/scheduledCalls.css';
import MarketBlock from './marketBlock';
import { fetchWithDefaults } from '../../../config/fetchDefault';

function LeadsMarket({ data, setupNavTiles, setPathData}) {
    const [newData, setData] = useState(false);    
    const [warning, setWarning] = useState(false);
    
    const gatherData= async()=>{
        try {
            const response = await fetchWithDefaults(`/leadMarketData`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setData(jsonData);
            setPathData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
     }
    useEffect(() => {
        gatherData();
    },[])
    
    return (
        
        <>
        {newData ? (
            <>
                <MarketBlock adjustclassName={'twoX'} setupNavTiles={setupNavTiles} title='Lead Types' data={newData[0].types}/>
                <MarketBlock adjustclassName={'twoX'} setupNavTiles={setupNavTiles} title='Vendors' data={newData[0].vendors}/>
            </>
        ) : (
            null
        )}
            
        </>
    );
}

export default LeadsMarket;
