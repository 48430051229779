import React, { useState, useRef, useEffect} from 'react';
import '../css/addLeads.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { fetchWithDefaults } from '../config/fetchDefault';
import CSVParser from './csvParse';
import { useSelector } from 'react-redux';

function AddLeads({title, fetchPage}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const scripts=useSelector((state) => state.scripts.scripts);
    const workflows=useSelector((state) => state.workflows.workflows);
    const [selectedFile, setSelectedFile] = useState(null);
    const [pastImports, setPastImports] = useState([]);
    const [customImports, setCustomImports] = useState(null);
    const [fileImport, setFileImport] = useState(false);
    const [amountUploaded, setAmountUploaded] = useState(false);
    const fileInputRef = useRef(null);
    const agentId=agentInfo.agentId;
    const teamId=agentInfo.teamId;
    const [workflowValue, setWorkflowValue] = useState(false);
    const [scriptValue, setScriptValue] = useState(false);

    const changeWorkflow = (event) => {
        if(event.target.value !=='none'){
            setWorkflowValue(event.target.value);
        }
    };
    const changeScript = (event) => {
        if(event.target.value !=='none'){
            setScriptValue(event.target.value);
        }
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };
    const importInfo= async(callId)=>{
        try {
            const response = await fetchWithDefaults(`/pastImports?agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setPastImports(jsonData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        try {
            const response = await fetchWithDefaults(`/customImports?agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setCustomImports(jsonData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const deleteBatch= async(batch)=>{
        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    batch: batch
                }) 
            };
      
            const response = await fetchWithDefaults('/deleteBatch', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setPastImports((prevImports) => prevImports.filter((item) => item.batch !== batch));
              
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    const handleUpload = async () => {
        if (!selectedFile) {
            console.log('No file selected');
            return;
        }
    
        const formData = new FormData();
        formData.append('csvFile', selectedFile);
        formData.append('agent', agentId);
        formData.append('team', teamId);
        formData.append('script', scriptValue);
        formData.append('workflow', workflowValue);
    
        try {
            const response = await fetch('https://v2.ninjadialer.com:3333/upload', {
                method: 'POST',
                body: formData,
            });
        
            if (response.ok) {
                const jsonDatas = await response.json();
                setAmountUploaded(jsonDatas);
                // Handle success, if needed
            } else {
                console.error('Error uploading file');
                // Handle error, if needed
            }
        } catch (error) {
            console.error('Error:', error.message);
            // Handle error, if needed
        }
    };
    const profiles=[
        {'name':'Import Wizard','value':'import_wizard'},
        {'name':'Quility HQ','value':'quility_HQ'}, 
        {'name':'OPT Leads','value':'opt'}, 
        {'name':'Opt Past Clients','value':'opt_past_clients'}
    ]
    const focus=()=>{
        //setSetFocus((prevFocus) => !prevFocus);
    }
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if(selectedValue==='import_wizard'){
            setFileImport(true);
        }
        // You can perform any further actions based on the selected value here
    };
    useEffect(() => {
        importInfo();
    },[])
    const handleSelectFileClick = () => {
      // Trigger the file input when clicking on the "Select File" span
      fileInputRef.current.click();
    };
    return (
        <div className="outerWrapper">
            <span className="outerWrapperTitle"><span className="outerWrapperIcon"><FontAwesomeIcon icon={faUpload} className="colorIcon" /></span>{title}</span>
            <div className="boxWrappers">
                
                <div className={`frontPageQuilityParentBlockWrapper x2 ${amountUploaded ? 'closed' : ''}`} onClick={()=>focus()} style={{width:'calc(49% - 10px)'}}>
                    <div className="quilityParentBlockHeader">
                        <div className="quilityParentBlockTitle">Import Options</div>
                    </div>
                    <div className="quilityParentBlockContent" >
                        <div className="quilityNestedBlockWrapper">
                            <div className="quilityNestedBlockHeader">
                                <div className="quilityParentBlockTitle">Import Assignments</div>
                            </div>
                            <div className="importOptionWrapper">
                                
                                <div>
                                    <select className="standardSelectBox" value={workflowValue} onChange={changeWorkflow}>
                                        <option value="none">Add To Workflow</option>
                                        {workflows && workflows.map((item, index) => (
                                            <option value={item.id} key={`workflowSelect${index}`}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="importOptionWrapper">
                                <div>
                                    <select className="standardSelectBox" value={scriptValue} onChange={changeScript}>
                                        <option value="none">Assign Script</option>
                                        {scripts && scripts.map((item, index) => (
                                            <option value={item.id} key={`scriptSelect${index}`}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="quilityNestedBlockWrapper">
                            <div className="quilityNestedBlockHeader">
                                <div className="quilityParentBlockTitle">Import Manipulation</div>
                            </div>
                            <div className="importOptionWrapper">
                                <span className="importOptionTitle">Add Tags To Leads</span>
                                <div><input type="text" className="standardInputBox" placeholder="Separate Tags by a comma"/></div>
                            </div>
                            <div className="importOptionWrapper">
                                <span className="importOptionTitle">Customize Lead Type</span>
                                <div><input type="text" className="standardInputBox" placeholder="Type Custom Type Here"/></div>
                            </div>
                        </div>
                        <div className="quilityNestedBlockWrapper" style={{maxHeight:'unset'}}>
                            <div className="quilityNestedBlockHeader">
                                <div className="quilityParentBlockTitle">Import File</div>
                            </div>
                            {fileImport ? (
                                <CSVParser scriptValue={scriptValue} workflowValue={workflowValue} setFileImport={setFileImport} setAmountUploaded={setAmountUploaded} agent={agentId} team={teamId} setSelectedFile={setSelectedFile} selectedFile={selectedFile}/>
                            ) : (
                                <>
                                    <div className="importOptionWrapper">
                                        <span className="importOptionTitle">Select Import Profile</span>
                                        <div>
                                            <select className="standardSelectBox" onChange={handleSelectChange}>
                                                <option value="none">Select Profile</option>
                                                {profiles.map((item, index) => (
                                                    <option value={item.value} key={`profiles${index}`}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="importOptionWrapper">
                                        {selectedFile ? (
                                            <>
                                                <div className="selectedFileText"><b>Uploading File: {selectedFile.name}</b></div>
                                                <div className="selectionButton" onClick={handleUpload}>Submit Upload</div>
                                            </>
                                        ) : (
                                            <>
                                                <span className="importOptionTitle">Select File To Upload</span>
                                                <span className="importOptionTitle selectionButton solidQuilityActiveButton" onClick={handleSelectFileClick}>
                                                    Select File
                                                </span>
                                                <input
                                                    type="file"
                                                    accept=".csv"  // Specify the allowed file types
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                    onChange={handleFileChange}
                                                />
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`frontPageQuilityParentBlockWrapper ${amountUploaded ? 'closed' : ''}`} style={{width:'calc(49% - 10px)'}}>
                    <div className="quilityParentBlockHeader">
                        <div className="quilityParentBlockTitle">Past Imports</div>
                    </div>
                    <div className="quilityParentBlockContent">
                        <div className="quilityNestedBlockWrapper" style={{maxHeight:'unset'}}>
                            <div className="quilityNestedBlockHeader">
                                <div className="quilityParentBlockTitle">Delete Past Imports</div>     
                            </div>
                            {pastImports.length > 0 ? pastImports.map((item, index) => (
                                <div className="pastImportWrapper" index={`pastImport${index}`}>
                                    <span className="importDate">{item.date_uploaded} - </span>
                                    <span className="importCount"> uploaded {item.total_rows} lead(s)</span>
                                    <span className="deleteImport" onClick={()=> deleteBatch(item.batch)}><FontAwesomeIcon icon={faTrashCan} className="oulineQuilityDestructiveButton" /></span>
                                </div>
                            )) :( 
                                <div className="title" style={{marginBottom:'20px'}}>You have no previous imports</div>
                            )}
                        </div>
                    </div>
                </div>
                
                {amountUploaded !=false ?(
                    <>
                    
                    <div className='mainBoxWrapper x2 displayed'>
                        <div className="mainBoxHeader">
                            <div className="mainBoxTitle">Import Successful</div>
                        </div>
                        <div className="mainBoxContent">
                            <div className="quilityNestedBlockWrapper" style={{maxHeight:'unset'}}>
                                <div className="quilityNestedBlockHeader">
                                    <div className="quilityParentBlockTitle">Your Recent Import</div>
                                </div> 
                                <span className="successfulImport">You imported {amountUploaded.count} Leads!</span>
                                
                            </div>
                        </div>
                        <div className="blockFooter">
                            <div className="buttonWrap left">
                                <div className="dataButtonBlock" style={{marginTop: '9px', marginLeft:' 10px'}}>Uploaded: {amountUploaded.count}</div>
                            </div>
                            <div className="buttonWrap right">
                                <div className="accentButton" style={{float: 'right',marginRight: '10px',marginTop: '7px'}} onClick={()=>fetchPage(amountUploaded.batch)}>View Leads</div>
                            </div>
                        </div>
                    </div>
                    </>
                ):(
                    null
                )}
            </div>
        </div>
    );
}

export default AddLeads;