import React, { useState, useEffect } from 'react';
import '../../css/leaderBoard.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/pro-regular-svg-icons'
import BoardSlot from './boardSlot';
import DateSelector from './dateSelector';
import image from '../../assets/icons/loading-loader.gif';


function LeaderBoard({}) {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [leaderBoard, setLeaderBoard] = useState(false);
    const [appointment, setAppointment] = useState(false);
    const [contact, setContact] = useState(false);
    const [dial, setDial] = useState(false);
    const [activeButton, setActiveButton] = useState('appointments');
    const [open, setOpen] = useState(false);
    const [leaderBoardLoading, setLeaderBoardLoading] = useState(true);

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };
  
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };
    const getLeaderBoard = async () => {

        setLeaderBoardLoading(false);
        try {
            const response = await fetchWithDefaults(`/leaderBoard?start=${selectedStartDate}&end=${selectedEndDate}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
                
            }
            const jsonData = await response.json();
            setLeaderBoardLoading(true);
            setLeaderBoard(jsonData);
    
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleOpen=()=>{
        if(!open){
            getLeaderBoard();
        }
        setOpen(!open);
    }
    const handleActive=(name)=>{
        setActiveButton(name);
    }
    useEffect(() => {
        if(leaderBoard){
            setAppointment(leaderBoard[0].appointment);
            setContact(leaderBoard[1].contact);
            setDial(leaderBoard[2].call);
         //   setLoading(false);
        }
    }, [leaderBoard]);
    useEffect(() => {
        if(selectedStartDate){
            getLeaderBoard();
        }
    }, [selectedStartDate]);
    return (
        <>
            {!open ? (
                <div className={`leaderBoardTab ${open ? 'leaderOpen': ''}`}>
                    <div className='innerTab' onClick={()=>handleOpen()}>
                        <span className="tabTitle">Leader Board</span><span className="tabIcon"><FontAwesomeIcon icon={faFlagCheckered} /></span>
                    </div>
                </div>
            ) : (
                <div className={`leaderBoardWrapper ${open ? 'openedLeaderBoard': ''}`}>
                    {!leaderBoardLoading && (
                        <div className="smallLoadingWrapper">
                            <div className="innerSmallLoadingWrapper">
                                <img src={image} alt="Loading Gif" style={{width: '100%'}} />
                            </div>
                        </div>
                    )} 
                    <div className="closeWindow" onClick={()=>handleOpen()} style={{zIndex: '2'}}>X</div> 
                    <div className="leaderBoardHeader">
                        <div className="leaderBoardTitle">Leader Boards</div>
                        <div className="leaderTypeWrapper">
                            <div className={`leaderType ${activeButton==='appointments' ? 'activeButton' : ''}`} onClick={()=>handleActive('appointments')}>Appointments</div>
                            <div className={`leaderType ${activeButton==='contacts' ? 'activeButton' : ''}`} onClick={()=>handleActive('contacts')}>Contacts</div>
                            <div className={`leaderType ${activeButton==='dials' ? 'activeButton' : ''}`} onClick={()=>handleActive('dials')}>Dials</div>
                        </div>
                    </div>
                    <div className="leaderBoardContent">
                        {open && (
                            <DateSelector onStartDateChange={handleStartDateChange} onEndDateChange={handleEndDateChange} />    
                        )}
                        <div className={`largeLeaderWrapper ${activeButton==='appointments' ? 'display' : ''}`}>
                            <div className="boardTitle">Appointments</div>
                            <div className="headerNames">
                                <div className="headerName name1">Place</div>
                                <div className="headerName name2">Agent</div>
                                <div className="headerName name3">Appointments</div>
                            </div>
                            {appointment && appointment.map((agent, index) => (
                                <BoardSlot data={agent} key={`appointmentBoard${index}`} place={index}/>
                            ))}
                        </div>

                        <div className={`largeLeaderWrapper ${activeButton==='contacts' ? 'display' : ''}`}>
                            <div className="boardTitle">Contacts</div>
                            <div className="headerNames">
                                <div className="headerName name1">Place</div>
                                <div className="headerName name2">Agent</div>
                                <div className="headerName name3">Contacts</div>
                            </div>
                            {contact && contact.map((agent, index) => (
                                <BoardSlot data={agent} key={`contactBoard${index}`} place={index}/>
                            ))}
                        </div>

                        <div className={`largeLeaderWrapper ${activeButton==='dials' ? 'display' : ''}`}>
                            <div className="boardTitle">Dials</div>
                            <div className="headerNames">
                                <div className="headerName name1">Place</div>
                                <div className="headerName name2">Agent</div>
                                <div className="headerName name3">Dials</div>
                            </div>
                            {dial && dial.map((agent, index) => (
                                <BoardSlot data={agent} key={`dialBoard${index}`} place={index}/>
                            ))}
                        </div>
                    </div>
                </div>
            )} 
        </>
    );
}

export default LeaderBoard;
