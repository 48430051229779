import React from 'react';
import '../../css/workWindow.css';
import DialQueue from './queueTypes/dialQueue';
import MessageQueue from './queueTypes/messageQueue';

function LeadQueue({title,data, agentId, messageModal, getLead}) {

    const formatDate = (dateString) => {
      if (!dateString) {
        return 'Never Contacted';
      }
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString('en-US', { month: 'numeric', day: '2-digit' });
      const formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    
      return `${formattedDate} @ ${formattedTime.toLowerCase()}`;
    };
  
    const formatPhoneNumber = (phoneNumber) => {
      // Remove non-numeric characters from the phone number
      const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    
      // Apply the desired format
      const formattedPhoneNumber = `(${numericPhoneNumber.slice(1, 4)}) ${numericPhoneNumber.slice(4, 7)}-${numericPhoneNumber.slice(7)}`;
    
      return formattedPhoneNumber;
    };
    
    return (
      <>
      {data.map((item, index) => (
        //console.log(item),
          <>
          {title=='Dial' ? (
           <DialQueue agentId={agentId} data={data} formatPhoneNumber={formatPhoneNumber} messageModal={messageModal} getLead={getLead} formatDate={formatDate} />
          ) : (
            <MessageQueue agentId={agentId} data={data} formatPhoneNumber={formatPhoneNumber} messageModal={messageModal} getLead={getLead} formatDate={formatDate} />
          )}
          </>
      ))}
      </>
    );
}

export default LeadQueue;