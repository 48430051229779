/*import React, { useState, useEffect } from 'react';

export const useTimer = (initialSeconds = 15, callback = () => {}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        let interval;
        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            clearInterval(interval);
            callback(); // Execute the callback function when timer reaches zero
        }
        return () => clearInterval(interval);
    }, [isActive, seconds, callback]);

    const clearTimer = () => {
        setIsActive(false);
        setSeconds(initialSeconds);
        
    };
// ... (rest of the code)

   
  
  
    return { seconds, clearTimer,resetTimer };
};*/
import React, { useState, useEffect } from 'react';

export const useTimer = (initialSeconds = 15, callback = () => {}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      callback(); // Execute the callback function when timer reaches zero
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, callback]);

  const clearTimer = () => {
    setIsActive(false);
    setSeconds(initialSeconds);
  };
  const resetTimer = (seconds) => {
        setIsActive(true); // Set isActive back to true to restart the timer
        setSeconds(seconds);
    };
  return { seconds, clearTimer, resetTimer };
};
