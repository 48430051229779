import React, { useEffect } from 'react';
import '../../css/qualifier.css';
import QualifierBlock from './sections/qualifierBlock';


function Qualifier({lead, handleLeadData, handleSetQualifier, setResults}) {
    const leadData=[
        {name:'First',column:'borrower_first'},
        {name:'Last',column:'borrower_last'},
        {name:'DOB',column:'borrower_dob'},
        {name:'Age',column:'borrower_age'},
        {name:'Height',column:'height'},
        {name:'Weight',column:'weight'},
        {name:'Has Insurance',column:'borrower_is_covered'},
        {name:'Current Coverage',column:'borrower_coverage'},
        {name:'Occupation', column:'borrower_occupation'},
        {name:'Primary Concern',column:'concerns'},
        {name:'Tobacco Use',column:'tobacco'},
    ]
    
    const coLeadData=[
        {name:'First',column:'co_borrower_first'},
        {name:'Last',column:'co_borrower_last'},
        {name:'DOB',column:'co_borrower_dob'},
        {name:'Age',column:'co_borrower_age'},
        {name:'Height',column:'co_borrower_height'},
        {name:'Weight',column:'co_borrower_weight'},
        {name:'Has Insurance',column:'co_borrower_is_covered'},
        {name:'Current Coverage',column:'co_borrower_coverage'},
        {name:'Occupation', column:'co_borrower_occupation'},
        {name:'Tobacco Use',column:'co_borrower_tobacco'}
    ]
    const mortgage=[
        {name:'Mortgage Value',column:'mortgage'},
        {name:'Home Value',column:'home_value'},
        {name:'Loan Type',column:'loan_type'},
        {name:'Loan Term',column:'term_length'},
        {name:'Home Payment',column:'payment'}
    ]
    
    useEffect(() => { 
       
    },[])

    return (
        <div className="qualifierData" style={{margin: 'unset', position:'relative'}}>
            <div className='returnToLeadCard solidQuilityActiveButton' onClick={()=>handleSetQualifier()}>Return To Lead</div>
            <div className="title">Qualifier</div>
            <QualifierBlock setResults={setResults} lead={lead} setLead={handleLeadData} title="Lead Information" startOpen={true} leadMainData={leadData}/>
            <QualifierBlock setResults={setResults} lead={lead} setLead={handleLeadData} title="Mortgage Info" startOpen={true} leadMainData={mortgage}/>
            <QualifierBlock setResults={setResults} lead={lead} setLead={handleLeadData} title="Co Lead Information" startOpen={false} leadMainData={coLeadData}/>
        </div>
    );
}

export default Qualifier;