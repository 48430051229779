import React, { useState } from "react";
import '../../css/elements.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faPersonWalkingArrowLoopLeft } from "@fortawesome/pro-regular-svg-icons";



function SelectBox({ title, handleChange, value, options, passBack }) {
    console.log(options);
    const [editingTemplate, setEditTemplate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    //const options=['List Item 1','List Item 2','List Item 3']
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const handleOptionClick = (option) => {
      setSelectedOption(option.name);
      setIsOpen(false);
      handleChange(option,passBack);
    };
    return (
        <div className="selectInputContainer">
            {title && (
                <div className="selectInputTitleWrapper">
                    <span className="selectInputTitle">{title}</span>
                </div>
            )}
            <div className="custom-dropdown textInputBox">
                <div className="selectBoxSelectedItem" onClick={toggleDropdown}>
                    <span className="selectBoxName">{selectedOption || 'Select an option'}</span>
                    <span className="selectBoxIconWrapper"><FontAwesomeIcon className={`selectBoxIcon ${isOpen ? 'rotateIcon':''}`} icon={faChevronUp}/></span>
                </div>
                {isOpen && (
                    <div className="selectBoxOptionWrapper">
                    {options.map((option, index) => (
                        console.log(option.name),
                        <div
                            key={option.id}
                            className="selectBoxOption"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.name}
                        </div>
                    ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SelectBox;

/*

className="textInputBox"*/