import React, {useState} from 'react';
import '../../css/teams.css';
import Warning from '../../template/warning';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';


function AddTeam({handleAddTeam}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [agentSearch, setAgentSearch] = useState('');
    const range=7;
    const [warning, setWarning] = useState(false);
    const cancel = () => {
        setWarning(false);
    };
    const handleAgentSearch = (event) => {
        setAgentSearch(event.target.value);
    };
    const inviteMember = (id,index) => {
        setWarning(id);
    };
    const searchAgents = async () => {
        try {
            const response = await fetchWithDefaults(`/agents/search?search=${agentSearch}&agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status===false){
                
            }
            else{
               
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
   
    return (
        <div className="teamModule">
            <div className="quilityCloseWindow" onClick={()=>handleAddTeam()}>X</div>
            <div className="title">Create a Team</div>
            <div className="teamInnerWrapper">
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Team Name</div>
                    <input type="text" class="addTeamFieldInput"/>
                </div>
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Team Objective</div>
                    <input type="text" class="addTeamFieldInput"/>
                </div>
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Add Required Goals (optional)</div>
                    <div className="goalsWrapper">
                        <div className="goalOption">Dials</div>
                        <div className="goalOption">Contacts</div>
                        <div className="goalOption">Appointments</div>
                        <div className="goalOption">APV</div>
                    </div>
                </div>
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Add Agents (You can always do this later)</div>
                    <input 
                        type="text" 
                        class="addTeamFieldInput" 
                        placeholder="Search for Agents to Add"
                        value={agentSearch} 
                        onChange={handleAgentSearch}
                    /> <span className="search" onClick={()=>searchAgents()}>O</span>
                </div>
            </div>
        </div>
    );
}

export default AddTeam;