import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';


function AppointmentDetail({details,formatDate,openLead}) {
    const [open, setOpen] = useState(false);
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    } 

    return (

            <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
                <div className='quilityNestedBlockHeader'>
                    <div className="quilityParentBlockTitle" style={{width: '170px'}}>Appointments</div>
                    <span style={{marginLeft:'20px'}}>
                        <span className="typeBlock">{details.length} Appointments</span>
                    </span>
                    <span className='quilityBlockIcon'><FontAwesomeIcon className={`transition ${open ? 'rotate' : ''}`} icon={faChevronUp}/></span>
                </div>
          
                <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                    {details.map((detail, index) => (
                        <div className="detailItem" key={"appointment"+index}>
                            <span className="detailTitle">{detail.apptTitle}</span>
                            <span className="detailDate">{formatDate(detail.apptDate)}</span>
                            <span className="detailTimezone">{detail.apptTimezone}</span>
                            <span className="ViewLead" onClick={(event) => { event.stopPropagation(); openLead(detail.apptLead); }}>View Lead</span>
                        </div>
                    ))}
                </div>
            </div>

    );
}

export default AppointmentDetail;