import React from 'react';
import '../../css/workWindow.css';
import DialWindow from './windows/dialWindow';
import TextWindow from './windows/textWindow';


function WindowBlock({title,data, agentId, getLead, windowFocus,leadData, toggleExpand, formatLastContacted,formatBirthDate,formatNumber }) {
    
    const getBlockClass = () => {
        if (windowFocus === null) {
          return '';
        } else if (windowFocus === title) {
          return 'focus';
        } else {
          return 'unfocus';
        }
    };
    return (
        <div className={`windowBlockWrapper ${getBlockClass()}`}  onClick={()=>toggleExpand(title)}>
            <div className="windowBlockHeader">
                <div className="windowBlockTitle">
                    {title}
                </div>
            </div>
            <div className="windowBlockContent">
                {title==='Dial' ? (
                    <DialWindow agentId={agentId} formatLastContacted={formatLastContacted} formatBirthDate={formatBirthDate} formatNumber={formatNumber} getLead={getLead} leadData={leadData} title={title} data={data} />
                ) : (
                    <TextWindow agentId={agentId} formatLastContacted={formatLastContacted} formatBirthDate={formatBirthDate} formatNumber={formatNumber} title={title} data={data} />
                )}
                
            </div>
        </div>
    );
}

export default WindowBlock;