import React, { useState } from 'react';



function SessionConvo({conversationModal}) {
    const [activeIndex, setActiveIndex] = useState(0);
    
    const handleBubbleClick = (index) => {
      setActiveIndex(index);
    };
    return (
        <div className="conversation">
            <div className="toWrapper">
                {conversationModal.map((convo, index) => (
                    <div
                        className={`conversationButton ${activeIndex === index ? 'softActive' : ''}`}
                        key={index}
                        onClick={() => handleBubbleClick(index)}
                    >
                        Conversation # {index + 1}
                    </div>
                ))}
            </div>
            {conversationModal.map((convo, index) => (
                <div
                    className={`conversationHolder ${activeIndex === index ? 'viewing' : ''}`}
                    key={'convo' + index}
                >
                    <span className="innerConvoWrap">
                        {convo[Object.keys(convo)[0]].map((conversation, innerIndex) => (
                            <div className={`sessionConvoBubble session${conversation.direction}`} key={`convo${innerIndex}`}>
                            {conversation.message}
                            </div>
                        ))}
                    </span>
                </div>
            ))}
            <div className="conversationInputWrapper">
                <div className="conversationInputBarWrapper">
                    <input type="text" className="conversationInput" />
                </div>
            </div>
        </div>
    );
}

export default SessionConvo;