import React, { useState, useEffect } from 'react';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const MedicationInfo = ({addCondition, usedMedications, addUsedMedications}) => {
    const [medication, setMedication] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [conditions, setConditions] = useState([]);
    const [matches, setMatches] = useState(false);
    const searchMatches = async (data,searchTerm) => {
        const foundMatches = data.filter(item => item.search_name && searchTerm.toLowerCase().includes(item.search_name.toLowerCase()));
        setMatches(foundMatches);
        setResponseData(searchTerm);
        setMedication('');
        return;
    };

    const fetchData = async () => {
        if(medication !=''){
            addUsedMedications(medication);
            try {
                const response = await fetch(
                    `https://api.fda.gov/drug/label.json?api_key=I3cia1do3ZA2eUuPHmcBZKpRfbPsgxU4UDdQmhPz&search=openfda.brand_name%3A%22${encodeURIComponent(
                    medication
                    )}%22`,
                    {
                    method: 'GET',
                    headers: {
                        Authorization: 'Basic STNjaWExZG8zWkEyZVV1UEhtY0JaS3BSZmJQc2d4VTRVRGRRbWhQejo=',
                    },
                    }
                );
                const data = await response.json();
                if (!response.ok) {
                    setMatches(false);
                    throw new Error('Network response was not ok');
                }
                try {
                    const response = await fetchWithDefaults(`/medicalConditions`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const jsonData = await response.json();
                    setConditions(jsonData);
                    await searchMatches(jsonData,data.results[0].indications_and_usage[0]);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } catch (error) {
                setMatches(false);
                console.error('Error fetching data:', error);
            }
        }
    };
    const handleInputChange = (event) => {
        setMedication(event.target.value);
    };

    return (
        <div style={{ width: '50%',display:'inline-block',verticalAlign: 'top'}}>
            <input
                type="text"
                className="qualifierFieldInput"
                value={medication}
                onChange={handleInputChange}
                placeholder="Medication name..."
                style={{paddingRight: '40px'}}
            />
            <span className="medSearchIcon" onClick={fetchData}><FontAwesomeIcon icon={faMagnifyingGlass}/></span>
            {matches && (
                <>
                    <div>Possible Conditions:</div>

                    {matches.map((match, index) => (
                        <div className="conditionBubble" key={index} onClick={()=>addCondition(match.search_name)}>{match.search_name}</div>
                    ))}
                </>
            )}
        </div>
    );
};

export default MedicationInfo;
