import { fetchWithDefaults } from '../config/fetchDefault';

const gatherConversation= async (currentLeadId, setConversations)=>{
    if(currentLeadId){
        setConversations([]);
        try {
            const response = await fetchWithDefaults(`/getConversationForLead?lead=${currentLeadId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
           /* if(jsonData.status===true){
                //setConversations([newItem, ...conversation])
            }
            */
            setConversations(jsonData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
}

export {gatherConversation};