import React,{useState,useRef, useEffect} from 'react';
import '../../css/frontPage.css';
import LeadSort from './leadSort';
import { setQueue } from '../../features/queueSlice';
import { useSelector, useDispatch } from 'react-redux';


function LeadsQueue({selectedLeads,calledQueue, setSelectedLeads, bulkOpen, setLeadCard, expanded, queueExpanded, holdingQueue,formatDate}) {
    const handleOpenLead=(lead)=>{
        setLeadCard([lead]);
    }
    const dispatch=useDispatch();
    const queue=useSelector((state) => state.queue.queue);
    //const [display, setDisplay] = useState(false);
    //const [currentPage, setCurrentPage] = useState(0);
    const [leadOrder, setLeadOrder] = useState();
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('');
    const lastCheckedLead = useRef(null);
    const [selectedLeads2, setSelectedLeads2] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    function formatAssigned(inputDate, inputDate2) {
        if(inputDate=="" || inputDate==null){
            inputDate=inputDate2;
        }
        const date = new Date(inputDate);
        
        // Extract day, month, and year
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear().toString();
        
        // Create the formatted date string
        const formattedDate = `${month}/${day}/${year}`;
        
        return formattedDate;
    }

    const handleSort = (criteria) => {
        if (sortBy === criteria) {
            // If already sorted by this criteria, toggle sort direction
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a new criteria, set sort criteria and direction
            setSortBy(criteria);
            setSortDirection('asc');
        }
  
        const sortedData = queue.slice().sort((a, b) => {
            if (sortBy) {
                // Custom comparison function to handle null values
                const compareFunction = (valueA, valueB) => {
                    if (Number.isInteger(valueA)) {
                        valueA = valueA.toString();
                    } 
                    if (Number.isInteger(valueB)) {
                        valueB = valueB.toString();
                    } 
                    if (valueA === null && valueB === null) return 0;
                    if (valueA === null) return sortDirection === 'asc' ? 1 : -1;
                    if (valueB === null) return sortDirection === 'asc' ? -1 : 1;
                    return valueA.localeCompare(valueB);
                };
        
                // Sort by the selected criteria using the custom comparison function
                return compareFunction(a[sortBy], b[sortBy]) * (sortDirection === 'asc' ? 1 : -1);
            } 
            else {
                // If no criteria selected, maintain original order
                return 0;
            }
        });
        dispatch(setQueue(sortedData));
    };
  
    
    const handleCheckboxChange = (leadId, shiftKeyPressed) => {
        let updatedSelectedLeads;

        if (shiftKeyPressed && lastCheckedLead.current !== null) {
            const lastIndex = leadOrder.findIndex(lead => lead.lead_id === lastCheckedLead.current);
            const currentIndex = leadOrder.findIndex(lead => lead.lead_id === leadId);
            const start = Math.min(lastIndex, currentIndex);
            const end = Math.max(lastIndex, currentIndex);

            updatedSelectedLeads = [...selectedLeads];
            for (let i = start; i <= end; i++) {
                const leadId = leadOrder[i].lead_id;
                if (!updatedSelectedLeads.includes(leadId)) {
                    updatedSelectedLeads.push(leadId);
                }
            }
        } else {
            updatedSelectedLeads = selectedLeads.includes(leadId)
                ? selectedLeads.filter(id => id !== leadId)
                : [...selectedLeads, leadId];
        }

        setSelectedLeads(updatedSelectedLeads);
        setSelectedLeads2(updatedSelectedLeads);
        lastCheckedLead.current = leadId;
    };
    const handleSelectAll=()=>{
        if(!selectAll){
            setSelectedLeads2(queue.map(lead => lead.lead_id));
            setSelectedLeads(queue.map(lead => lead.lead_id));
            setSelectAll(true);
        }
        else{
            setSelectedLeads2([]);
            setSelectedLeads([]);
            setSelectAll(false);
        }
    }
    /*
    useEffect(() => {
        if (data) { // Assuming data is defined somewhere
            const updatedJsonData = data.map((item, index) => ({
                ...item,
                queuePos: index
            }));
            setLeadOrder(updatedJsonData);
        }
    }, [data]);*/
    return (
       
     
        <>        
            <div className="leadWrapper" style={{  marginTop:queueExpanded ? '10px':'' , height: expanded ? '100%' : queueExpanded ? 'calc(100% - 445px)': '100%', overflow: 'hidden', color: 'black', width: queueExpanded ? '85%':''}}>

                <span className="leadListSort">
                    <span className="checkAllWrapper">
                        <input 
                            type="checkbox" 
                            //value={lead.lead_id}
                            //checked={selectedLeads2.includes(lead.lead_id)}
                            onChange={(e) => handleSelectAll()}
                        />
                    </span>
                    <LeadSort handleSort={handleSort} expanded={expanded} queueExpanded={queueExpanded} sortDirection={sortDirection} sortBy={sortBy}/>
                </span>
                <span className="innerLeadWrapper" style={{height:queueExpanded ? 'calc(100% - 65px)' :''}}>
                    

                        <>
                            {calledQueue && calledQueue.map((lead, index) => {
                                if(lead.borrower_first){
                                    lead.borrower_first=lead.borrower_first.replace(/\\/g, '');
                                }
                                else{
                                    lead.borrower_first='unknown';
                                }
                                if(lead.borrower_last){
                                    lead.borrower_last=lead.borrower_last.replace(/\\/g, '');
                                }
                                else{
                                    lead.borrower_last='unknown';
                                }


                                return(
                                    <span key={'newindividualLead'+index}>
                                        <div className="individualLeadWrapper called" key={'individualLead'+index} onClick={() => handleOpenLead(lead)}>
                                            <div className={`checkBoxWrapper ${bulkOpen ? 'display' : ''}`} onClick={(e)=> e.stopPropagation()}>
                                                <input 
                                                    type="checkbox" 
                                                    value={lead.lead_id}
                                                    checked={selectedLeads2.includes(lead.lead_id)}
                                                    onChange={(e) => handleCheckboxChange(lead.lead_id, e.shiftKey)}
                                                />
                                            </div>
                                            <div className="leadListName leadItem" id="leadBlockName">{lead.borrower_last+', '+lead.borrower_first}</div>
                                            
                                            {lead.last_contacted=='' || lead.last_contacted==null ? (
                                                <div className="leadListLastContact leadItem" id="leadBlockContacted">Never Contacted</div>
                                            ) : (
                                                <div className="leadListLastContact leadItem" id="leadBlockContacted">{formatDate(lead.last_contacted)}</div>
                                            )}
                                        </div>
                                    </span>
                                )
                            })}
                            {queue.length > 0 ? queue.map((lead, index) => {

                                return(
                                    <span key={'newindividualLead'+index}>
                                    {expanded ? (
                                        <div className={`individualLeadWrapper ${lead.called ? 'called' : ''}`} key={'individualLead'+index} onClick={() => handleOpenLead(lead)}>
                                            <div className={`checkBoxWrapper ${bulkOpen ? 'display' : ''}`} onClick={(e)=> e.stopPropagation()}>
                                                <input 
                                                    type="checkbox" 
                                                    value={lead.lead_id}
                                                    checked={selectedLeads2.includes(lead.lead_id)}
                                                    onChange={(e) => handleCheckboxChange(lead.lead_id, e.shiftKey)}
                                                />
                                            </div>
                                            <div className="leadListName leadItem" id="leadBlockName">{lead.borrower_last.replace(/\\/g, '')+', '+lead.borrower_first.replace(/\\/g, '')}</div>
                                            {lead.status=='' || lead.status==null ? (
                                                <div className="leadListStatus leadItem">No Status</div>
                                            ) : (
                                                <div className="leadListStatus leadItem">{lead.status}</div>
                                            )}
                                            <div className="leadListState leadItem" id="leadBlockState">{lead.state}</div>
                                            {lead.last_contacted=='' || lead.last_contacted==null ? (
                                                <div className="leadListLastContact leadItem" id="leadBlockContacted">Never Contacted</div>
                                            ) : (
                                                <div className="leadListLastContact leadItem" id="leadBlockContacted">{formatDate(lead.last_contacted)}</div>
                                            )}
                                            <div className="leadListDateAssigned leadItem">{formatAssigned(lead.date_entered, lead.date_uploaded)}</div>
                                            <div className="leadListAmountContacted leadItem">{lead.amount_contacted}</div>
                                        </div>
                                    ) : queueExpanded ? (
                                        <div className={`individualLeadWrapper ${lead.called ? 'called' : ''}`} key={'individualLead'+index} onClick={() => handleOpenLead(lead)}>
                                            <div className={`checkBoxWrapper ${bulkOpen ? 'display' : ''}`} onClick={(e)=> e.stopPropagation()}>
                                                <input 
                                                    type="checkbox" 
                                                    value={lead.lead_id}
                                                    checked={selectedLeads2.includes(lead.lead_id)}
                                                    onChange={(e) => handleCheckboxChange(lead.lead_id, e.shiftKey)}
                                                />
                                            </div>
                                            <div className="leadListName leadItem" id="leadBlockName">{lead.borrower_last+', '+lead.borrower_first}</div>
                                            
                                            {lead.last_contacted=='' || lead.last_contacted==null ? (
                                                <div className="leadListLastContact leadItem" id="leadBlockContacted">Never Contacted</div>
                                            ) : (
                                                <div className="leadListLastContact leadItem" id="leadBlockContacted">{lead.last_contacted}</div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className={`individualLeadWrapper ${lead.called ? 'called' : ''}`} key={'individualLead'+index} onClick={() => handleOpenLead(lead)}>
                                            <div className={`checkBoxWrapper display`} onClick={(e)=> e.stopPropagation()}>
                                                <input 
                                                    type="checkbox" 
                                                    value={lead.lead_id}
                                                    checked={selectedLeads2.includes(lead.lead_id)}
                                                    onChange={(e) => handleCheckboxChange(lead.lead_id, e.shiftKey)}
                                                />
                                            </div>
                                            <div className="leadListName leadItem" id="leadBlockName">{lead.borrower_last+', '+lead.borrower_first}</div>
                                            
                                            <div className="leadListState leadItem" id="leadBlockState">{lead.state}</div>
                                            {lead.last_contacted=='' || lead.last_contacted==null ? (
                                                <div className="leadListLastContact leadItem" id="leadBlockContacted">Never Contacted</div>
                                            ) : (
                                                <div className="leadListLastContact leadItem" id="leadBlockContacted">{formatDate(lead.last_contacted)}</div>
                                            )}
    
                                            <div className="leadListAmountContacted leadItem">{lead.amount_contacted}</div>
                                        </div>
                                    )} 
                                    </span>
                                )
                            }) : (
                                <div className="noResults">
                                    This search has no results
                                </div>
                            )}
                        </>
                    
                </span>
            </div>
        </> 
    );
}

export default LeadsQueue;

