import { setQueue, setBackup } from '../features/queueSlice';

const updateLeadInfo = async (queue, column, data, leadId, dispatch, type)=>{
    const updatedArray = queue.map(item => {
        // Check if the current item's ids matches the targetTagId
        if (item.lead_id === leadId) {
            // Return a new object with the updated tag_ids
            return {
                ...item,
                [column]: data
            };
        } else {
            // If it doesn't match, return the item as is
            return item;
        }
        
    });
    if(type==='backup'){
        dispatch(setBackup(updatedArray));
    }
    else{
        dispatch(setQueue(updatedArray));
    }
    return true;
}
export { updateLeadInfo};