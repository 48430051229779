import React, { useState } from "react";
import '../../css/templates.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faPencil } from "@fortawesome/pro-regular-svg-icons";
import AddTag from './addTag';
import Warning from '../warning';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';


function LeadTag({ data,formatDate }) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [editingTemplate, setEditTemplate] = useState(false);
    const [tags, setTags] = useState(data[0]);
    const [addScript, setAddScript] = useState(false);
    const [editingItemId, setEditingItemId] = useState(null);
    const [newTemplate, setNewTemplate] = useState(null);
    const [warning, setWarning] = useState(false);
    const [editedText, setEditedText] = useState({});
    const [editedIndex, setEditedIndex] = useState(null);


    const cancel = () => {
        setWarning(false);
    };
    const confirm = async () => {
        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    tagId: warning
                }) 
            };
          
            const response = await fetchWithDefaults('/deleteTag', requestOptions);

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
         
            if(jsonData.status==true){
                const newArray = [...tags];

                // Find the index of the object with the specified ID
                const indexToRemove = newArray.findIndex(obj => obj.id === warning);

                // Remove the object at the found index
                if (indexToRemove !== -1) {
                    newArray.splice(indexToRemove, 1);
                    setTags(newArray);
                }
                setWarning(false);
            }
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const addAScript = () => {
        setAddScript(!addScript);
    };
    const handleInputChange = (e, index) => {
        setEditedText({ ...editedText, [index]: e.target.value });
    };
    const handleEditName = (index, text) => {
        setEditedIndex(index);
        //setEditedText({ [index]: text });
        setEditedText({ ...editedText, [index]: text });
    };
    const setStatusOption = (contentType, content, tagId) => {
        //setCurrentStatusReminder(content);
        updateTemplate(tagId, contentType, content);
    };
   /* const setStatusWorkflow = (contentType, content, tagId) => {
        //setCurrentStatusReminder(content);
        updateTemplate(tagId, contentType, content);
    };*/
    const removeItem = (id,index) => {
        setWarning(id);
    };
    
    const updateTemplate = async (tag, type, content) => {
        setEditedIndex(null);
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    tag: tag,
                    content:content,
                    type:type
                }) 
            };
          
            const response = await fetchWithDefaults('/updateTag', requestOptions);
           
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    return (
        <div className="outerWrapper">
            {warning && 
                <Warning title={"Delete Tag"} cancel={cancel} confirm={confirm} message={"Are you sure you want to remove this tag?"} buttons={[{name:'Don\'t Delete',action:'cancel'},{name:"Confirm Delete", action:'confirm'}]} />
            }
            
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">
                    <FontAwesomeIcon icon={faClipboardList} className="colorIcon" />
                </span>
                Tags
            </span>
            <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
            >

            {addScript ? (
               <AddTag agentId={agentId} addATemplate={addAScript} setNewTemplate={setNewTemplate} type={'Tag'}/>

            ):(
                null
            )}
                <div className={`frontPageQuilityParentBlockWrapper ${addScript ? 'closeMainBlock' : editingTemplate ? 'closeMainBlock' : ''}`} style={{width: '50%'}}>
                    <div className="quilityParentBlockHeader">
                        <div className="quilityParentBlockTitle">My Lead Tags</div>
                    </div>
                    <div className="quilityParentBlockContent">
                        {newTemplate == null ? '' : (
                            <div className={`quilityNestedBlockWrapper ${editingItemId === newTemplate.id ? 'focusBigBlock' : ''}`} key={'temp'} style={{marginTop: '25px'}}>
                                <div className="quilityCloseWindow oulineQuilityDestructiveButton" onClick={()=> removeItem(newTemplate.id, 'temp')}>X</div>
                                <div className="quilityNestedBlockHeader">
                                    <div className="quilityParentBlockTitle">
                                        {newTemplate.name}
                                    </div>
                                </div>
                                <div className="quilityNestedBlockContent">
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '130px'}}>Tag Name: </span>
                                        {newTemplate.name}
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '130px'}}>Date Created: </span>
                                        Just Now
                                    </div>
                                </div>
                            </div>
                        )}
                        {tags.map((item, index) => (    
                            <div className={`quilityNestedBlockWrapper ${editingItemId === item.id ? 'focusBigBlock' : ''}`} key={`tags${index}`} style={{marginTop: '25px'}}>
                                <div className="quilityCloseWindow oulineQuilityDestructiveButton" onClick={()=> removeItem(item.id, index)}>X</div>
                                <div className="quilityNestedBlockHeader">
                                    <div className="quilityParentBlockTitle">
                                        {editedText[index] ?? item.name}
                                    </div>
                                </div>
                                <div className="quilityNestedBlockContent">
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '130px'}}>Tag Name: </span>
                                        {editedIndex === index ? (
                                            <input
                                                type="text"
                                                className="standardInputBox"
                                                value={editedText[index] || item.name}
                                                onChange={(e) => handleInputChange(e, index)}
                                                onBlur={() => setStatusOption('name',editedText[index], item.id)}
                                                
                                                autoFocus
                                            />
                                            ) : (
                                            <>
                                                <span style={{ marginLeft: "5px" }} onClick={() =>
                                                    handleEditName(index, editedText[index])
                                                }>
                                                {editedText[index] || item.name}
                                                </span>
                                                <span
                                                className="hoverIcon"
                                                onClick={() =>
                                                    handleEditName(index, editedText[index])
                                                }
                                                >
                                                <FontAwesomeIcon icon={faPencil} />
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '130px'}}>Date Created: </span>
                                        {item.created==null ? " Uknown" : formatDate(item.created)}
                                    </div>
                                
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="blockFooter">
                        <div
                            className="buttonWrapper"
                            style={{ marginTop: " 5px", marginLeft: "10px" }}
                        >
                            <div className="dataButtonBlock">Tags: {tags.length}</div>
                            <div className="actionButtonBlock" style={{marginTop: '5px'}}>
                                <div className="accentButton solidQuilityActiveButton" onClick={()=>addAScript()}>
                                +  New Tag
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LeadTag;

