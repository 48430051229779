import React, { useState } from "react";
import EinProfileForm from "./einForm";
import SoleProfileForm from "./solePropForm";

function CreateCustomerProfile({profileType, setProfileType, setAddCustProfile, submitCustomerProfile}) {
    

    return (
        <div className="quilityPopupFormWrapper">
            <div className="quilityPopupFormContent">
                <div className="quilityCloseWindow" onClick={()=>setAddCustProfile(false)}>X</div>
                {profileType && (
                    <div className="solidQuilityActiveButton startOverButton" onClick={()=>setProfileType(false)}>Start Over</div>
                )}
                <div className="formTitle">Create New Trust Profile</div>
                {!profileType ? (
                    <div>
                        <div className="bigSelectionButton solidQuilityActiveButton" onClick={()=>setProfileType('ein')}>I have an EIN number</div>
                        <div className="bigSelectionButton solidQuilityActiveButton" onClick={()=>setProfileType('sole')}>No EIN Number</div>
                    </div>
                ) : profileType==='ein' ? (
                    <EinProfileForm submitCustomerProfile={submitCustomerProfile}/>
                ) : (
                    <SoleProfileForm submitCustomerProfile={submitCustomerProfile}/>
                )}
            </div>
        </div>
    );
}

export default CreateCustomerProfile;

