import React, { useState, useEffect } from "react";
import '../../css/workflow.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from "react-dnd";
import DropTarget from "./dropZone";
import WorkflowWait from "./waits";
import WorkflowSpecTime from "./specificTime";
import { updateWorkflow } from '../../utilities/workflowFunctions';
import { useSelector } from 'react-redux';
import DraggableBlock from "./draggableBlock";
import FinishFlow from "./finishFlow";

function EditWorkflow({setEditFlow, editFlow, setWorkflows }) {
    const [templateName, setTemplateName] = useState('');
    const agentInfo=useSelector((state) => state.agent.userData);
    const [exitConditions, setExitConditions] = useState([]);
    const texts=useSelector((state) => state.texts.texts);
    const status=useSelector((state) => state.status.status);
    const [showExitCondition, setShowExitConditions] = useState(false);
    const tags=useSelector((state) => state.tags.tags);
    const emails=useSelector((state) => state.email.email);
    const [flow, setFlow] = useState([]);
    const [flowId, setFlowId] = useState(false);

    const [wait, setWaits] = useState([
        {name:'5 Seconds', id:('wa_'+5/86400)},
        {name:'10 Seconds', id:('wa_'+10/86400)},
        {name:'20 Seconds', id:('wa_'+20/86400)},
        {name:'30 Seconds', id:('wa_'+30/86400)},
        {name:'45 Seconds', id:('wa_'+45/86400)},
        {name:'1 Minute', id:('wa_'+60/86400)},
        {name:'2 Minutes', id:('wa_'+120/86400)},
        {name:'3 Minutes', id:('wa_'+180/86400)},
        {name:'4 Minutes', id:('wa_'+240/86400)},
        {name:'5 Minutes', id:('wa_'+300/86400)},
        {name:'10 Minutes', id:('wa_'+600/86400)},
        {name:'20 Minutes', id:('wa_'+1200/86400)},
        {name:'30 Minutes', id:('wa_'+1800/86400)},
        {name:'45 Minutes', id:('wa_'+2700/86400)},
        {name:'1 Hour', id:('wa_'+3600/86400)},
        {name:'2 Hours', id:('wa_'+7200/86400)},
        {name:'3 Hours', id:('wa_'+10800/86400)},
        {name:'4 Hours', id:('wa_'+14400/86400)},
        {name:'5 Hours', id:('wa_'+18000/86400)},
        {name:'6 Hours', id:('wa_'+21600/86400)},
        {name:'7 Hours', id:('wa_'+25200/86400)},
        {name:'8 Hours', id:('wa_'+28800/86400)},
        {name:'9 Hours', id:('wa_'+32400/86400)},
        {name:'10 Hours', id:('wa_'+36000/86400)},
        {name:'12 Hours', id:('wa_'+43200/86400)},
        {name:'24 Hours', id:'wa_1'},
        {name:'2 Days', id:'wa_2'},
        {name:'3 Days', id:'wa_3'},
        {name:'4 Days', id:'wa_4'},
        {name:'5 Days', id:'wa_5'},
        {name:'6 Days', id:'wa_6'},
        {name:'7 Days', id:'wa_7'},
        {name:'14 Days', id:'wa_14'},
        {name:'21 Days', id:'wa_21'},
        {name:'28 Days', id:'wa_28'},
        {name:'30 Days', id:'wa_30'},
        {name:'31 Days', id:'wa_31'}
    ]);

    const items=editFlow.order.split(',').filter(item => item !== '');

    const arrangeItems= (data)=>{
        const categorizedItems = [];
        data.forEach(item => {
            let category;
            let template;
            if (/^\d{1,2}:\d{2}(AM|PM)$/i.test(item)) {
                category = "Wait Until";
                template = { id: item, name: item, type:"Specific Time" };
            } else {
            const prefix = item.substring(0, 3);
    
           
            switch (prefix) {
                case "tt_":
                    category = "Send Text";
                    template = texts.find(template => template.id === item);
                    break;
                case "wa_":
                    category = "Wait";
                    template = wait.find(template => template.id === item);
                    break;
                case "et_":
                    category = "Send Email";
                    template = emails.find(template => template.id === item);
                    break;
                case "ei_":
                    category = "Send Email";
                    template = emails.find(template => template.id === item);
                    break;
                case "tag":
                    category = "Tag Lead";
                    template = tags.find(template => template.id === item);
                    break;
                case "si_":
                    category = "Status Lead";
                    template = status.find(template => template.id === item);
                    break;
                case "obj":
                    category = "Status Lead";
                    template = status.find(template => template.id === item);
                    break;
                default:
                    category = "unknown";
                    break;
            }
        }
            if (template) {
                categorizedItems.push({ id: item, name: template.name, type: category });
            }

        });
        setFlow(categorizedItems);
        return categorizedItems;
    }
    const arrangeExit= (data)=>{
        const categorizedItems = [];
        data.forEach(item => {
            let category;
            let template;
          
            const prefix = item.substring(0, 3);
    
            switch (prefix) {
                case "si_":
                    category = "Status Lead";
                    template = status.find(template => template.id === item);
                    break;
                case "obj":
                    category = "Status Lead";
                    template = status.find(template => template.id === item);
                    break;
                case "tex":
                    category = "Lead Responds";
                    template = { id: 'text response', name: "Lead Texts Back", type:"" };
                    break;
                default:
                    category = "unknown";
                    break;
            }
            if (template) {
                categorizedItems.push({ id: item, name: template.name, type: category });
            }

        });
        setExitConditions(categorizedItems);
        //return categorizedItems;
    }
    const handleDrop = (item) => {
        setFlow((prevFlow) => [...prevFlow, item]);
    };
    const removeFromFlow = (itemId) => {
        setFlow((prevFlow) => prevFlow.filter((flowItem) => flowItem.id !== itemId));
    };
    const showExitConditions = () => {
        setShowExitConditions(!showExitCondition);
    };
    const handleExitDrop = (item) => {
        setExitConditions((prevExit) => [...prevExit, item]);
    };
    const removeFromExit = (itemId) => {
        setExitConditions((prevExit) => prevExit.filter((exitItem) => exitItem.id !== itemId));
    };
    const updateFlow= async ()=>{
       
        const status = await updateWorkflow(flow, exitConditions, agentInfo.agentId, agentInfo.teamId, flowId);
        if(status){
            setWorkflows(prevData =>
                prevData.map(item => {
                    if (item.id === flowId) {
                        console.log({ ...item, exit_conditions: status.flow, order:status.exit});
                        return { ...item, exit_conditions: status.exit, order:status.flow };
                    }
                    return item;
                })
            );
            setEditFlow(false);
        }
    }
    useEffect(() => {
        arrangeItems(items);
        
        if(editFlow.exit_conditions.split(',').length > 0){
            arrangeExit(editFlow.exit_conditions.split(',').filter(item => item !== ''))
        }
        else{
            setExitConditions([]);
        }
        if(editFlow){
            setFlowId(editFlow.id);
        }
    },[])
    return (
        <div className="frontPageQuilityParentBlockWrapper" style={{ width: '75%',position: 'relative'}}>
            <div className="quilityCloseWindow" onClick={()=>setEditFlow(false)}>x</div>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">Edit Flow - {editFlow.name}</div>
            </div>
            <div className="quilityParentBlockContent" style={{height: '600px',overflow: 'auto'}}>
                {showExitCondition ? (
                    <FinishFlow removeFromExit={removeFromExit} exitConditions={exitConditions} status={status} handleExitDrop={handleExitDrop}/>
                ) : (
                    <DndProvider backend={HTML5Backend}>
                        <div className="quilityNestedBlockWrapper workflowBuilderLeft editWorkflowBuilderLeft">
                            <div className="workflowStartBlock">
                                <div className="workflowStartBlockTitle">Lead Put In Workflow</div>
                            </div>
                            <div className="dottedLine"></div>
                            {flow && flow.map((item, index) => ( 
                                <>
                                    <div className='draggedElement'><div className="removeFromFlow" onClick={()=>removeFromFlow(item.id)}>X</div><div className="draggedTitle" >{item.type}</div><div>{item.name}</div></div>
                                    <div className="dottedLine"></div>
                                </>   
                            ))}
                            
                            <DropTarget onDrop={handleDrop} internalText={"Drop Actions Here"}>

                            </DropTarget>
                        </div>
                        <div className="quilityNestedBlockWrapper workflowBuilderRight editWorkflowBuilderRight">
                            <div className="title">Action Items</div>
                            <DraggableBlock  data={status} type={'Status'} action={'Status Lead'} title={'Statuses'} />
                            <DraggableBlock  data={tags} type={'Tag'} action={'Tag Lead'} title={'Tags'} />
                            <WorkflowWait />
                            <WorkflowSpecTime />
                            <DraggableBlock  data={texts} type={'Text'} action={'Send Text'} title={'Text Templates'} />
                            <DraggableBlock  data={emails} type={'Email'} action={'Send Email'} title={'Email Templates'} />
                        </div>
                    </DndProvider>
                )}
            </div>
            
            {showExitCondition ? (
                <div className="buttonWrapper">
                    <span style={{float: 'left'}}>
                        <div className="advanceWorkflow solidQuilityActiveButton" onClick={()=>setShowExitConditions(false)}>Previous Step</div>
                    </span>
                    <span style={{float: 'right'}}>
                        <div className="advanceWorkflow solidQuilityActiveButton" onClick={()=>updateFlow()}>Finish</div>
                    </span>
                </div>
            ) : (
                <div className="buttonWrapper" style={{float: 'right'}}>
                    <div className="advanceWorkflow solidQuilityActiveButton" onClick={()=>showExitConditions()}>Next Step</div>
                </div>
            )}
                
            
        </div> 
    );
}

export default EditWorkflow;
/*
 
*/
