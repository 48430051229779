import React, { useState } from 'react';
import Calendar from './calendar';
import ScheduleCall from './scheduleCall';
import AttachFile from './attachFile';


function LeadTopBarButton({title, setQualifier, qualifier, leadData, newEventPackage,calendarUsing, defaultCalendar, setEvent, newAppt, setNewAppt}) {
    
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(false);
    const [calendar, setCalendar] = useState(false);
    const handleClick = (link) => {
        window.open(link, '_blank');
    };
    const handleSetQualifier = () => {
        setQualifier(true)
    };
    const handleOpen=(title)=>{
        setIsOpen(!isOpen);
        if(title===selected){
            setSelected(false);
        }
        else{
            setSelected(title)
        }
    }
    const calendarChange=(calendar)=>{
        setCalendar(calendar);
    }
    return (
        <div className="leadCardButtonOuterWrapper" style={{flex: '1'}}>
            {title==="Lead PDF" ?(
                 <div className="leadTopBarButton"><a href="#" onClick={()=>handleClick(leadData.pdf)} className="pdfLink">{title}</a></div>
            ): title==='Appointment' ? (
                <>
                    <div className="leadTopBarButton" onClick={()=>handleOpen(title)}>{title}</div>
                    <div className={`calendarWrapper ${isOpen ? 'openCal' : ''}`}>
                        {isOpen && (
                            <Calendar calendarChange={calendarChange} calendarUsing={calendarUsing} newAppt={newAppt} setNewAppt={setNewAppt} defaultCalendar={defaultCalendar} newEventPackage={newEventPackage} setIsOpen={setIsOpen} setEvent={setEvent}/>
                        )}
                    </div>
                </>
            ) :  title==='Qualifier' ? (
                    <div className={`leadTopBarButton ${qualifier ? 'activeLeadCardButton' : ''}`} onClick={()=>setQualifier(true)}>{title}</div>
            ) : title==="Schedule Call" ? (
                <>
                    <div className={`leadTopBarButton ${selected===title ? 'focusButton' : ''}`} onClick={()=>handleOpen(title)}>{title}</div>
                    <div className={`dialogBox ${isOpen ? 'openBox' : ''}`}>
                        <ScheduleCall />
                    </div>
                </>
            ) : title==="Attach File" ? (
                <>
                    <div className={`leadTopBarButton ${selected===title ? 'focusButton' : ''}`} onClick={()=>handleOpen(title)}>{title}</div>
                    <div className={`dialogBox ${isOpen ? 'openBox' : ''}`} style={{overflow: 'auto'}}>
                        <AttachFile files={leadData.file_link} leadId={leadData.lead_id}/>
                    </div>
                </>
            ) : (
                <>
                    <div className={`leadTopBarButton ${selected===title ? 'focusButton' : ''}`} onClick={()=>handleOpen(title)}>{title}</div>
                    <div className={`dialogBox ${isOpen ? 'openBox' : ''}`}>
                    </div>
                </>
            )}
        </div>
    );
    
}

export default LeadTopBarButton;
