import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { fetchWithDefaults } from '../../../config/fetchDefault';

function QualifierSection({lead, data, setLead, agentId, handleSetProfile}) {
    const handleChange = (event, column) => {
        const { value } = event.target;
        //update the lead
        setLead(column, value);
    };
    const handleBlur = async (event, column, leadId) => {
        const { value } = event.target;
        handleSetProfile(column, value);
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent:agentId,
                    lead:leadId,
                    column:column,
                    value:value
                }) 
            };
          
            const response = await fetchWithDefaults('/updateLead', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div className="qualifierSection">
            {data && data.map((item, index) => ( 
                <div className="qualifierFieldWrapper">
                    <div className="qualifierFieldTitle">{item.name}</div>
                    <input 
                        type="text" 
                        className="qualifierFieldInput" 
                        value={lead[0][item.column]}
                        onChange={(event) => handleChange(event, item.column)}
                        onBlur={(event) => handleBlur(event, item.column, lead[0].lead_id)}
                    />
                </div>
            ))}
        </div>
    );
}

export default QualifierSection;
