import React, {useEffect, useState} from 'react';
import { getLeadOrders } from '../../utilities/dashFunction';

function PastOrders({ }) {    
    const [pastOrders, setPastOrders] = useState(false);
    useEffect(() => {
        getLeadOrders(setPastOrders)
    },[])
    return (
        <div className={`quilityParentBlockWrapper twoX selectable`}>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">Previous Orders</div>
            </div>
            
            <div className="quilityParentBlockContent">
                
                <div className={`quilityNestedBlockWrapper `} style={{marginTop: '25px'}}>
                    {pastOrders && pastOrders.map((item, index) =>(
                        <div className="quilityNestedBlockContent">
                            <div className="quilityDataLine">
                                <div className="quilityDataLineTitle" style={{display: 'inline-block',width: '200px'}}>{item.vendorName}</div>
                                <div class="quilityDataLineValue marketLine" style={{maxWidth: 'fit-content'}}>{item.order_type}</div>
                                <div class="quilityDataLineValue marketLine" style={{maxWidth: 'fit-content'}}>{item.states}</div>
                                <div class="quilityDataLineValue marketLine">2/19/2024</div>

                            </div>
                        </div> 
                    ))}   
                </div>
            </div>
            <div className="clearFooter">
                
            </div>
        </div>
    );
}

export default PastOrders;

/*<div className="quilityDataLine" key={`leadPreview${index}`}>
                            <span className="quilityDataLineTitle extraIndent" style={{display: 'inline-block',width: '200px'}}></span>
                        </div>*/
