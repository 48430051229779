import React, { useState } from "react";


function IntegrationBlock({title, logo, button, status, link }) {

    const [isOpen, setIsOpen] = useState(false);
    const authorize = async (link)=>{
        window.location.href = link;
    }
    return (
        <div className="integrationBlock">
            <span className="integrationLogo"><img src={logo} alt="Google Calendar Logo" style={{width:'100%'}}/></span>
            <span className="integrationTitle">{title}</span>
            <span className={`integrationStatus ${status ? 'integrationConnected' : 'integrationDisconnected'}`}>
                {status ? (
                    'Connected'
                ) : (
                    'Disconnected'
                )}
            </span>
            <span className="integrationToggleHolder">
                {button !=='toggle' ? (
                    status ? (
                        <div className="removeButton solidQuilityDestructiveButton">Remove Authorization</div>
                    ) : !status && button==='quility' ? (
                        <div className="quilitySignInButton solidQuilityActiveButton" onClick={()=>authorize(link)}>Sign In With Quility</div>
                    ) : (
                        <img src={button} alt="Google Calendar Logo" style={{height:'100%'}} onClick={()=>authorize(link)}/>
                    )
                    
                ) : (
                    null
                )}
            </span>
        </div>
    );
}

export default IntegrationBlock;
