import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';


function CallbackDetail({details,formatDate,openLead}) {
    const [open, setOpen] = useState(false);
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    } 
    return (
        <>
            <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
                <div className='quilityNestedBlockHeader'>
                    <div className="quilityParentBlockTitle" style={{width: '170px'}}>Call Backs</div>
                    <span style={{marginLeft:'20px'}}>
                        <span className="typeBlock">{details.length} Callbacks</span>
                    </span>
                    <span className='quilityBlockIcon'><FontAwesomeIcon className={`transition ${open ? 'rotate' : ''}`} icon={faChevronUp}/></span>
                </div>

                <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                    {details.map((detail, index) => (
                        <div className="detailItem" key={"callback"+index}>
                            <span className="detailTitle">{detail.schedLeadFirst} {detail.schedLeadLast}</span>
                            <span className="detailDate">{formatDate(detail.schedLead)}</span>
                            <span className="detailTimezone"></span>
                            <span className="ViewLead" onClick={(event) => { event.stopPropagation(); openLead(detail.schedTime); }}>View Lead</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default CallbackDetail;