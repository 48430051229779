import React, { useEffect, useState } from 'react';
import '../../../../css/calendar.css'; 
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveCalendar } from '../../../../features/agentSlice';


const Calendar = ({ setEvent, setIsOpen, defaultCalendar, newEventPackage, newAppt, calendarChange}) => {
    const dispatch=useDispatch();
    const agentInfo=useSelector((state) => state.agent.userData);
    const [cals, setCals] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [dateStart, setDateStart] = useState(false);
    const [dateEnd, setDateEnd] = useState(false);
    const [events, setEvents] = useState([]);
    const [activeCal, setActiveCal] = useState(agentInfo.defaultCalendar);
    //const [dateHolder, setDateHolder] = useState([]);
    let dateHolder=[];
    let date1='';
    let date2='';
    const generateDatesForWeek = (startDate) => {
        const dates = [];
        const today = new Date();
        dateHolder=[];
        const sunday = new Date(startDate.getTime());
        sunday.setDate((sunday.getDate() - sunday.getDay()) + 1); // Move to the previous Sunday
        for (let i = 0; i < 7; i++) {
            const currentDate = new Date(sunday.getTime());
            currentDate.setDate(sunday.getDate() + i);
            const formattedDate = currentDate.toLocaleDateString("en-US");
            dates.push({
                date: formattedDate,
                isToday: currentDate.toDateString() === today.toDateString()
            });
            dateHolder.push(formattedDate);
            if(i===0){
                date1=currentDate; 
            }
            if(i===6){
                date2=currentDate;
            }
        }
        
        //setDateHolder(dates)
        return dates;
    };
    const incrementDate = (date) => {
        const newStartDate = new Date(date); // Create a new Date object
        newStartDate.setDate(newStartDate.getDate() + 7); // Increment the date by 7 days

        return newStartDate;
        // You can perform further actions with the new date here
    };
    const decrementDate = (date) => {
        const newStartDate = new Date(date); // Create a new Date object
        newStartDate.setDate(newStartDate.getDate() - 7); // Increment the date by 7 days

        return newStartDate;
        // You can perform further actions with the new date here
    };

    const handleNextClick = () => {
        let thisDate1;
        let thisDate2;
        if(dateStart){
            thisDate1=incrementDate(dateStart);
            setDateStart(incrementDate(dateStart));
        }
        else{
            thisDate1=incrementDate(date1);
            setDateStart();
        }
        if(dateEnd){
            thisDate2=incrementDate(dateEnd);
            setDateEnd(incrementDate(dateEnd));
        }
        else{
            thisDate2=incrementDate(date2);
            setDateEnd(incrementDate(date2));
        }
        setStartDate(thisDate1);
        fetchEvents(thisDate1,thisDate2);
    };

    // Function to handle clicking the "Previous" button
    const handlePrevClick = () => { 
        let thisDate1;
        let thisDate2;
        if(dateStart){
            thisDate1=decrementDate(dateStart);
            setDateStart(decrementDate(dateStart));
        }
        else{
            thisDate1=decrementDate(date1);
            setDateStart();
        }
        if(dateEnd){
            thisDate2=decrementDate(dateEnd);
            setDateEnd(decrementDate(dateEnd));
        }
        else{
            thisDate2=decrementDate(date2);
            setDateEnd(decrementDate(date2));
        }
        setStartDate(thisDate1);
        fetchEvents(thisDate1,thisDate2);
    };

    const fetchEvents = async (start,end) => {
        
        try {
            const response = await fetchWithDefaults(`/getEvents?agent=${agentInfo.agentId}&calendar=${activeCal}&start=${start}&end=${end}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const jsonData = await response.json();
            setEvents(jsonData);
        } 
        catch (error) {
            console.error('Error fetching data:', error); 
        }
    }


    useEffect(() => {
        fetchEvents(date1,date2);
    }, [activeCal]);
    useEffect(() => {
        fetchEvents(date1,date2);
    }, [newAppt]);
    // Fetch calendar data and default calendar for the agent
    useEffect(() => {
        
        const fetchData = async () => {
            try {
                const response = await fetchWithDefaults(`/getCalendars?agent=${agentInfo.agentId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if(jsonData[0]===false){

                }
                else{
                    setCals(jsonData);
                    calendarChange(defaultCalendar);
                    if(!defaultCalendar){
                        calendarChange(jsonData[0].id);
                        setActiveCal(jsonData[0].id);
                        dispatch(setActiveCalendar(jsonData[0].id));
                    }
                }
            } 
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

  // Fetch default calendar for the agent
   
    const fetchSpecificEvent = async (event) => {
        try {
            const response = await fetchWithDefaults(`/getEvent?agent=${agentInfo.agentId}&calendar=${activeCal}&event=${event}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setEvent(jsonData);
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const makeDefault = async (calendar) => {
        try {
            const response = await fetchWithDefaults(`/setDefaultCal?agent=${agentInfo.agentId}&calendar=${calendar}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const selectedDate=(date,time)=>{
        newEventPackage[0].setNewEvent(true);
        newEventPackage[0].setDate(date);
        newEventPackage[0].setTime(time);
    }
    function handleCalendarChange(event) {
        const selectedCalendarId = event.target.value;
        setActiveCal(selectedCalendarId)
        dispatch(setActiveCalendar(selectedCalendarId));
    }
  // Render calendar options
    const renderCalendarOptions = () => {
        return (
        <select id="popupCalendarList" onChange={handleCalendarChange}>
            {cals.map(cal => (
                !cal.id.includes('holiday@group') && !cal.id.includes('addressbook#contacts@group.v.calendar.google.com') && !cal.id.includes('p#weather@group.v.calendar.google.com') && (
                    <option key={cal.id} value={cal.id} selected={cal.id === defaultCalendar} >{cal.summary}</option>
                ) 
            ))}
        </select>
        );
    };
    const renderedEvents = new Set();
  // Render time slots for each day
    const renderTimeSlots = () => {
        const daysOfWeek = [ 'Time', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return daysOfWeek.map((day, index) => (
            <div key={index} className="largeWrap" id={index === 0 ? 'date-1' : `dayDate${index}`}>
                {/* Render hours for the first column */}
                {index === 0 && [...Array(24).keys()].map(hour =>(
                    <div key={index+hour} className="hour">
                        <div className="halfHour top" >{`${hour <= 12 ? hour : hour - 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</div>
                        <div className="halfHour bottom">{`${hour <= 12 ? hour : hour - 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}</div>
                    </div>
                ))}
                {index !== 0 && [...Array(24).keys()].map(hour => (
                    <div key={hour+hour} className="hour" day={day}>
                       <div className="halfHourLine top" onClick={() => selectedDate(dateHolder[index-1], `${hour <= 12 ? hour : hour - 12}:${hour % 12 === 0 ? '00' : '30'} ${hour < 12 ? 'AM' : 'PM'}`)}></div>
                        <div className="halfHourLine bottom" onClick={() => selectedDate(dateHolder[index-1], `${hour <= 12 ? hour : hour - 12}:${hour % 12 === 0 ? '00' : '30'} ${hour < 12 ? 'AM' : 'PM'}`)}></div>
                        {/* Track rendered events to prevent duplication */}
                        
                        {/* Render events for this hour */}
                        {events.length > 0 && events.map(event => {
                            const eventStartDate = new Date(event.Start);
                            const eventEndDate = new Date(event.End);
                            const eventDay = eventStartDate.getDay();
                            const eventHour = eventStartDate.getHours();
                            const eventMinute = eventStartDate.getMinutes();
                            const eventDuration = event.duration || 30; // Default duration is 60 minutes
                            // Check if the event's start time is within the current hour and it hasn't been rendered yet
                            if (eventDay === index && eventHour === hour && eventMinute === 0 && !renderedEvents.has(event.Id)) {

                                renderedEvents.add(event.Id); // Add event to rendered set
                                return (
                                    <div key={event.Id} className="event" style={{ top: '0', height: `${(eventDuration / 60) * 45}px` }} onClick={()=>fetchSpecificEvent(event.Id)}>
                                        {event.Summary}
                                    </div>
                                );
                            }
                            // Check if the event spans multiple hours and overlaps with the current hour
                            if (eventDay === index && hour >= eventHour && hour < eventEndDate.getHours() && !renderedEvents.has(event.Id)) {
                                renderedEvents.add(event.Id); // Add event to rendered set
                                return (
                                    <div key={event.Id} className="event" style={{ height: `${(eventDuration / 60) * 45}px` }} onClick={()=>fetchSpecificEvent(event.Id)}>
                                        {event.Summary}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                ))}
            </div>
        ));
    };
    const updateTimeline= ()=>{

    }
    return (
        <div className="calendar">
            <div className="wrapper">
                <div className="closeCal" onClick={()=>setIsOpen(false)}>Close Calendar</div>
                <div className="button-container">
                    <button className="next-prev-button" onClick={handlePrevClick}>Previous</button>
                    <div className="calendars">
                        {renderCalendarOptions()}
                        <div className="makeDefault" onClick={()=>makeDefault(activeCal)}>Make Default</div>
                    </div>
                    <button className="next-prev-button" onClick={handleNextClick}>Next</button>
                </div>
                <div className="calendar-header">
                    <div className="date">Time</div>
                    {generateDatesForWeek(startDate).map(({ date, isToday }, index) => (
                        <div className={`date ${isToday ? "today" : ""}`} key={index} onClick={() => updateTimeline(index)}>
                            {date}
                        </div>
                    ))} 
                
                </div>
                <div className="calendar-header">
                    <div className="day">Time</div>
                    <div className="day">Monday</div>
                    <div className="day">Tuesday</div>
                    <div className="day">Wednesday</div>
                    <div className="day">Thursday</div>
                    <div className="day">Friday</div>
                    <div className="day">Saturday</div>
                    <div className="day">Sunday</div>
                </div>
            </div>
            <div className="timeline">
                {renderTimeSlots()}
            </div>
           
        </div>
    );
};

export default Calendar;
