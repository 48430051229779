import { fetchWithDefaults } from '../config/fetchDefault';

const gatherCallInfo= async (agent, type, setCallInfo, setCallsLoading)=>{
    try {
        setCallsLoading(true);
        const response = await fetchWithDefaults(`/callLog?agent=${agent}&type=${type}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setCallsLoading(false);
        setCallInfo(jsonData.data);
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
export {gatherCallInfo};
