import { fetchWithDefaults } from '../config/fetchDefault';

async function gatherCalendars (agentId){
    try {
        const response = await fetchWithDefaults(`/getCalendars?agent=${agentId}`);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return(jsonData);
    } 
    catch (error) {
        console.error('Error fetching data:', error); 
    }
}
function convertSmallStamp(timeString) {
    // Split the time string into hours, minutes, and AM/PM
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':').map(num => parseInt(num));
    // Convert hours to 24-hour format
    let hours24 = hours;
    if ((period === 'PM' || period === 'pm') && hours !== 12) {
        hours24 += 12;
    } else if (period === 'AM' && hours === 12) {
        hours24 = 0;
    }

    // Pad hours and minutes with leading zeros if necessary
    const formattedHours = String(hours24).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return the time in 24-hour format
    return `${formattedHours}:${formattedMinutes}:00`;
}  
const convertDateFormat = (dateString) => {
    const parts = dateString.split('/');
    return `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
};

export {gatherCalendars, convertSmallStamp, convertDateFormat};