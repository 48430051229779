import React, {useState, useEffect} from 'react';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import TermsOfService from '../../terms/tos';

function Payment({formData, imoInfo, setLoading, terms, setTerms, usernameCheck}) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        setLoading(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
        const result = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
              // Return URL where the customer should be redirected after the SetupIntent is confirmed.
              //return_url: 'https://example.com',
            },
          })
          .then(async function(results) {
            if (results.error) {
                setLoading(false);
                console.log(results);
              // Inform the customer that there was an error.
            }
            else{
                if(results.setupIntent.status==='succeeded'){
                    try {
                        const requestOptions = {
                            method: 'POST',
                            body: JSON.stringify({ 
                                form: formData,
                                method:results.setupIntent.payment_method,
                                imoInfo:imoInfo
                            }) 
                        };
                    
                        const response = await fetchWithDefaults('/createCustomer', requestOptions);
                        if (!response.ok) {
                        throw new Error("Network response was not ok");
                        }
                        const jsonData = await response.json();
                        if(jsonData===true){
                            window.location.href = 'https://v2.ninjadialer.com';
                        }
                    } catch (error) {
                        setLoading(false);
                        console.error("Error fetching data:", error);
                    }
                }
            }
        });

      };
    
    return (
        <span>
            <div className="quilityDataLine">
                <PaymentElement />
            </div>
            <div className="quilityDataLine">
                <TermsOfService terms={terms} setTerms={setTerms}/>
            </div>
            {formData['Username'] !== '' && usernameCheck===false && terms && formData['Password'] !== '' && formData['Confirm_Password'] === formData['Password'] && (
                <div className="quilityDataLine">
                    <div className="submitSubscription solidQuilityActiveButton" onClick={(event)=>handleSubmit(event)}>Submit and Subscribe</div>
                </div>
            )}    
        </span>     
    );
}

export default Payment;