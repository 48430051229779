import React from 'react';


function MarketSection({ title, leadPreview, workflowPreview,setupNavTiles}) {    
    
    return (
        <div className={`quilityParentBlockWrapper twoX selectable`}  onClick={()=> setupNavTiles(title,'faIdCard')}>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">{title}</div>
            </div>
            
            <div className="quilityParentBlockContent">
                {title==='Leads' ? (
                    <div className={`quilityNestedBlockWrapper `} key={'index'} style={{marginTop: '25px'}}>
                        <div className="quilityParentBlockHeader">
                            <div className="quilityParentBlockTitle">Shop For Leads</div>
                        </div>
                        <div className="quilityNestedBlockContent">
                            <div className="quilityDataLine">
                                <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '200px'}}>Shop By Vendor</span>
                            </div>
                            <div className="quilityDataLine">
                                <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '200px'}}>Shop By Lead Type</span>
                            </div>
                            {leadPreview.map((item, index) =>(
                                <div className="quilityDataLine" key={`leadPreview${index}`}>
                                    <span className="quilityDataLineTitle extraIndent" style={{display: 'inline-block',width: '200px'}}>{item.type}</span>
                                </div>
                            ))}
                            <div className="quilityDataLine">
                                <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '200px'}}>Browse All Leads</span>
                            </div>
                            </div>
                            
                    </div>
                    
                ) : (
                    <>
                    {workflowPreview ?(
                       
                        <div className={`quilityNestedBlockWrapper`} key={'index'} style={{marginTop: '25px'}}>
                            <div className="quilityParentBlockHeader">
                                <div className="quilityParentBlockTitle">Shop For Leads</div>
                            </div>
                            <div className="quilityNestedBlockContent">
                                {workflowPreview.map((item, index) =>(
                                    <div className="quilityDataLine" key={`workflowPreview${index}`}>
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '200px'}}>{item.type}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        null
                    )}
                    </>
                )}
                
                
            </div>
            <div className="clearFooter">
                
            </div>
        </div>
    );
}

export default MarketSection;



/*<>
                    {leadPreview ?(
                        leadPreview.map((item, index) =>(
                            <div className="previewBlock">
                                <div className="previewBlockHeader">
                                    <div className="previewBlockTitle">{item.type}</div>
                                </div>
                            </div>
                        ))
                    ) : (
                        null
                    )}
                    </>
                    
                    
                    
                    <div className="previewBlock">
                                <div className="previewBlockHeader">
                                    <div className="previewBlockTitle">{item.type}</div>
                                </div>
                            </div>*/
