import React, { useState, useRef } from "react";
import AudioPlayer from 'react-audio-player';
import '../../css/recording.css';


function AudioElement({audio, type, index}) {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioPlayerRef = useRef(null);
    const onAudioEnded = () => setIsPlaying(false);
    const [open, setOpen] = useState(false);
    
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    } 
   
   
    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };
    
    return (
        <div className="quilityNestedBlockWrapper">
            <span className="quilityNestedBlockHeader" onClick={(event) => { event.stopPropagation();openUp();}}>
                <div className="quilityParentBlockTitle">{audio.name}</div>

                <div className="quilityCloseWindow oulineQuilityDestructiveButton">x</div>
            </span>
            <div className={`quilityNestedBlockContent`}>
                    <div className='quilityDataLine'>
                        <div className="recordingHolder">
                            <AudioPlayer
                                ref={audioPlayerRef}
                                src={"https://files.thehivelms.com/"+type+"/"+audio.id+"."+audio.ext}
                                autoPlay={false}
                                controls
                                onEnded={onAudioEnded}
                                className="audioPlayerStyles"
                            />
                        </div>
                    </div>
                    <div className='quilityDataLine'>
                        <div className="quilityDataLineTitle" style={{width: '60px'}}>Name: </div>
                        <span className="rename">{audio.name}</span>
                    </div>
                    {type=='voicemails' ? (
                        <div className='quilityDataLine'>
                            <div className="accentButton solidQuilityActiveButton">Set As Voicemail</div>
                        </div>
                    ):(
                        null
                    )}

            </div>
        </div>
    );
}

export default AudioElement;

