import React, { useState, useEffect } from "react";
import '../../css/kanban.css';
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faPhoneArrowUpRight } from "@fortawesome/pro-regular-svg-icons";


const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    borrower_first: `item ${k + offset}`,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "#0b4364",
    padding: grid,
    width: 300
});

function Overview({ }) {
    const [groups, setGroups] = useState([]);

    const onDragEnd = result => {

        const { source, destination } = result;
        console.log(result);
        // dropped outside the list
        if (!destination) return;

        const sourceIndex = +source.droppableId.split("-")[0];
        const destinationIndex = +destination.droppableId.split("-")[0];

        const sourceStatus=source.droppableId.split("-")[1];
        const destinationStatus=destination.droppableId.split("-")[1];

        if (sourceIndex === destinationIndex) {
            const items = [...groups[sourceIndex].values];
            const [removed] = items.splice(source.index, 1);
            items.splice(destination.index, 0, removed);

            const newGroups = [...groups];
            newGroups[sourceIndex] = { ...groups[sourceIndex], values: items };
            setGroups(newGroups);
        } else {
            const sourceItems = [...groups[sourceIndex].values];
            const destinationItems = [...groups[destinationIndex].values];
            const [removed] = sourceItems.splice(source.index, 1);
            destinationItems.splice(destination.index, 0, removed);

            const newGroups = [...groups];
            newGroups[sourceIndex] = { ...groups[sourceIndex], values: sourceItems };
            newGroups[destinationIndex] = { ...groups[destinationIndex], values: destinationItems };

            setGroups(newGroups);
        }
    };

    useEffect(() => {
        setGroups([
            {
                title: 'No Contact',
                color:'#baa26c',
                statusId:'si_asdf31',
                values: [
                    { "id": "item-1-1", "borrower_first": "Tim", 'borrower_last':"Rawlins", "tags":['Morning Dial'] ,"lead_type":"Lighthouse Mortgage Protection","workflowName":"MP Symmetry WF"},
                    { "id": "item-1-2", "borrower_first": "Amanda", "borrower_last":"Luckie", "tags":['Morning Dial'],"lead_type":"Lighthouse Mortgage Protection","workflowName":"MP Symmetry WF"}
                ]
            },
            {
                title: 'Call Back',
                color:'#98bcba',
                statusId:'si_asdf3',
                values: [
                    { "id": "item-3-1", "borrower_first": "Joshua", "borrower_last":"Welch", "tags":['Works Afternoon'],"lead_type":"Lighthouse Mortgage Protection","workflowName":"MP Symmetry WF"},
                    { "id": "item-3-2", "borrower_first": "Julie", "borrower_last":"Kuzmenko", "tags":['Weekend'],"lead_type":"Lighthouse Mortgage Protection","workflowName":"MP Symmetry WF"}
                ]
            },
            {
                title: 'Appointment',
                color:'#fbb14b',
                statusId:'si_asdf2',
                values: [
                    { "id": "item-4-1", "borrower_first": "Karly", "borrower_last":"Kitterlin", "tags":['Zoom Appointment'],"lead_type":"Lighthouse Mortgage Protection","workflowName":"MP Symmetry WF"},
                    { "id": "item-4-2", "borrower_first": "Alex", "borrower_last":"Saldova", "tags":['Phone Appointment'],"lead_type":"Lighthouse Mortgage Protection","workflowName":"MP Symmetry WF"}
                ]
            },
            {
                title: 'Application',
                color:'#8cdba9',
                statusId:'si_asdf',
                values: [
                    { "id": "item-5-1", "borrower_first": "Martin", "borrower_last":"Maxwell", "tags":['MOO','Pending'],"lead_type":"Lighthouse Mortgage Protection","workflowName":"MP Symmetry WF"},
                    { "id": "item-5-2", "borrower_first": "Justin", "borrower_last":"Miller", "tags":['AmAm','Issue Paid'],"lead_type":"Lighthouse Mortgage Protection","workflowName":"MP Symmetry WF"}
                ]
            }
        ]);
    }, []);

    return (
        <div>
        <div className="innerWrapper" style={{ paddingTop: '100px', marginLeft: '60px' }}>
           
            <div className="kanbanBorderWrapper">
                <DragDropContext onDragEnd={onDragEnd}>
                    {groups.map((group, index) => (
                        <Droppable key={index} droppableId={`${index}-${group.statusId}`} className="things">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    className="kanColumn"
                                   // style={{ background: snapshot.isDraggingOver ? "lightblue" : "#0b4364"}}
                                    {...provided.droppableProps}
                                >
                                    <div className="kanBandListTitle" style={{borderBottom: `solid 4px ${group.color}`}}>{group.title}</div>
                                    {group.values.map((item, itemIndex) => (
                                        <Draggable key={item.id} draggableId={item.id} leadId={item.id} index={itemIndex}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`dragCard color${group.color.replace("#","")}`}
                                                >
                                                    <div>
                                                        <div className="kanName">{item.borrower_first} {item.borrower_last}</div>
                                                        <div className="kanLeadType kanStandardItem">{item.lead_type}</div>
                                                        <div className="kanFlow kanStandardItem">{item.workflowName}</div>
                                                        <div className="kanTagsHolder">
                                                            {item.tags.map((tag, itemIndex) => (
                                                                <div className="kanTag" key={`kanTag${itemIndex}`}><span style={{color:'grey'}}>#</span>#{tag}</div>
                                                            ))}
                                                        </div>
                                                        <div className="bottomButtonHolder">
                                                            <div className="kanDialButton kanCardButton"><FontAwesomeIcon icon={faPhoneArrowUpRight}/></div>
                                                            <div className="kanTextButton kanCardButton"><FontAwesomeIcon icon={faMessage}/></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
        </div>
    </div>
    );
}

export default Overview;


/* <button type="button" onClick={() => setGroups([...groups, { title: '', values: [] }])}>
                Add new group
            </button>
            <button type="button" onClick={() => {
                const newGroups = [...groups];
                newGroups[0].values.push({ id: `item-${Date.now()}`, borrower_first: `item ${newGroups[0].values.length + 1}` });
                setGroups(newGroups);
            }}>
                Add new item
            </button>*/