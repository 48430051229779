import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowProgress, faChevronDown, faTriangle } from '@fortawesome/pro-regular-svg-icons';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';

function WorkflowBlock({ leadId, setFlowFlag}) {
    const workflows=useSelector((state) => state.workflows.workflows);
    const [isOpen, setIsOpen] = useState(false);
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const innerWrapperRef = useRef(null);

    const openUp = () => {
        setIsOpen(!isOpen);
    };
    const addToFlow= async (flow)=>{
        console.log(flow);
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    lead: leadId,
                    flow:flow,
                    trigger:'manual'
                }) 
            };
          
            const response = await fetchWithDefaults(`/addLeadToWorkflow`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status===true){
                setFlowFlag(jsonData.flow)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        if (innerWrapperRef.current) {
            setInnerWrapperHeight(innerWrapperRef.current.clientHeight);
        }
    }, [isOpen]);
    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`}>
            <div className="quilityNestedBlockHeader" onClick={()=>openUp()}>
                <div class="quilityParentBlockTitle">Add To Workflow </div>
                <span className='quilityBlockIcon'>
                    <FontAwesomeIcon className={`transition ${isOpen ? 'rotate' : ''}`} icon={faChevronDown}/>
                </span>
            </div>
            <div className="quilityNestedBlockContent" style={{ height: isOpen ? innerWrapperHeight + 25 : '0px' }}>
                <div className="innerWrapper" ref={innerWrapperRef}>
                    {workflows.map((workflow, index) => (
                        <div className="queueBlockOption" key={`workflows${index}`} onClick={()=>addToFlow(workflow.id)}><span className="blockOptionSelect"><FontAwesomeIcon icon={faArrowProgress} /><span className="toolTip">Add To Flow</span></span><span className="blockOptionTitle">{workflow.name}</span></div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WorkflowBlock;

/**/