import React, { useState, useRef } from "react";
import '../../css/elements.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronUp } from "@fortawesome/pro-regular-svg-icons";



function QuilityNestedEditBlock({ title, values, handleRemove, itemId}) {
    const [isOpen, setIsOpen] = useState(false);
    const objectArray = Object.entries(values);
    const innerWrapperRef = useRef(null);
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const handleOptionClick = (option) => {
      //setSelectedOption(option.name || option.states);
      setIsOpen(!isOpen);
      //addToHolder(option,search);
    };
    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    return (
        <span className="quilityNestedBlockWrapper" style={{ height: 'fit-content',margin: '20px 0px' }}>
            <div className="deleteItem oulineQuilityDestructiveButton" onClick={()=>handleRemove(itemId)}>X</div>
            <div className="quilityParentBlockHeader" onClick={()=>handleOptionClick()}>
                <div className="quilityParentBlockTitle">{title}</div>
                <span className="quilityBlockIcon">
                </span>
            </div>
            <div className="quilityNestedBlockContent"  ref={innerWrapperRef}>
                {objectArray.map(([item, value]) => (
                    <div className="quilityDataLine"><span className="quilityDataLineTitle">{item}</span> <span className="quilityDataLineValue">{value}</span></div>
                ))}
            </div>
        </span>
    );
}

export default QuilityNestedEditBlock;

