import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPhoneArrowUpRight} from '@fortawesome/pro-regular-svg-icons';
import ContactField from './field';
import { useSelector } from 'react-redux';

function ContactInfo({leadData, handleLeadData, makeDial}) {
    const numberList=useSelector((state) => state.agent.phoneNumbers);
    const [isOpen, setIsOpen] = useState(true);
    const numbers=['borrower_cell', 'borrower_home', 'borrower_work']
    const names=['borrower_first', 'borrower_last']
    const Conumbers=['co_borrower_cell', 'co_borrower_home', 'co_borrower_work']
    const Conames=[ 'co_borrower_first', 'co_borrower_last']
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const [numberLists, setNumberList] = useState([]);
    
    const innerWrapperRef = useRef(290);
    const openUp = () => {
        setIsOpen(!isOpen);
    };
    const startDial=(toNum,callee,caller)=>{
        const foundNumber = numberLists.find(item => {
            return item.number === caller;
        });
        if(foundNumber){
            makeDial(toNum,callee,caller)
        }
        else{
            caller=numberLists[0].number;
            makeDial(toNum,callee,caller)
        }
    }
      
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (innerWrapperRef.current) {
                setInnerWrapperHeight(innerWrapperRef.current.clientHeight);   
            }
        }, 250);
        return () => clearTimeout(timeoutId);
        
    }, [isOpen]);
    useEffect(() => {
        setNumberList(numberList);
    }, []);
    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`} >
            <div className="quilityNestedBlockHeader " onClick={()=>openUp()}>
                <div className="quilityParentBlockTitle">Contact Information </div>
                <span className='quilityBlockIcon'>
                    <FontAwesomeIcon className={`transition ${isOpen ? 'rotate' : ''}`} icon={faChevronDown}/>
                </span>
            </div>
            <div className="quilityNestedBlockContent" style={{ height: isOpen ? innerWrapperHeight + 25 : '0px' }}>
                <div className="innerWrapper" ref={innerWrapperRef}>
                    <div className="leadCardBlock doubleLine">
                        {names.map((field, index) => (
                            <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData} key={`fieldInfo${index}`}/>
                        ))}
                        {numbers.map((field, index) => (
                            <span key={`numbersInfo${index}`}>
                                {leadData[0][field] !== null ? (
                                    <>
                                        <span className="callIcon" onClick={(event)=>{startDial(leadData[0][field],leadData[0].borrower_first+' '+leadData[0].borrower_last,leadData[0].call_from);event.stopPropagation();}}>
                                            <FontAwesomeIcon icon={faPhoneArrowUpRight}/>
                                            <div className="numberOptions">
                                                {numberLists.map((number, index) => (
                                                    <span key={`availableNumbers${index}`}>
                                                    {leadData[0][field]===leadData[0].callFrom ? (
                                                        <div className="numberChoice" onClick={(event)=>{startDial(leadData[0][field],leadData[0].borrower_first+' '+leadData[0].borrower_last,number.number);event.stopPropagation();}}>{number.friendlyName}</div>
                                                    ) : (
                                                        <div className="numberChoice" onClick={(event)=>{startDial(leadData[0][field],leadData[0].borrower_first+' '+leadData[0].borrower_last,number.number);event.stopPropagation();}}>{number.friendlyName}</div>
                                                    )}
                                                    </span>
                                                
                                                ))}
                                            
                                            </div>
                                        </span>
                                        <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData}/></>
                                ) : (
                                    <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData}/>
                                )}
                            </span>
                        ))}
                        <ContactField handleLeadData={handleLeadData} field='email' leadData={leadData} index='1'/>
                    </div>
                    <div className="leadCardBlock doubleLine">
                        {Conames.map((field, index) => (
                            <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData} key={`coNameFieldInfo${index}`}/>
                        ))}
                        {Conumbers.map((field, index) => (
                            <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData} key={`coNumberFieldInfo${index}`}/>
                        ))}
                        <ContactField handleLeadData={handleLeadData} field='co_borrower_email' leadData={leadData} index='1'/>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default ContactInfo;