import React from 'react';
import '../../css/market.css';
import LeadsMarket from './markets/leadsMarket';
import SpecType from './markets/searchBy/specType';
import MarketBlock from './markets/marketBlock';
import PurchasePage from './purchasePage';


function CurrentPageHandler({ page, setupNavTiles, setCurrentPage, setPathData, pathData}) { 
    let filteredData;
    if(page==='LevelUp Leads'){
        filteredData = pathData[0].types[0].filter(item => item.vendorName === page);
    }
    if(page==='Razor Ridge'){
        filteredData = pathData[0].types[0].filter(item => item.vendorName === page);
    }
    return (
        <>
            {page=='Leads' ? (
                <LeadsMarket setPathData={setPathData} setupNavTiles={setupNavTiles}/>
            ) : page === 'Market' ? (
                null,
                setCurrentPage(false)
            ) : page==='LevelUp Leads' || page==='Razor Ridge' ? (
                <MarketBlock data={[filteredData]} title={page} setupNavTiles={setupNavTiles}/>
            ) : page==='purchase' ? (
                <PurchasePage data={pathData}/>
            ) : (
                <SpecType data={pathData} title={page} setupNavTiles={setupNavTiles}/>
            )}
        </>
    );
}

export default CurrentPageHandler;
