import React, {useState, useRef, useEffect} from 'react';
import '../../css/conversation.css';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile, faImage, faClock, faPaperPlaneTop, faBracketsCurly, faFileInvoice } from '@fortawesome/pro-regular-svg-icons';
import TextInputBar from '../callQueue/elements/textBar';
import { fetchPdf } from '../../utilities/messagingFunctions';
import { useSelector } from 'react-redux';

function ActualConversation({setLoading,lead,conversation,travelPathway,handleSendText,convoId,handleInputChange,inputValue}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const texts=useSelector((state) => state.texts.texts);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [textsTemplates, setTextsTemplates] = useState(false);
    const [attachedImage, setAttachedImage] = useState(false);
    const [variables, setVariables] = useState(false);
    const fileInputRef = useRef(null);
    const sendBar = useRef();
    const [cursorPosition, setCursorPosition] = useState(0);
    const [textBoxHeight, setTextBoxHeight] = useState(0);
    const keysWithValue = [];
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTexts, setFilteredTexts] = useState([]);

    const openvars=()=>{
        setVariables(!variables);
    }
    const keysandvalues=(leadInfo)=>{
        for (const key in leadInfo) {
            if (leadInfo[key] !== null && leadInfo[key] !== undefined) {

                keysWithValue.push({key:key,value:leadInfo[key]});
            }
        }
    }
    const handleRemoveImage=()=>{
        setAttachedImage(false);
    }
    const handleImageClick = () => {
        fileInputRef.current.click(); 
    };
    function formatUnixTimestamp(unixTimestamp) {
        const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
        const options = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate;
    }
    const handleHeight = (height) => {
        const newheight=sendBar.current.clientHeight;
        setTextBoxHeight(newheight);       
    };
    const handleEmojiSelect = (emoji) => {
        handleInputChange(inputValue+emoji.native);
    };
    const handleEmojiPicker=()=>{
        setShowEmojiPicker(!showEmojiPicker);
    }
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
     
        const formData = new FormData();

        formData.append('file', file);

        try {
            const response = await fetch(`https://v2.ninjadialer.com:3333/sendImage`, {
                method: 'POST',
                body: formData,
            });
            const jsonData = await response.json();
            setAttachedImage(`https://files.thehivelms.com/mmsImages/${jsonData[0].file}`)
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    const sendSuggestion=async(template)=>{
        let modifiedContent = Object.entries(lead[0]).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '';
            return content.replace(new RegExp(`{${key.replace('borrower','lead')}}`, 'g'), `${replacement}`);
        }, template);
        if(modifiedContent.includes('{agent_first}')){
            modifiedContent = modifiedContent.replace(/{agent_first}/g, agentInfo.firstName);
        }
        if(modifiedContent.includes('{agent_last}')){
            modifiedContent = modifiedContent.replace(/{agent_last}/g, agentInfo.lastName);
        }
        travelPathway(lead[0].lead_id, lead[0].pathway);
        handleChange(modifiedContent);

        
        lead[0].pathway=null;
    }
    
    const handleClick = (value,key) => {
        if(key==='pdf'){
            fetchPdf(value, setAttachedImage, setVariables, setLoading);
        }
        else{
            const updatedValue = inputValue.slice(0, cursorPosition) + value + inputValue.slice(cursorPosition);
            handleInputChange(updatedValue);
            setCursorPosition(cursorPosition + value.length);
            setVariables(!variables);
        }
    };

    // Function to handle input change
    const handleChange = (value) => {
        handleInputChange(value);
    };
    const handleCursorPosition=(pos)=>{
        setCursorPosition(pos);
    }
    keysandvalues(lead[0]);
    const resetSize=()=>{
        setTextBoxHeight(150)
    }
    const openTemplates=()=>{
        setTextsTemplates(!textsTemplates);
    }
    const addTemplate = (template, images)=>{

        if(images){
            if(!images.includes('http')){
                const newImageLink='https://files.thehivelms.com/'+images;
                setAttachedImage(newImageLink);
            }
            else{
                setAttachedImage(images);
            }
        }
        //this normalized from borrower ( our db) to lead. for instance DB is borrower_first, where variables and everything display lead_first. This is due to this originally being only for mortgage protection leads
        let modifiedContent = Object.entries(lead[0]).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '';
            return content.replace(new RegExp(`{${key.replace('borrower','lead')}}`, 'g'), `${replacement}`);
        }, template);
        //if its an agent variable, replace the agent data
        if(modifiedContent.includes('{agent_first}')){
            modifiedContent = modifiedContent.replace(/{agent_first}/g, agentInfo.firstName);
        }
        if(modifiedContent.includes('{agent_last}')){
            modifiedContent = modifiedContent.replace(/{agent_last}/g, agentInfo.lastName);
        }
        if(modifiedContent.includes('{lead_pdf}')){
            modifiedContent = modifiedContent.replace(/{lead_pdf}/g, '');
            fetchPdf(lead[0].pdf, setAttachedImage, setVariables, setLoading);
        }

        modifiedContent = modifiedContent.replace(/\\n/g, '<br>');
        handleInputChange(inputValue+modifiedContent);
        openTemplates();
        if(images){
            handleHeight(75);
        }
        else{
            handleHeight(25);
        }
    }
    const filterTexts = (searchTerm) => {
        const filtered = texts.filter(text => text.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredTexts(filtered);
    };
    useEffect(() => {
        setFilteredTexts(texts);
    }, []);
    useEffect(() => {
        if(lead){
            setTextBoxHeight(153); 
        }
        else{
            setTextBoxHeight(103); 
        }
    }, [lead]);
       
    
    return (
        <div className="outerConvo">
            <div className="convoInnerWrapper" style={{height: lead.length > 0 && lead[0].pathway ? `calc(100% - ${textBoxHeight}px)`:`calc(100% - ${textBoxHeight}px)`}}>
                {conversation && conversation.map((message, index) => (
                    message.pending ? (

                            <span key={`conversationMap${index}`}>
                                <div className={`time message${message.sent_from}`}></div>
                                <div className={`messageWrapper message${message.sent_from}`}>
                                <div className={`convoBubble background${message.sent_from} ${message.pending ? 'pending': ''}`}>
                                        {message.image && message.image != 0 && (
                                            <div className="sentImageWrapper">
                                                <img src={message.image} alt="Preview" className="sendingImage"/>
                                            </div>
                                        )}
                                        {message.message}
                                    </div>
                                    <FontAwesomeIcon icon={faClock}/>
                                </div>  
                            </span>

                    ) : (
                        <span key={`conversationMap${index}`}>
                            <div className={`time message${message.sent_from} ${message.pending ? 'pending': ''}`}>{formatUnixTimestamp(message.time)}</div>
                            <div className={`messageWrapper message${message.sent_from}`}>
                                <div className={`convoBubble background${message.sent_from}`}>
                                    {message.image && message.image != 0 && (
                                        <div className="sentImageWrapper">
                                            <img src={message.image} alt="Preview" className="sendingImage"/>
                                        </div>
                                    )}
                                {message.message}
                                </div>
                            </div>  
                        </span>
                    )
                    
                ))}
                
            </div>
            
            <div className="sendWrapper" ref={sendBar}>
                {lead.length > 0 && lead[0].pathway && (
                    <div className="pathSuggestion">
                        <div className="suggestionHolder">
                            <div className="suggestion" onClick={()=>sendSuggestion(lead[0].convoFlowContent)}>{lead[0].convoFlowName}</div>
                        </div>
                    </div>
                )}
                <div className="sendingImageWrapper">
                    {attachedImage && (
                        <div className="innerImageWrapper">
                            <div className="removeImage" onClick={()=>handleRemoveImage()}>x</div>
                            <img src={attachedImage} alt="Preview" className="sendingImage"/>
                        </div>
                    )}
                </div>
                <div className="sendBar">
                    <div className="inputBarWrapper">
                        
                        <TextInputBar sendBar={sendBar} from='bigConvo' handleCursorPosition={handleCursorPosition} handleHeight={handleHeight} inputValue={inputValue} handleChange={handleChange} />
                    </div>
                    <div className="sendButton"><FontAwesomeIcon icon={faPaperPlaneTop} onClick={() => { handleSendText(inputValue, convoId, attachedImage); resetSize(); setAttachedImage(false);}}/></div>
                </div>
                {}
                <div className="sendBarOptions" style={{paddingBottom: '6px'}}>
                    <div className="optionButton" onClick={() => handleEmojiPicker()} ><FontAwesomeIcon icon={faFaceSmile} /></div>
                    <div className="optionButton " onClick={handleImageClick}><FontAwesomeIcon icon={faImage} />
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileUpload}
                        />
                    </div>
                    <div className="optionButton"><FontAwesomeIcon icon={faClock} /></div>
                    <div className="optionButton" onClick={()=>openvars()}><FontAwesomeIcon icon={faBracketsCurly} /></div>
                    {variables && (
                        <div className="variableWindow">
                            <div className="innerWrapper">
                                {keysWithValue.map((variable, index) => (
                                    <div class="leadInfoLine" key={`variable${index}`}><span class="dataFieldTitle">{variable.key.replace('_',' ')}</span><span class="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{cursor:'pointer'}}>Click To Use</span></div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="optionButton" onClick={()=>openTemplates()}><FontAwesomeIcon icon={faFileInvoice} /></div>
                    {textsTemplates && (
                        <div className="variableWindow">
                            <input
                                type="text"
                                placeholder="Search templates..."
                                className="templateSearch"
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    filterTexts(e.target.value);
                                }}
                            />
                            <div className="innerWrapper">
                                {filteredTexts.map((text, index) => (
                                    <div className="leadInfoLine" key={`texttemplate${index}`}>
                                        <span className="dataFieldTitle">{text.name}</span>
                                        <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showEmojiPicker && (
                <div className="emojiWrapper" style={{ position: 'absolute',bottom: '100px'
                }} >
                <Picker
                    data={data} onEmojiSelect={handleEmojiSelect}
                />
                </div>
            )}
        </div>
    );
}

export default ActualConversation;

/*

handleHeight={handleHeight} inputValue={inputValue} handleChange={handleChange}

*/
