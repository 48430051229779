import React from "react";
import '../../css/elements.css';


function RemoveableOption({ name, index, remove, item, search }) {
    return (
        <div className="removeableOption">
            <span className="removeableRemove" onClick={()=>remove(index,item,search)}>X</span>
            <span className="removeableTitle">{name}</span>
        </div>
    );
}

export default RemoveableOption;

 