

import React from 'react';


function MarketInfo({title, message, handleClose,setupNavTiles}) {
    
    return (
        <div className="warningWrapper">
            
            <div className="warningBody" style={{maxWidth: '1000px',height: '450px'}}>
                <div className="closeWindow" onClick={()=>handleClose()}>X</div>
                <div className="warningHeader">
                    {title}
                </div>
                <div className="warningContent">
                    {message}
                </div>
                <ul className="listStyle">
                    <li>Order a your leads and schedule an orientation!</li>
                    <li>Watch two quick videos on how LevelUp works.</li>
                    <li>After you watch, there are a couple of informational links you can view</li>
                    <li>Recieve your order and make some money!</li>
                </ul>
                <div className="warningButtonHolder">
                    <div className="warningButtonWrapper">
                        <div className="standardButton" onClick={()=>setupNavTiles('purchase')}>Get Started!</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MarketInfo;