import React, { useState } from "react";
import '../../css/workflow.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import Warning from '../warning';

function WorkflowInfo({type, title, workflowId, hopperId}) {
    const [displayInfo, setDisplayInfo] = useState(false);
    const [warning, setWarning] = useState(false);
    const [stopped, setStopped] = useState(false);
    const workflowInfo= async ()=>{
        if(type==='workflow'){
            try {      
                const response = await fetchWithDefaults(`/workflow/detail?workflow_id=${workflowId}&hopper_id=${hopperId}`);
                
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                if(jsonData){
                    setDisplayInfo(jsonData);
                }
            } 
            catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        else{
            try {      
                const response = await fetchWithDefaults(`/conversation/detail?conversation=${workflowId}`);
                
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                if(jsonData){
                    setDisplayInfo(jsonData);
                }
            } 
            catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }
    const closeInfo=()=>{
        setDisplayInfo(false);
    }
    const cancel = () => {
        setWarning(false);
    };
    const confirm = async () => {
        setWarning(false);
        try {      
            const response = await fetchWithDefaults(`/workflow/stop?hopper_id=${hopperId}`);
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            if(jsonData){
                setDisplayInfo(false);
                setStopped(true);
            }
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const stopFlow=()=>{
        setWarning(true);
    }
    return (
        <>
            {warning && 
                <Warning title={"Stop Workflow?"} cancel={cancel} confirm={confirm} message={`Are you sure you want to stop this ${type}?`} buttons={[{name:'Don\'t Stop',action:'cancel'},{name:"Confirm Stop", action:'confirm'}]} />
            }
            {displayInfo && (

                <div className="workflowInfoBox">
                    <div className="quilityCloseWindow" onClick={()=>closeInfo()}>X</div>
                    <div className="title">{title}</div>
                    
                        {type==='workflow' ? (
                            <div className="infoWrap" style={{marginTop:'20px'}}>
                                <div class="leadInfoLine">
                                    <span class="dataFieldTitle">Length of Flow: </span>
                                    <span class="leadData" style={{textDecoration:'unset', verticalAlign:'top'}}>{displayInfo[0].length.amount}</span>
                                </div>
                                <div class="leadInfoLine">
                                    <span class="dataFieldTitle">Messages Sent: </span>
                                    <span class="leadData" style={{textDecoration:'unset', verticalAlign:'top'}}>{displayInfo[0].sent.amount}</span>
                                </div>
                                <div class="leadInfoLine">
                                    <span class="dataFieldTitle">Messages Left: </span>
                                    <span class="leadData" style={{textDecoration:'unset', verticalAlign:'top'}}>{displayInfo[0].active.amount}</span>
                                </div>
                                <div class="leadInfoLine">
                                    <span class="dataFieldTitle">Messages Failed: </span>
                                    <span class="leadData" style={{textDecoration:'unset', verticalAlign:'top'}}>{displayInfo[0].expired.amount}</span>
                                </div>
                                <div className="solidQuilityDestructiveButton accentButton" style={{margin: 'auto',marginTop: '20px'}} onClick={()=>stopFlow()}>Stop Workflow</div>
                            </div>
                        ) : (
                            <div className="infoWrap" style={{marginTop:'20px'}}>
                                <div class="leadInfoLine">
                                    <span class="dataFieldTitle">Length of Guided Conversation: </span>
                                    <span class="leadData" style={{textDecoration:'unset', verticalAlign:'top'}}>{displayInfo[0].length.amount}</span>
                                </div>
                                <div class="leadInfoLine">
                                    <span class="dataFieldTitle">Templates Sent: </span>
                                    <span class="leadData" style={{textDecoration:'unset', verticalAlign:'top'}}>{displayInfo[0].sent.amount}</span>
                                </div>
                                <div class="leadInfoLine">
                                    <span class="dataFieldTitle">Templates Left: </span>
                                    <span class="leadData" style={{textDecoration:'unset', verticalAlign:'top'}}>{displayInfo[0].active.amount}</span>
                                </div>
                                <div className="solidQuilityDestructiveButton accentButton" style={{margin: 'auto',marginTop: '20px'}} onClick={()=>stopFlow()}>Stop Conversation</div>
                            </div>
                        )}
                </div>
            )}
            <div class={`workflowBubble ${stopped ? 'flowStopped' : ''}`} onClick={()=>{workflowInfo()}}>{title}</div>
        </>
    );
}

export default WorkflowInfo;


