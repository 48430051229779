

import React, {useState, useEffect} from 'react';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';


function PurchasePage({title, data}) {
    const [inputValue, setInputValue] = useState('');
    const [unitPrice, setUnitPrice] = useState(false);
    const [filteredStates, setFilteredStates] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedCard, setSelectedCard] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [cards, setCards] = useState(false);

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
        'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
        'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
        'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
        'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming'
    ];
    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        setFilteredStates(states.filter(state => state.toLowerCase().includes(value.toLowerCase())));
    };
    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };
    const selectState = (state) => {
        if (!selectedStates.includes(state)) {
            setSelectedStates([...selectedStates, state]);
            setInputValue('');
        }
    };
    const handleRemove = (stateToRemove) => {
        setSelectedStates(selectedStates.filter(state => state !== stateToRemove));
    };
    const fetchCards = async () => {
        try {
            const response = await fetchWithDefaults(`/paymentInfo?agent=1`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setCards(jsonData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
    }; 
    const submitOrderForm = async () => {

        console.log(data);
        /*const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                agent: zagentIdNumber,
                agentFirst: zagentFirst,
                agentLast: zagentLast,
                agentEmail:zagentEmail,
                card: zcard,
                vendor: zvendor,
                amount: zamount,
                order: zorder,
                description:zdescription,
                customer:zcustomer,
                states: zstates,
                leadType: zleadType,
                subType: zsubType,
                orderType: zorderType,
                leadTypeId: zleadTypeId,
                priceObj: zpriceObj,
                workflow: zworkflow
            }) 
        };
        /*try {
            const response = await fetchWithDefaults(`/submitOrder`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setCards(jsonData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        */
    }; 
    useEffect(() => {
        fetchCards();
        if(data){
            setUnitPrice(data.price)
        }
    },[])
    const handleChangeCard=(card)=>{
        if(card==='none'){
            setSelectedCard(false)
        }
        else{
            setSelectedCard(card);
        }
    }
    return (
        <div className="purchasePageWrapper">
            
            <div className="blockWrapper">
                <div className="blockHeader">
                    <div className="title" style={{margin:'unset'}}>Purchase Form - {title}</div>
                </div>

                <div className="blockContent">
                    <div className="purchaseSectionWrapper">
                        <div className="price">Price Per Lead: {unitPrice}</div>
                        <div className="purchaseSectionTitle">States (5 minimum)</div>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                className="qualifierFieldInput"
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder='Type State'
                                style={{width: '300px'}}
                            />
                            {inputValue !='' && inputValue && (
                                <div className="stateOptionsList">
                                    {filteredStates.map((state, index) => (
                                        <div key={index} className="option" onClick={()=>selectState(state)}>{state}</div>
                                    ))}
                                </div>
                            )}
                            
                        </div>
                    </div>
                    <div className="purchaseSectionWrapper">
                        {selectedStates && (
                            selectedStates.map((state, index) => (
                                <div key={`selectedState`+index} className="removeableOption">
                                    <span className="removeableRemove" onClick={()=>handleRemove(state)}>X</span>
                                    <span className="removeableTitle">{state}</span>
                                </div>

                            ))
                        )}
                    </div>
                    <div className="purchaseSectionWrapper">
                        <div className="purchaseSectionTitle">Order Quantity (5 minimum)</div>
                        <div className="inputWrapper">
                            <input 
                                type="text" 
                                className="qualifierFieldInput" 
                                style={{width: '300px'}}
                                value={quantity} 
                                onChange={handleQuantityChange}
                            />
                        </div>
                    </div>
                    
                    {selectedStates.length > 4 && parseInt(quantity) > 4 && (
                        <div className="submitWrapperForm" style={{textAlign:'center'}}>
                            <div className="totalPrice">Order Total: <b>{(unitPrice * quantity).toFixed(2)}</b></div>
                            <div className="selectCards">
                                <select className="qualifierFieldInput" 
                                    style={{width: '100%',height: '30px',fontFamily: 'inherit',fontSize: '13px',color: 'inherit'}}
                                    onChange={(e)=>handleChangeCard(e.target.value)}
                                    value={selectedCard}
                                >
                                    <option value="none">Select Your Payment Card</option>
                                    {cards && cards.map((card, index) => (
                                        <option value={card.id}>{card.card.brand} Ending in {card.card.last4}</option>
                                    ))}
                                </select>
                            </div>
                            {selectedCard && (
                                <div className="buttonWrapper">
                                    <div className="saveAsQueue solidQuilityActiveButton" onClick={()=>{}}>
                                        Submit Order
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PurchasePage;

//<div onClick={()=>purchaseNotice()}> CLOSE </div>


/*
 {cards && cards.map((state, index) => (
    <option value="none">Select Your Payment Card</option>
))}
*/