

import React, { useState } from 'react';


function RightQueueHeader({selected, selectTab}) {
    const [items, setItems] = useState(['Script','Text','Notes','Quote']);
    
    return (
        <div className="rightHeaderWrapper">
            {items.map((item, index) => ( 
               <div
               className={`queueHeaderBlock ${selected === index ? 'quilityActiveTab' : ''}`}
               onClick={() => selectTab(index)}
               key={`rightHeaderInfo${index}`}
           >
               {item}
           </div>
                
            ))}
        </div>
    );
    
}

export default RightQueueHeader;