import React, {useState, useEffect } from 'react';
import '../../css/leadCard.css';
import ContactInfo from '../callQueue/elements/sections/contactInfo';
import HomeInfo from '../callQueue/elements/sections/homeInfo';
import DispositionBlock from '../callQueue/elements/sections/dispositions';
import Qualifier from '../qualifier/qualifier';
import AdditionalInfo from '../callQueue/elements/sections/additionalInfo';
import { SuccessAlert } from '../successAlert';
import TagComponent from '../tags/tagComponent';
import { useSelector, useDispatch } from 'react-redux';
import { removeTagFromLead, addTagToLead } from '../../utilities/tagFunctions';
import { unixTommddhia} from '../../utilities/dashFunction';
import { updateLeadInfo } from '../../utilities/leadFunctions';
import LeadCardAppointments from '../appointment/leadCardAppointments';
import WorkflowInfo from '../workflow/workflowInfo';

function LeadData({lead, makeDial, qualifier, handleQualifier, alreadyTagged, expiration}) {
    const dispatch=useDispatch();
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [leadData, setLeadData] = useState(lead);
    const [successTrigger, setSuccessTrigger] = useState(false);
    const queue=useSelector((state) => state.queue.queue);
    const backUp=useSelector((state) => state.queue.originalQueue);
    const [selectedTags, setSelectedTags] = useState([]);
   
    const handleLeadData=(field, value)=>{
        let index=0;
        setLeadData(prevData => {
            const newData = [...prevData];
            // Check if index is valid
            if (index >= 0 && index < newData.length) {
              // Update the field value for the object at the specified index
              newData[index] = {
                ...newData[index],
                [field]: value
              };
            }
            return newData;
        });
        updateLeadInfo(queue,field,value,leadData[0].lead_id, dispatch, 'queue')
        updateLeadInfo(backUp,field,value,leadData[0].lead_id, dispatch, 'backup');
    }
    
    const updateStatusName=(name)=>{
        handleLeadData('status_name',name);
        setSuccessTrigger(true);
    }
    const removeTag= async (tagIdToRemove)=>{
        const tagRemoved=await removeTagFromLead(tagIdToRemove, agentId, leadData[0].lead_id);
        if(tagRemoved){
            const updatedTags = selectedTags.filter(tag => tag.id !== tagIdToRemove);
            // Update the state with the filtered array
            setSelectedTags(updatedTags);
        }
    }
    const addTag= (tag) => {
        addTagToLead(setSelectedTags, selectedTags, tag);
        let tags;
        if(selectedTags){
            tags=[selectedTags[0],tag];
        }
        else{
            tags=tag;
        }

        const formattedString = tags
        .map(item => `${item.id}*${item.name}`)
        .join(',');

        updateLeadInfo(queue,'tag_ids',formattedString,leadData[0].lead_id, dispatch, 'queue')
        updateLeadInfo(backUp,'tag_ids',formattedString,leadData[0].lead_id, dispatch, 'backup');
    }
    useEffect(() => {
        if(alreadyTagged){
            setSelectedTags(alreadyTagged);
        }
    }, [alreadyTagged]);
   
    
    return (
        <div style={{overflow: 'auto',height:'calc(100% - 60px)',overflowX: 'hidden', position: 'relative'}} className={`${expiration < 86400 && expiration > 0 ? 'expiring' : expiration < 0  ? 'expired': 'exclusive'}`}>
            <SuccessAlert message={'Status Changed'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger}/>
            {lead && leadData.map((leadItem, index) => (
                <span key={`leadItemKey${index}`}>
                    <span key={`leadDataBlock${index}`}>
                        <div className="leadName">{leadItem.borrower_first ? leadItem.borrower_first.replace(/\\/g, '') : 'unknown'} {leadItem.borrower_last ? leadItem.borrower_last.replace(/\\/g, '') : 'unknown'}</div>
                        {leadItem.status_name && (
                            <span className="statusName">{leadItem.status_name}</span>
                        )}
                        {leadItem.wf_name && (
                            <WorkflowInfo type='workflow' title={leadItem.wf_name} workflowId={leadItem.wf_id} hopperId={leadItem.wf_hopper_id} />
                        )}
                        {leadItem.convoFlowName && (
                            <WorkflowInfo type='conversation' title={leadItem.convoFlowName} workflowId={leadItem.pathway} hopperId={leadItem.wf_hopper_id} />
                        )}
                        <span className="statusName">{leadData[0].lead_type !== null ? `Lead Type: ${leadData[0].lead_type}` : null}</span>
                        <TagComponent selectedTags={selectedTags} setSelectedTags={setSelectedTags} leadId={leadItem.lead_id}/>
                        
                        {selectedTags.length > 0 && (
                            selectedTags.map((tag, index) => (
                                tag && (
                                    <span className="tagWrapper" key={`taggedLead${tag.id}`}>
                                        {tag.name}
                                        {tag.id.includes('master') && (
                                            null
                                        )}
                                        <span className="removeTag solidQuilityDestructiveButton" onClick={()=>removeTag(tag.id)}>x</span>
                                    </span>
                                )
                            ))
                        )}
                        <div className="leadCardAddressHolder">{leadItem.address} {leadItem.city} {leadItem.state} {leadItem.zip}</div>
                        {leadItem.exclusivity && (
                            <div className="exclusivity">Exclusive until - {unixTommddhia(leadItem.exclusivity)}</div>
                        )}
                    </span>
                    {leadData[0].appointments && (
                        <LeadCardAppointments appointments={leadData[0].appointments} lead={leadData[0].lead_id}/>
                    )}
                </span>
            ))}
            {lead && (
                <div className="quilityParentBlockContent" style={{position: 'relative'}}>
                    {qualifier ? (
                        <Qualifier handleLeadData={handleLeadData} lead={leadData} handleSetQualifier={handleQualifier}/>
                    ) : (
                        <>
                            <ContactInfo handleLeadData={handleLeadData} leadData={leadData} makeDial={makeDial}/>
                            {leadData[0].custom_fields && (
                                <AdditionalInfo handleLeadData={handleLeadData} leadData={leadData} />
                            )}
                            <HomeInfo handleLeadData={handleLeadData}  leadData={leadData} />
                            <DispositionBlock addTag={addTag} removeTag={removeTag} selectedTags={selectedTags} updateStatusName={updateStatusName} from={'dash'} leadId={leadData[0].lead_id}/>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default LeadData;
//<TagComponent selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>