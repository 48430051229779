import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangle } from '@fortawesome/pro-regular-svg-icons';


function LeadCardInfo({leadData, formatNumber, formatField, formatDate}) {

    const [isOpen, setIsOpen] = useState(false);
    const numbers=['borrower_cell', 'borrower_home', 'borrower_work']
    const names=['borrower_first', 'borrower_last']
    const Conumbers=['co_borrower_cell', 'co_borrower_home', 'co_borrower_work']
    const Conames=[ 'co_borrower_first', 'co_borrower_last']
    //const leadDataPoints=['amount_contacted','date_entered','date_uploaded','total_emails','last_contacted']
    const keysWithNullValue = [];
    const keysWithNonNullValue = [];
    const keysToIgnore=['email','ben_type','additional_info','return_call','status_history','request_id','qualifier','credit_rating','dnc','custom','policy_plan','amount_contacted','date_entered','date_uploaded','total_emails','last_contacted','team_id','sent_by','expiration','parent_status','assigned_agent','additional_policy','lead_type','county','call_to','call_from','batch','pdf','lead_status','lead_id','agent_id','address','city','state','zip','address2','co_borrower_first', 'co_borrower_last', 'co_borrower_cell', 'co_borrower_home', 'co_borrower_work','borrower_first', 'borrower_last','borrower_cell', 'borrower_home', 'borrower_work','brood','call_begin','call_end','file_link','home_owner','lead_cost','lat','lon','medical_conditions','ninja_status','old_lead_id','old_ninja_id','original_lead','script','scheduled_call','sub_type','system_notes','notes','tags','type_of_lead','value','zoom_scheduled']
    const nullToIgnore=['agent_who_sold','sold_manager','policy_number','apv','face_amount','required_monthly','app_status_date','app_link','app_date','application_type','carrier','policy','app_notes','hobby','motivation','vendor']
    // Iterate through the array and organize keys
    const openUp=()=>{
        setIsOpen(!isOpen);
    }
    leadData.forEach((dataObject) => {
        for (const key in dataObject) {
            if (keysToIgnore.includes(key)) {
                continue;
            }
            if (dataObject[key] === null) {
                if (nullToIgnore.includes(key)) {
                    continue;
                }
                keysWithNullValue.push(key);
            } else {
                keysWithNonNullValue.push(key);
            }
        }
    });
    
    return (
        <>
            {leadData.map((item, index) => (
                <>
                    <div className="leadName">{item.borrower_first} {item.borrower_last}</div>
                    <div className="addressHolder">
                        <span className="addressSlot">{item.address+',' ?? 'Click To Add'}</span>
                        <span className="addressSlot">{item.city+',' ?? 'Click To Add'}</span>
                        <span className="addressSlot">{item.state ?? 'Click To Add'}</span>
                        <span className="addressSlot">{item.zip ?? 'Click To Add'}</span>
                    </div>
                    <div className="leadType">Lead Level: {item.lead_type}</div>
                    <div className="leadType">{item.type_of_lead !== null ? `Lead Type: ${item.type_of_lead}` : null}</div>
                    <div className="leadInformationBlock">
                        <div className="leadCardSubTitle">Contact Information</div>
                        <div className="leadCardBlock double">
                            {names.map((field, index) => (
                                <div className="cardInfoItem">{formatField(field)+":"} {item[field] !== null ? item[field]:"Click To Add"}</div>
                            ))}
                            {numbers.map((field, index) => (
                                <div className="cardInfoItem">{formatField(field)+":"} {item[field] !== null ? formatNumber(item[field]):"Click To Add"}</div>
                            ))}
                            <div className="cardInfoItem">Email : {item.email ?? 'Click To Add'}</div>
                        </div>
                        <div className="leadCardBlock double">
                            {Conames.map((field, index) => (
                                <div className="cardInfoItem">{formatField(field)+":"} {item[field] !== null ? item[field]:"Click To Add"}</div>
                            ))}
                            {Conumbers.map((field, index) => (
                                <div className="cardInfoItem">{formatField(field)+":"} {item[field] !== null ? formatNumber(item[field]):"Click To Add"}</div>
                            ))}
                            <div className="cardInfoItem">Co Lead Email : {item.co_borrower_email ?? 'Click To Add'}</div>
                        </div>
                    </div>
                    <div className="bigBlock">
                        <div className="leadCardSubTitle">Lead Information</div>
                        <div className="leadCardBlock">
                            {keysWithNonNullValue.map((field, index) => (
                               <div className="cardInfoItem">
                                    {formatField(field) + ":"}{" "}
                                    {item[field] !== null ? (
                                        field === 'mortgage' ? (
                                            item[field] ? item[field] : "Click To Add"
                                        ) : (
                                            formatDate(item[field])
                                        )
                                    ) : (
                                        "Click To Add"
                                    )}
                               </div>
                            ))}
                        </div>
                    </div>
                    <div className="leadCardSubTitleStable leadCardSubNode" onClick={()=>openUp()}><span className='openArrow'><FontAwesomeIcon className={`transition ${isOpen ? 'rotate' : ''}`} icon={faTriangle}/></span>Empty Information</div>
                    <div className={`leadCardBlock emptyInfo ${isOpen ? 'isOpen' : ''}`}>
                        {keysWithNullValue.map((field, index) => (
                            <div className="cardInfoItem double">
                                {formatField(field)+":"}
                                    Click To Add
                            </div>
                        ))}
                    </div>
                </>
            ))} 
        </>
    );
}

export default LeadCardInfo;