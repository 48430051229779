import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTriangle} from '@fortawesome/pro-regular-svg-icons';
import ContactField from './field';

function HealthInfo({leadData, handleLeadData, formatField, agentId, formatNumber}) {
    const [isOpen, setIsOpen] = useState(false);
    const homeInfo = ['borrower_sex', 'tobacco', 'borrower_age', 'borrower_dob', 'height', 'weight', 'co_borrower_age','co_borrower_dob','co_borrower_height','co_borrower_weight', 'co_borrower_tobacco', 'co_borrower_sex'];
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);

    const innerWrapperRef = useRef(null);

    const openUp = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (innerWrapperRef.current) {
            setInnerWrapperHeight(innerWrapperRef.current.clientHeight);
        }
    }, [isOpen]);
    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`}>
            <div className="quilityNestedBlockHeader" onClick={()=>openUp()}>
                <div className="quilityParentBlockTitle">Health Information </div>
                <span className='quilityBlockIcon'>
                    <FontAwesomeIcon className={`transition ${isOpen ? 'rotate' : ''}`} icon={faChevronDown}/>
                </span>
            </div>
            <div className="quilityNestedBlockContent" style={{ height: isOpen ? innerWrapperHeight + 25 : '0px' }}>
                <div className="innerWrapper" ref={innerWrapperRef}>
                    {homeInfo.map((field, index) => (
                        <div className="leadCardBlock doubleLine" key={`healthInfo${index}`}>
                            <ContactField handleLeadData={handleLeadData} field={field} agentId={agentId} leadData={leadData} formatField={formatField} index={index}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
    
}

export default HealthInfo;
