import React, {useState} from 'react';
import Warning from '../../template/warning';
import { fetchWithDefaults } from '../../config/fetchDefault';

function Subscription({data, flex, formatDate,setSubscriptions}) {
    const [warning, setWarning] = useState(false);
    const [customer, setCustomer] = useState(false);
    const [subscription, setSubscription] = useState(false);
    const cancel = () => {
        setWarning(false);
    };
   
    const confirm = async () => {
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    subscription: subscription,
                    cust:customer
                }) 
            };
          
            const response = await fetchWithDefaults('/cancelSubAtPeriod', requestOptions);
        
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const jsonData = await response.json(); // Assuming the response is JSON
            setWarning(false);
            setSubscriptions(jsonData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const cancelSubscription = async (id, cust) => {
        setWarning(true);
        setSubscription(id);
        setCustomer(cust);
    }
    function convertCentsToDollarsAndCents(cents) {
        // Calculate dollars and cents
        const dollars = Math.floor(cents / 100);
        const remainingCents = cents % 100;
      
        // Format the result
        const formattedResult = `${dollars}.${remainingCents.toString().padStart(2, '0')}`;
      
        return formattedResult;
    }
   
    
    const formatStatus = (inputString) => {
        // Replace underscores with spaces
        const stringWithSpaces = inputString.replace(/_/g, ' ');
      
        // Capitalize the first letter of every word
        const formattedString = stringWithSpaces
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      
        return formattedString;
    };
    return (
        <>
        <>
        {warning && 
            <Warning title={"Cancel Subscription"} cancel={cancel} confirm={confirm} message={"Are you sure you want to cancel this subscription? This may cause you to loose access to certain services."} buttons={[{name:'Don\'t Cancel',action:'cancel'},{name:"Cancel Now", action:'confirm'}]} />
        }
        </>
        <div className={`frontPageQuilityParentBlockWrapper`} style={{flex: flex}}>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">Your Subscription</div>
            </div>
            
            <div className="quilityParentBlockContent">
            {data.length > 0 ? (
                data.map((subscription, index) => (
                <div className={`quilityNestedBlockWrapper ${subscription.cancel_at && 'cancelled' }`} key={'subscription'+index} style={{marginTop: '25px'}}>
                    <div className="removeItem" onClick={()=> cancelSubscription(subscription.id, subscription.customer)} >X</div>
                    <div className="quilityNestedBlockHeader">
                        <div className="quilityParentBlockTitle">
                            {subscription.description}
                        </div>
                    </div>
                    <div className="quilityNestedBlockContent">
                        <div className="innerContentWrapper">
                            {subscription.cancel_at ? (
                                <div className="quilityDataLine">
                                    <span className="quilityDataLineTitle">Status: </span>
                                   Subscription Cancelled
                                </div>
                            ):(
                                <div className="quilityDataLine">
                                    <span className="quilityDataLineTitle">Status: </span>
                                    {formatStatus(subscription.status)}
                                </div>
                            )}
                            <div className="quilityDataLine">
                                <span className="quilityDataLineTitle">Quantity: </span>
                                {subscription.quantity}
                            </div>
                            <div className="quilityDataLine">
                                <span className="quilityDataLineTitle">Start Date: </span>
                                {formatDate(subscription.start_date)}
                            </div>
                            {subscription.cancel_at && (
                                <div className="quilityDataLine">
                                    <span className="quilityDataLineTitle">Cancels On: </span>
                                    {formatDate(subscription.cancel_at)} 
                                </div>
                            )}
                            <div className="quilityDataLine">
                                <span className="quilityDataLineTitle">Billing Interval: </span>
                                {formatStatus(subscription.plan.interval)}ly 
                            </div>
                            <div className="quilityDataLine">
                                <span className="quilityDataLineTitle">Price: </span>
                                ${convertCentsToDollarsAndCents(subscription.plan.amount)}
                            </div> 
                            <div className="quilityDataLine">
                                <span className="quilityDataLineTitle">Total: </span>
                                ${convertCentsToDollarsAndCents((subscription.plan.amount * subscription.quantity))}
                            </div> 
                        </div>
                    </div>
                </div>
              ))
            ) : (
                "You have no subscription"
            )}
            </div>
            <div className="blockFooter">
                <div
                    className="buttonWrapper"
                    style={{ marginLeft: "10px" }}
                >
                    <div className="dataButtonBlock">Subscriptions: {data.length} </div>
                    <div className="actionButtonBlock">
                        
                    </div>
                </div>
                
            </div>
        </div>
        </>
    );
}

export default Subscription;
