import { createSlice } from '@reduxjs/toolkit'

export const filterSlice = createSlice({
    name: 'filters',
    initialState: {
        filters: [],
        activeFilter: ''
    },
    reducers: {
        setFilters(state, action) {
            state.filters = action.payload;
        },
        addFilter(state, action) {
            state.filters.unshift(action.payload);
        },
        setActiveFilterName(state, action) {
            state.activeFilter = action.payload;
        }
    },
})
export const { setFilters, setActiveFilterName, addFilter } = filterSlice.actions;

export default filterSlice.reducer

