import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserDoctor } from '@fortawesome/pro-regular-svg-icons';


function Condition({handleSetProfile, conditions, removeCondition,medicalForm, removeFormCondition}) {
    return (
        <div style={{width: "calc(50% - 40px)",textAlign: 'center', padding:'0px 10px'}}>
            <div className="addTo">Conditions <FontAwesomeIcon icon={faUserDoctor} style={{marginLeft:'5px'}}/></div>
            <div className="conditionHolder">
                {medicalForm && medicalForm.length > 0 && medicalForm.map((med, index) => {
                    if (med.conditionId) {
                        return (
                            <div className="conditionBubble" key={index}>
                                {med.condition}
                                <span className="smallRemove" onClick={() => removeFormCondition(med.conditionId,index)}>x</span>
                            </div>
                        );
                    } else {
                        return null; 
                    }
                })}
                {conditions && conditions.map((match, index) => (
                    <div className="conditionBubble" key={index}>{match}<span class="smallRemove" onClick={()=>removeCondition(index)}>x</span></div>
                ))}
            </div>
        </div>
    );

}

export default Condition;
