import { createSlice } from '@reduxjs/toolkit'

export const workflowSlice = createSlice({
    name: 'workflows',
    initialState: {
        workflows: []
    },
    reducers: {
        setWorkflows(state, action) {
            state.workflows = action.payload;
        },
    },
})

export const { setWorkflows } = workflowSlice.actions;

export default workflowSlice.reducer;