import React, { useState, useEffect } from "react";
import '../../css/templates.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faPencil } from "@fortawesome/pro-regular-svg-icons";
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';


function LeadType({ }) {
    const types=useSelector((state) => state.leadTypes.leadTypes)
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [editedText, setEditedText] = useState({});
    const [editedIndex, setEditedIndex] = useState(null);


    const handleEditClick = (index, text) => {
        setEditedIndex(index);
        //setEditedText({ [index]: text });
        setEditedText({ ...editedText, [index]: text });
    };
    const handleSaveClick = (index, id) => {
        // Save the edited text or perform any necessary action
        console.log("Saving:", editedText[index]);
        updateName(id, editedText[index]);
        setEditedIndex(null);
    };
    
    const handleInputChange = (e, index) => {
        setEditedText({ ...editedText, [index]: e.target.value });
    };
    const updateName = async (orginal, updated) => {
        if(orginal !== updated && updated !== undefined){
            try {
                const requestOptions = {
                    method: 'PATCH',
                    body: JSON.stringify({ 
                        agent: agentId,
                        orginal:orginal,
                        updated:updated
                    }) 
                };
              
                const response = await fetchWithDefaults('/updateLeadType', requestOptions);
                
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
            } 
            catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };
    return (
        <div className="outerWrapper">
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">
                    <FontAwesomeIcon icon={faClipboardList} className="colorIcon" />
                </span>
                Lead Types
            </span>
            <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
            >
            <div className={`mainBlockWrapper`} style={{width: '50%'}}>
                        <div className="mainBlockHeader">
                            <div className="mainBlockTitle">Lead Types</div>

                        </div>
                        <div className="mainBlockContent">
                            {types.map((item, index) => (    
                                <div className={`bigBlock`} key={`leadTypes${index}`} style={{marginTop: '25px'}}>
                                    <div className="leadCardSubTitle">
                                        <>
                                            {editedText[index] || item.name}
                                            <span
                                                className="hoverIcon"
                                                onClick={() =>
                                                handleEditClick(index, item.name)
                                                }
                                            >
                                                <FontAwesomeIcon icon={faPencil} />
                                            </span>
                                        </>
                                    </div>
                                    <div className="infoWrapper">
                                        <div className="systemInfoItem">
                                            <span className="itemTitle">Lead Type Name: </span>
                                            {editedIndex === index ? (
                                            <input
                                                type="text"
                                                className="standardInputBox"
                                                value={editedText[index] || item.name}
                                                onChange={(e) => handleInputChange(e, index)}
                                                onBlur={() => handleSaveClick(index, item.name)}
                                                autoFocus
                                            />
                                            ) : (
                                            <>
                                                <span style={{ marginLeft: "5px" }} onClick={() =>
                                                    handleEditClick(index, editedText[index])
                                                }>
                                                {editedText[index] || item.name}
                                                </span>
                                                <span
                                                className="hoverIcon"
                                                
                                                >
                                                <FontAwesomeIcon icon={faPencil} />
                                                </span>
                                            </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="blockFooter">
                            <div
                                className="buttonWrapper"
                                style={{ marginTop: " 5px", marginLeft: "10px" }}
                            >
                                <div className="dataButtonBlock">Lead Types: {types.length}</div>
                                <div className="actionButtonBlock" style={{width: '150px'}}>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
        </div>

    );
}

export default LeadType;

