import React, { useState, useEffect } from "react";
import '../../css/trust.css';
import { fetchWithDefaults } from '../../config/fetchDefault';

function CustomerProfile({data, soleSid, agentId, einSid, formatString, setAddCustProfile}) {
    const [currentProfile, setCurrentProfile]=useState(soleSid);
    const [inProgress, setInProgress]=useState(false);
    const [profiles, setProfiles]=useState(false);
    const makeDefaultProfile=async(profileSid, type)=>{
        try {
            const response = await fetchWithDefaults(`/defaultCustomerProfile`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json", 
                    },
                    body: JSON.stringify({ 
                        agent: agentId,
                        profileSid:profileSid,
                        type:type,
                    })
                }
            );
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json()
            setCurrentProfile(jsonData[0].profile);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const removeProfile= async (profileSid)=>{
        try {
            const response = await fetchWithDefaults(`/deleteCustomerProfile`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json", 
                    },
                    body: JSON.stringify({ 
                        agent: agentId,
                        profileSid:profileSid,
                    })
                }
            );
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json()
            if(jsonData.status===true){
                const newArray = data.filter(item => item.sid !== profileSid);
                setProfiles(newArray);
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        let twilioInProgressProfiles = [];
        let profiles = data.filter(profile => {
            if (profile.status === "in-review") {
                twilioInProgressProfiles.push(profile);
                return true; // Exclude from main array
            }
            if (profile.status === "draft") {
                return false; // Exclude from main array
            }
            return true; // Include in main array
        });
        if(twilioInProgressProfiles.length > 0){
            setInProgress(true);
        }
        console.log(twilioInProgressProfiles);
        profiles.sort((a, b) => {
            // If status is "twilio-approved", it comes first
            if (a.status === "twilio-approved") return -1;
            if (b.status === "twilio-approved") return 1;
            // Otherwise, maintain the order
            return 0;
        });
        setProfiles(profiles)
    },[])
    return (
        <div className="mainBlockWrapper twoX">
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">Agent Profile</div>

            </div>
            <div className="mainBlockContent">
                {profiles && profiles.map((item, index) => (
                    <div key={`custProfile${index}`} className={`bigBlock ${item.sid === currentProfile || item.sid === einSid ? 'inUse' : ''}`} style={{marginTop: '25px'}}>
                        {item.status==='twilio-approved' ? (
                             null
                        ) : (
                            <div className="quilityCloseWindow oulineQuilityDestructiveButton" onClick={()=>removeProfile(item.sid)}>X</div>
                        )}
                       
                        <div className="leadCardBlock">
                            <div className="innerWrapper">
                                <div className="leadInfoLine">
                                    <span className="dataFieldTitle">Name: </span>
                                    <span className="leadData">{item.friendlyName}</span>
                                </div>
                                <div className={`leadInfoLine  a2p${item.status.replace('twilio-', '')}`}>
                                    <span className="dataFieldTitle">Status: </span>
                                    <span className="leadData">{formatString(item.status.replace('twilio-',''))}</span>
                                </div>
                               
                                <div className="leadInfoLine">
                                    <span className="dataFieldTitle">Date: </span>
                                    <span className="leadData">{item.dateCreated}</span>
                                </div>
                                <div className="leadInfoLine">
                                    <span className="dataFieldTitle">In-Use: </span>
                                    <span className="leadData">{item.sid === currentProfile || item.sid === einSid ? 'Active' : <div className="quilitySmallInlineButton" style={{display: 'inline-block',height: '25px',lineHeight: '25px'}} onClick={()=>makeDefaultProfile(item.sid, currentProfile !== null ? 'sole' : 'ein')}>Make Default</div>}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))} 
            </div>
            <div className="blockFooter">
                <div
                    className="buttonWrapper"
                    style={{ marginLeft: "10px" }}
                >
                    <div className="dataButtonBlock">Profiles: {profiles.length}</div>
                    {inProgress===false && (
                        <div className="actionButtonBlock" style={{width: '150px',marginTop: '5px'}}>
                            <div className="accentButton solidQuilityActiveButton" onClick={()=>setAddCustProfile(true)}>
                                + New Profile
                            </div>
                        </div>
                    )}
                </div>
                
            </div>
        </div>
    );
}

export default CustomerProfile;


/*
<div className="leadInfoLine">
    <span className="dataFieldTitle">In-Use: </span>
    <span className="leadData">{item.sid === currentProfile || item.sid === einSid ? 'Active' : <div className="quilitySmallInlineButton" style={{display: 'inline-block',height: '25px',lineHeight: '25px'}} onClick={()=>makeDefaultProfile(item.sid, currentProfile !== null ? 'sole' : 'ein')}>Make Default</div>}</span>
</div>
*/