import { fetchWithDefaults } from '../config/fetchDefault';

const deleteService = async (sidToRemove, agentId,setServices) => {
    try {
        const requestOptions = {
            method: 'DELETE',
            body: JSON.stringify({ 
                agent: agentId,
                service:sidToRemove,
            }) 
        };
      
        const response = await fetchWithDefaults(`/deleteMessageService`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData.status){
            setServices(prevServices => prevServices.filter(service => service.sid !== sidToRemove));
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const addServiceNumber = async (serviceSid, agentId, number, services, setServices) => {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                agent: agentId,
                service:serviceSid,
                number: number
            }) 
        };
      
        const response = await fetchWithDefaults(`/addNumberFromMessageService`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData.status){
            const updatedServices = services.map(service => {
                if (service.sid === serviceSid) {
                    // Create a new array with the existing phone numbers and the new phone number
                    const updatedPhoneNumbers = [...service.phoneNumbers, number];
                    // Update the service with the new phone numbers
                    return {
                        ...service,
                        phoneNumbers: updatedPhoneNumbers
                    };
                }
                return service;
            });
            setServices(updatedServices);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const deleteServiceNumber = async (serviceSid, agentId, number, services, setServices) => {
    try {
        const requestOptions = {
            method: 'DELETE',
            body: JSON.stringify({ 
                agent: agentId,
                service:serviceSid,
                number: number
            }) 
        };
      
        const response = await fetchWithDefaults(`/removeNumberFromMessageService`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData.status){
            const updatedServices = services.map(service => {
                if (service.sid === serviceSid) {
                    // Check if there are phone numbers to filter
                    if (service.phoneNumbers && service.phoneNumbers.length > 0) {
                        // Filter out the phone number with the matching sid
                        const updatedPhoneNumbers = service.phoneNumbers.filter(phoneNumber => {
                        return phoneNumber.sid !== number;
                        });
                        // Update the service with the filtered phone numbers
                        return {
                            ...service,
                            phoneNumbers: updatedPhoneNumbers
                        };
                    }
                }
                return service;
            });
            setServices(updatedServices);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
export {deleteService, deleteServiceNumber};