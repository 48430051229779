import React, {useState, useEffect} from 'react';
import '../../css/leadCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCommentDots, faListAlt, faBookAtlas } from '@fortawesome/pro-regular-svg-icons';
import AttachFile from '../callQueue/elements/sections/attachFile';
import ScheduleCall from '../callQueue/elements/sections/scheduleCall';
import WeekCalendar from '../callQueue/elements/sections/calendar';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { getConvo } from '../../utilities/dashFunction';
import QueueConvo from '../callQueue/elements/conversation';
import { useSelector } from 'react-redux';


function LeadCardTopBar({expiration, setLoading, data, textingNumbers, handleQualifier, qualifier, calendarChange, setCalendarUsing, newAppt, setNewAppt,setEvent,newEventPackage}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const scopes=useSelector((state) => state.scopes.scopes);
    const timezone=agentInfo.timezone;
    const calendar=agentInfo.defaultCalendar;
    const [inputValue, setInputValue] = useState('');
    //const [cursorPosition, setCursorPosition] = useState(0);
    const agentNumbers=['5305180876','2342342345']
    //const [notes, setNotes] = useState(data[0].notes || "You have no notes yet! (click to edit notes)");
    const [convos, setConvos] = useState([]);
    const [activeConvo, setActiveConvo]=useState([]);
    const nestedObject = convos[0];


    const handleInputChange = (content) => {
        setInputValue(content);
    };
    const handleBlur = (event) => {
        saveNotes(event.target.innerHTML);
    };
    const [activeConvoIndex, setActiveConvoIndex] = useState(0);

    const handleConversationClick = (index,convo) => {
        setActiveConvo(convo);
        setActiveConvoIndex(index);
    };
    const filterNonBlanknumbers = (number1, number2, number3) => {
        // Filter out blank numbers
        const convoId=[];
        const nonBlanknumbers = [number1, number2, number3].filter(number => number !== null && number !== undefined && number !== '');
        const mappedArray = nonBlanknumbers.map((clientNumber, ciindex) => (
            agentNumbers.map((agentNumber, anindex) => (
                convoId.push(btoa('+1'+agentNumber+":+1"+clientNumber))
            ))  
        ));
        
        const response=[{'convos':convoId,'numbers':nonBlanknumbers}]
        return response;
    };
    
    let numbers=filterNonBlanknumbers(data[0].borrower_cell, data[0].borrower_home, data[0].borrower_work);

    const buttons=['Lead Notes','Policy Options','Schedule Call','Attach File','Qualifier','Set Appointment', 'Lead Pdf'];
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [isNotesOpen, setIsNotesOpen] = useState(false);
    const [isPlanOpen, setIsPlanOpen] = useState(false);
    const [isAttachOpen, setIsAttachOpen] = useState(false);
    const [isScheduleOpen, setIsScheduleOpen] = useState(false);
    const [openCal, setOpenCal] = useState(false);

    
    const toggleNotes = () => {
        setIsNotesOpen(!isNotesOpen);
        setIsPlanOpen(false);
        setIsMessageOpen(false);
        setIsAttachOpen(false);
        setIsScheduleOpen(false);
    };
    const toggleMessages = () => {
        setIsMessageOpen(!isMessageOpen)
        setIsNotesOpen(false);
        setIsPlanOpen(false);
        setIsAttachOpen(false);
        setIsScheduleOpen(false);
    };
    const toggleAttach = () => {
        setIsAttachOpen(!isAttachOpen)
        setIsNotesOpen(false);
        setIsPlanOpen(false);
        setIsMessageOpen(false);
        setIsScheduleOpen(false);
    };
    
    const togglePlan = () => {
        setIsPlanOpen(!isPlanOpen);
        setIsNotesOpen(false);
        setIsMessageOpen(false);
        setIsAttachOpen(false);
        setIsScheduleOpen(false);
    };
    const toggleSchedule = () => {
        setIsScheduleOpen(!isScheduleOpen)
        setIsPlanOpen(false);
        setIsNotesOpen(false);
        setIsMessageOpen(false);
        setIsAttachOpen(false);
    };
    const toggleSetAppt=()=>{
        setOpenCal(!openCal);
        //setNewEvent(true)
    }
    const saveNotes = async (value) =>{

        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    lead: data[0].lead_id,
                    column: 'notes',
                    value: value
                }) 
            };
            const response = await fetchWithDefaults('/updateLead', requestOptions);
        
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    const savePLan = async (value) =>{

        try {
           
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    lead: data[0].lead_id,
                    column: 'policy_plan',
                    value: value
                }) 
            };
            const response = await fetchWithDefaults('/updateLead', requestOptions);
    
        
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleClick = (link) => {
        window.open(link, '_blank');
    };

    useEffect(() => {
        // Fetch data when the component mounts
        if(isMessageOpen){
            getConvo(data[0].lead_id, setConvos, setActiveConvo)
        }
    }, [isMessageOpen]);
    return (
        <div className="leadCardTopBar">
            <div className="leadCardBarMessages">
                <div className="cardBarMessageBlock" onClick={toggleMessages}><span className="messageButton min"><FontAwesomeIcon icon={faCommentDots} /></span></div>
                <div className={`conversationWrapper${isMessageOpen ? ' openConvo' : ''}`}>
                    <QueueConvo expiration={expiration} setLoading={setLoading} lead={data[0]} currentLead={data[0]} from='leadCard' textingNumbers={textingNumbers} conversations={convos} setConversations={setConvos} currentLeadId={data[0].lead_id}/>
                </div>

                <div className="cardBarMessageBlock"><span className="phoneLogButton min"><FontAwesomeIcon icon={faListAlt} /></span></div>
                <div className="cardBarMessageBlock"><span className="demoButton min"><FontAwesomeIcon icon={faBookAtlas} /></span></div>
            </div>
            <div className="barButtonWrapper">
                {buttons.map((buttonName, buttonIndex) => {
                    if(buttonName==='Lead Notes'){
                        return (
                            <>
                                <div className="leadCardTopBarButton" onClick={toggleNotes}>{buttonName}</div>
                                <div className={`leadNotesContentWrapper${isNotesOpen ? ' open' : ''}`}>
                                    <div className="subTitle">Notes</div>
                                    <div className="timestamp2 smallButton">Time Stamp</div>
                                    <div 
                                        className="leadNotes2 new" 
                                        id="leadNotes2" 
                                        suppressContentEditableWarning={true}
                                        contentEditable="true" 
                                        onBlur={(event)=> saveNotes(event.target.textContent)}
                                    >
                                        <span className="imsafe">
                                        {data[0].notes ? (
                                               <div dangerouslySetInnerHTML={{ __html: data[0].notes }} />
                                            ) : (
                                                "You have no notes yet! (click to edit notes)"
                                            )}
                                        
                                        </span>
                                    </div>				
                                    <div className="doneLeadNotes standardButton" onClick={toggleNotes}>Done</div>
                                </div>
                            </>
                        );
                    }
                    else if(buttonName==='Policy Options'){
                        return (
                            <>
                                <div key={'button'+buttonIndex} className="leadCardTopBarButton" onClick={togglePlan}>{buttonName}</div>
                                <div className={`leadNotesContentWrapper${isPlanOpen ? ' open' : ''}`}>
                                    <div className="subTitle">Policy Plan</div>
                                    <div className="timestamp2 smallButton">Time Stamp</div>
                                    <div className="leadNotes2 new" id="leadNotes2" contentEditable="true" suppressContentEditableWarning={true} onBlur={(event)=> savePLan(event.target.textContent)}>
                                        <span className="imsafe">
                                        {data[0].policy_plan ? (
                                                data[0].policy_plan
                                            ) : (
                                                "You have no plan yet! (click to edit gameplan)"
                                            )}
                                        
                                        </span></div>				
                                    <div className="doneLeadNotes standardButton" onClick={togglePlan}>Done</div>
                                </div>
                            </>
                        );
                    }
                    else if(buttonName==='Attach File'){
                        return (
                            <>
                                <div key={'button'+buttonIndex} className="leadCardTopBarButton" onClick={toggleAttach}>{buttonName}</div>
                                <div className={`dialogBox ${isAttachOpen ? 'openBox' : ''}`} style={{textAlign: 'center',width: '600px',marginTop: '50px'}}>
                                    <AttachFile leadId={data[0].lead_id}/>
                                </div>
                               
                            </>
                        );
                    }
                    else if(buttonName==='Schedule Call'){
                        return (
                            <>
                                <div key={'button'+buttonIndex} className="leadCardTopBarButton" onClick={toggleSchedule}>{buttonName}</div>
                                <div className={`dialogBox ${isScheduleOpen ? 'openBox' : ''}`} style={{textAlign: 'center',width: '600px',marginTop: '50px'}}>
                                    <ScheduleCall futureCall={data[0].callBackTime} leadId={data[0].lead_id} clientName={`${data[0].borrower_first} ${data[0].borrower_last}`}/>
                                </div>
                               
                            </>
                        );
                    }
                    else if(buttonName==='Lead Pdf'){
                        return (
                            <div key={'button'+buttonIndex} className="leadCardTopBarButton" onClick={()=>handleClick(data[0].pdf)}>{buttonName}</div>
                        );
                    }
                    else if(buttonName==='Set Appointment'){
                        if(scopes.length > 0){
                            return (
                                <>
                                    <div className="leadCardTopBarButton" onClick={()=>toggleSetAppt()}>{buttonName}</div>
                                    <div className={`calendarWrapper ${openCal ? 'openCal' : ''}`} >
                                        {openCal && (
                                            <WeekCalendar calendarChange={calendarChange} calendarUsing={setCalendarUsing} agentTimezone={timezone} newAppt={newAppt} setNewAppt={setNewAppt} defaultCalendar={calendar} newEventPackage={newEventPackage} setIsOpen={toggleSetAppt} setEvent={setEvent} agentId={agentInfo.agentId} />
                                        )}
                                        
                                    </div>
                                </>
                            );
                        }
                    }
                    else if(buttonName==="Qualifier"){
                        
                        return <div key={'button'+buttonIndex} className={`leadCardTopBarButton ${qualifier ? 'active':''}`} onClick={()=>handleQualifier()}>{buttonName}</div>
                    }
                    else{
                        return <div key={'button'+buttonIndex} className="leadCardTopBarButton">{buttonName}</div>
                    }
                })}
            </div>
            <div className="leadCardBarOperations">
            </div>
        </div>
    );
}

export default LeadCardTopBar;

/*<
<span className="convoPickerButtons">
{convos.map((convo, index) => (
    <>{//{atob(Object.keys(convo)[0]).split(':')[0]}
    }
        <span
            key={Object.keys(convo)[0]}
            className={`conversationPicker ${index === activeConvoIndex ? 'softActive' : ''}`}
            onClick={() => handleConversationClick(index,Object.keys(convo)[0])}
        >
                Convo #{index + 1}
        </span>     
    </> 
    
))}
    </span>
*/