import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';


function Dials({details}) {
    const [open, setOpen] = useState(false);
    let calls=0;
    let contacts=0;
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    } 
    function formatToMonthDay(dateString) {
        const originalDate = new Date(dateString);
        
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = originalDate.toLocaleDateString('en-US', options);
      
        return formattedDate;
    }
    details.map((detail, index) => (
        calls=calls + parseInt(detail.callsMade),
        contacts=contacts + parseInt(detail.contacts)
    ))
    return (

            <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
                <div className='quilityNestedBlockHeader'>
                    <span className="quilityParentBlockTitle" style={{width: '170px'}}>Dials and Contacts</span>
                    <span style={{marginLeft:'20px'}}>
                        <span className="typeBlock">{details.length} Active Days</span>
                        <span className="typeBlock"><span className="subSummary">Total Calls: {calls}</span></span>
                        <span className="typeBlock">Total Contacts: {contacts}</span>
                    </span>
                    <span className='quilityBlockIcon'><FontAwesomeIcon className={`transition ${open ? 'rotate' : ''}`} icon={faChevronUp}/></span>
                </div>
                
                
               


                <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                    {details.map((detail, index) => (
                        <div className="detailItem" key={"callback"+index}>
                            <span className="detailDialDate">{formatToMonthDay(detail.dialDate)}</span>
                            <span className="detailDialCount">Dials: {detail.callsMade}</span>
                            <span className="detailDialCount">Contacts: {detail.contacts}</span>
                        </div>
                    ))}
                </div>
            </div>

    );
}

export default Dials;