import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import '../../css/lead.css';

function LeadSort({handleSort, sortDirection, sortBy, expanded, queueExpanded}) {
    const blocks=[
        {name:'Name', value:'borrower_last'},
        {name:'Status', value:'lead_status'},
        {name:'State', value:'state'},
        {name:'Contacted', value:'last_contacted'},
        {name:'Assigned', value:'date_uploaded'},
        {name:'Call Amount', value:'amount_contacted'}
    ];
    const blocksShort=[
        {name:'Name', value:'borrower_last'},
        {name:'State', value:'state'},
        {name:'Contacted', value:'last_contacted'},
        {name:'Calls', value:'amount_contacted'}
    ];
    const blockQueue=[
        {name:'Name', value:'borrower_last'},
        {name:'Contacted', value:'last_contacted'},
    ];
    const renderSortIcon = (criteria) => {
        if (sortBy === criteria) {
          return sortDirection === 'asc' ? <span className="sortIcon"><FontAwesomeIcon icon={faArrowUp} /></span>: <span className="sortIcon"><FontAwesomeIcon icon={faArrowDown} /></span>;
        }
        return null;
    };
    return (
        <div className="sortWrapper">
            {expanded ? (
                <>
                    {blocks.map((item, index) => (
                        <div className="sortBlock" id={'sortBlock'+item.name} key={'sort'+index} onClick={() => handleSort(item.value)}>
                        <div className="sortBlockTitle">{item.name} {renderSortIcon(item.value)}</div>
                        </div>
                    ))}
                </>
            ) : queueExpanded ? (
                <>
                    {blockQueue.map((item, index) => (
                        <div className="sortBlock" id={'sortBlock'+item.name} key={'sort'+index} onClick={() => handleSort(item.value)}>
                        <div className="sortBlockTitle">{item.name} {renderSortIcon(item.value)}</div>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {blocksShort.map((item, index) => (
                        <div className="sortBlock" id={'sortBlock'+item.name} key={'sort'+index} onClick={() => handleSort(item.value)}>
                        <div className="sortBlockTitle">{item.name} {renderSortIcon(item.value)}</div>
                        </div>
                    ))}
                </>
            )}
            
        </div>
    );
}

export default LeadSort;


/*

<div className="sortWrapper">
            {blocks.map((item, index) => (
                item==='State' ? (
                    <div className="sortBlock" id={'sortBlock'+item} key={'sort'+index} onClick={()=>handleSort('state')}>
                        <div className="sortBlockTitle">{item}</div>
                    </div>
                ) : item==='Contacted' ? (
                    <div className="sortBlock" id={'sortBlock'+item} key={'sort'+index} onClick={()=>handleSort('last_contacted')}>
                        <div className="sortBlockTitle">{item}</div>
                    </div>
                ) : item==='Name' ? (
                    <div className="sortBlock" id={'sortBlock'+item} key={'sort'+index} onClick={()=>handleSort('borrower_last')}>
                        <div className="sortBlockTitle">{item}</div>
                    </div>
                ) : item==='Status' ? (
                    <div className="sortBlock" id={'sortBlock'+item} key={'sort'+index} onClick={()=>handleSort('lead_status')}>
                        <div className="sortBlockTitle">{item}</div>
                    </div>
                ) : item==='Assigned' ? (
                    <div className="sortBlock" id={'sortBlock'+item} key={'sort'+index} onClick={()=>handleSort('date_uploaded')}>
                        <div className="sortBlockTitle">{item}</div>
                    </div>
                ) : item==='Call Amount' ? (
                    <div className="sortBlock" id={'sortBlock'+item} key={'sort'+index} onClick={()=>handleSort('amount_contacted')}>
                        <div className="sortBlockTitle">{item}</div>
                    </div>
                ) :  (
                    <div className="sortBlock" id={'sortBlock'+item} key={'sort'+index}>
                        <div className="sortBlockTitle">{item}</div>
                    </div>
                )
                
            ))}
        </div>

        */