import React, { useState } from "react";
import '../../css/elements.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";



function FilterButton({ title, type, values, search, addToHolder,setSelectedOptions, setNameModule, selectedOptions }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

  
    const handleOptionClick = (option,search) => {
        console.log(option);
        console.log(search);
        setNameModule(true);
        setSelectedOption(option.lead_type);
        addToHolder(option,search);
    };
    return (
        <span className="filterButtonWrapper">
            <div className={`filterButton ${type==='solid' ? 'solidButton':'outlineButton' }`}>
                <div className="filterButtonSelectedItem" onClick={toggleDropdown}>
                    <span className="filterButtonTitle">{title}</span>
                    <span className="selectBoxIconWrapper"><FontAwesomeIcon className={`selectBoxIcon ${isOpen ? 'rotateIcon':''}`} icon={faChevronUp}/></span>
                </div>
                {isOpen && (
                    <div className="filterOptionWrapper">
                        {values[0].map((option, index) => {
                            const isChecked = selectedOptions.some(item => {
                                return (item.name || item.states || item.lead_type) === (option.states || option.name || option.lead_type || option.id);
                            });
                            //option.search=search;
                            return (
                                <div
                                    key={`filterButton${index}`}
                                    className={`multiSelectOption ${isChecked ? 'chosen' : ''}`}
                                    onClick={() => handleOptionClick(option, search)}
                                >
                                    <span className="QuilityCheckBoxWrapper" style={{verticalAlign: 'sub'}}>
                                        <input 
                                            type="checkbox" 
                                            className="quilityCheck" 
                                            onChange={(e)=> {console.log('')}} 
                                            checked={isChecked}
                                        />
                                    </span> 
                                    {option.name || option.states || option.lead_type || option.id}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div> 
        </span>
    );
}

export default FilterButton;
