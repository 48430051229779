import React from 'react';
import '../../css/workWindow.css';



function WorkPhone({title}) {
    
    return (
        <>aaaaa</>
    );
}

export default WorkPhone;