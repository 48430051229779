import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlaneTop, faImage, faClock, faFaceSmile, faBracketsCurly, faFileInvoice} from '@fortawesome/pro-regular-svg-icons';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { fetchWithDefaults } from '../../../config/fetchDefault';
import { fetchPdf } from '../../../utilities/messagingFunctions';
import { gatherConversation } from '../../../utilities/conversationFunctions';
import { formatPhoneNumber } from '../../../utilities/dashFunction';
import TextInputBar from './textBar';
import { useSelector } from 'react-redux';


function QueueConvo({expiration, setLoading, currentLead, from, textingNumbers, conversations, setConversations, currentLeadId, lead}) {

    const agentInfo=useSelector((state) => state.agent.userData);
    const [addConvo, setAddConvo] = useState(false);
    const texts=useSelector((state) => state.texts.texts);
    const [textsTemplates, setTextsTemplates] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [fromNumber, setFromNumber] = useState(false);
    const [theirNumbers, setTheirNumbers] = useState(false);
    const [newConvo,setNewConvo] = useState([]);
    const [toNumber, setToNumber] = useState(false);
    const [currentConvoId, setCurrentConvoId] = useState(false);
    const [convoDisplay, setConvoDisplay] = useState(1);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [attachedImage, setAttachedImage] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [textTemplates, setTextTemplates] = useState(false);
    const [variables, setVariables] = useState(false);
    const fileInputRef = useRef(null);
    const [textBoxHeight, setTextBoxHeight] = useState(from==='leadCard' ? 90 : 210);
    const keysWithValue = [];
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTexts, setFilteredTexts] = useState([]);
    const varsToUse=['pdf','borrower_first','borrower_last', 'email', 'address','city','state','zip','mortgage','borrower_occupation','hobby','lender','loan_type','motivation','payment','policy','policy_number','policy_plan','tobacco','height','weight','borrower_age','borrower_dob','borrower_sex','co_borrower_first','co_borrower_last','co_borrower_age','co_borrower_dob','co_borrower_email','co_borrower_occupation'];
    const openvars=()=>{
        setVariables(!variables);
    }
    const keysandvalues=(leadInfo)=>{
        const custom= JSON.parse(lead.custom_fields);
        for (const key in custom) {
            if (custom[key] !== null && custom[key] !== undefined) {
                const newkey=key.replace('borrower','Lead');
                keysWithValue.push({key:newkey,value:custom[key]});
            }
        }
        for (const key in leadInfo) {
            if (leadInfo[key] !== null && leadInfo[key] !== undefined && varsToUse.includes(key)) {
                const newkey=key.replace('borrower','Lead');
                keysWithValue.push({key:newkey,value:leadInfo[key]});
            }
        }
    }
    keysandvalues(lead);
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
     
        const formData = new FormData();

        formData.append('file', file);

        try {
            const response = await fetch(`https://v2.ninjadialer.com:3333/sendImage`, {
                method: 'POST',
                body: formData,
            });
            const jsonData = await response.json();
            setAttachedImage(`https://files.thehivelms.com/mmsImages/${jsonData[0].file}`)
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
  
    const handleImageClick = () => {
        fileInputRef.current.click(); 
    };
    const handleChange = (input) => {
        setInputValue(input);
    };
   
    const filterEmptyValues = (element1, element2, element3) => {
        const nonEmptyValues = [];
        let setMe="";
      
        if (element1 !== null && element1 !== undefined && element1 !== '') {
            nonEmptyValues.push(element1);
            setMe=element1;
        }
      
        if (element2 !== null && element2 !== undefined && element2 !== '') {
            nonEmptyValues.push(element2);
            if(setMe!=''){
                setMe=element2;
            }
        }
      
        if (element3 !== null && element3 !== undefined && element3 !== '') {
            nonEmptyValues.push(element3);
            if(setMe!=''){
                setMe=element3;
            }
        }
        return nonEmptyValues;
    };
    
    const sendText= async (key)=>{
        let toNew=toNumber;
        let fromNew=fromNumber;
        if (typeof fromNew === 'object') {
            fromNew=fromNumber.number;
        }
        if(key){
            toNew=atob(currentConvoId).split(':')[1];
            fromNew=atob(currentConvoId).split(':')[0];
        }
        if (typeof toNew !== 'string') {
            toNew='+1'+toNew;
        }
        try {
            const textToSend = inputValue.replace(/<br>/g, '\n');
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    to: toNew,
                    from: fromNew,
                    body: textToSend,
                    team:agentInfo.teamId,
                    lead:currentLeadId,
                    image:attachedImage
                }) 
            };
            
            const response = await fetchWithDefaults('/sendText', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status===true){
                const theConvo=btoa(fromNew+':'+toNew);
                if(conversations.length > 0){

                    //const foundIndex = conversations.findIndex(obj => Object.keys(obj)[0] === theConvo);
                    const foundIndex = conversations.findIndex(obj => {
                        const key = Object.keys(obj)[0];
                        return key === theConvo;
                    });
                    if (foundIndex !== -1) {
                        conversations[foundIndex][theConvo] = [jsonData, ...conversations[foundIndex][theConvo]];
                        setConversations(conversations);
                        setInputValue('');
                        setAttachedImage(false);
                    }
                }
                else{
                    const newCovo = [{[theConvo]: [jsonData]}];
                    setConversations(newCovo)
                    setInputValue('');
                    setAttachedImage(false)
                }
                
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleHeight = (height) => {
        if(from==='leadCard'){
            if(height !== 50){
                setTextBoxHeight(90 + height);
            }
            else{
                setTextBoxHeight(140);
            }
        }
        else{
            if(height !== 50){
                setTextBoxHeight(160 + height);
            }
            else{
                setTextBoxHeight(210);
            }
        }
    };
    function formatConvoId(inputString) {
        // Split the input string based on the colon
        const numbersArray = inputString.split(':');
      
        // Format each number in the array
        const formattedNumbers = numbersArray.map((number) => {
            const cleanedNumber = number.replace(/\D/g, ''); // Remove non-numeric characters
            const formatted = `(${cleanedNumber.slice(1, 4)}) ${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7)}`;
            return formatted;
        });
      
        // Join the formatted numbers with ' & ' between them
        const result = formattedNumbers.join(' & ');
      
        return result;
    }
    const handleCursorPosition=(pos)=>{
        setCursorPosition(pos);
    }
    const handleClick = (value,key) => {
        if(key==='pdf'){
            fetchPdf(value,setAttachedImage, setVariables, setLoading);
        }
        else{
            const updatedValue = inputValue.slice(0, cursorPosition) + value + inputValue.slice(cursorPosition);
            handleInputChange(updatedValue);
            setCursorPosition(cursorPosition + value.length);
            setVariables(!variables);
        }
    };
    useEffect(() => {
        setFilteredTexts(texts)
        const numbers=filterEmptyValues(currentLead.borrower_cell,currentLead.borrower_home, currentLead.borrower_work);
        setTheirNumbers(numbers)
        setToNumber(numbers[0]);
        setFromNumber(textingNumbers[0]);
        setNewConvo([]);
        setConvoDisplay(1);
        if(conversations.length > 0){
            Object.keys(conversations[0]).map((key) => (
                setCurrentConvoId(key)
            ))
        }
    }, [currentLead,conversations]);
    useEffect(() => {
        gatherConversation(currentLead.lead_id,setConversations)
    }, [currentLead]);
    
    const handleRemoveImage=()=>{
        setAttachedImage(false);
    }
    const handleToChange = (event) => {
        // Update the state with the selected value
        setToNumber(event.target.value);
    };
    const handleFromChange = (event) => {
        // Update the state with the selected value
        setFromNumber(event.target.value);
    };
    const showConvo=(index, convo)=>{
        setConvoDisplay(index);

        Object.keys(convo).map((key) => (
            setCurrentConvoId(key)
        ))
    }
    const handleEmojiPicker=()=>{
        setShowEmojiPicker(!showEmojiPicker);
    }
    const handleEmojiSelect = (emoji) => {
        setInputValue(inputValue+emoji.native);
    };
    const handleTextTemplates=()=>{
        setTextTemplates(!textTemplates);
    }
    const handleInputChange = (content) => {
        setInputValue(content);
    };
    const addTemplate=(template,images)=>{
        openTemplates();
        if(images){
            if(!images.includes('http')){
                const newImageLink='https://files.thehivelms.com/'+images;
                setAttachedImage(newImageLink);
            }
            else{
                setAttachedImage(images);
            }
        }
        let modifiedContent = Object.entries(currentLead).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '';
            return content.replace(new RegExp(`{${key.replace('borrower','lead')}}`, 'g'), `${replacement}`);
        }, template);
        modifiedContent = modifiedContent.replace(/\\n/g, '<br>');
        if(modifiedContent.includes('{agent_first}')){
            modifiedContent = modifiedContent.replace(/{agent_first}/g, agentInfo.firstName);
        }
        if(modifiedContent.includes('{agent_last}')){
            modifiedContent = modifiedContent.replace(/{agent_last}/g, agentInfo.lastName);
        }
        if(modifiedContent.includes('{lead_pdf}')){
            modifiedContent = modifiedContent.replace(/{lead_pdf}/g, '');
            fetchPdf(lead.pdf, setAttachedImage, setVariables, setLoading);
        }

        const custom= JSON.parse(lead.custom_fields);
        let modifiedContent2 = Object.entries(custom).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '';
            return content.replace(new RegExp(`{${key.replace('borrower','lead')}}`, 'g'), `${replacement}`);
        }, modifiedContent);
        
        handleTextTemplates();
        handleInputChange(inputValue+modifiedContent2);
    }

    const openTemplates=()=>{
        setTextsTemplates(!textsTemplates);
    }
    const filterTexts = (searchTerm) => {
        const filtered = texts.filter(text => text.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredTexts(filtered);
    };
    const toggleNewConvo= ()=>{
        setAddConvo(true);
    }
    
    return (
        
        <div className="queueConvoWrapper" style={{height:`${from==='leadCard' ? 'calc(100% - 40px)':'calc(100% - 50px)' }`, width: `${from==='leadCard' ? '100%':'90%' }`}}>
            
            <div className="queueConvoHeader">
                
            {conversations.length < 1 && (
                 <div className="queueConversationButton">New Conversation</div>
            )}
                {conversations && (
                    <>
                        {conversations.map((convo, index) => (
                            <div key={`conversationBox${index}`} className={`queueConversationButton ${convoDisplay===(index+1) ? '' : 'selected'}`} onClick={()=>showConvo(index+1,convo)}>Convo #{index+1}</div>
                        ))}
                        {conversations.length > 0 && addConvo=== false && (
                            <div className="addNewConvo" onClick={()=>toggleNewConvo()}>+ Convo</div>
                        )}
                    </>
                )}
            </div>
            {conversations.length < 1 ? (
                <div className="queueConversationWrapper">
                    <div className="conversationTitle">
                        <span className="fromNumWrapper">
                            <select className="numberSelectBox" value={fromNumber} onChange={handleFromChange}>
                                {textingNumbers && (
                                    textingNumbers.map((number, index) => ( 
                                        <option key={`textingNumber${index}`} value={number.number}>{number.friendlyName}</option>
                                    ))
                                )}
                            </select>
                        </span>
                        &
                        <span className="toNumWrapper">
                            <select className="numberSelectBox" value={toNumber} onChange={handleToChange}>
                                {theirNumbers && (
                                    theirNumbers.map((number, index) => ( 
                                        <option key={`theirNumber${index}`}>{formatPhoneNumber(number.toString())}</option>
                                    ))
                                )}
                            </select>
                        </span>
                    </div>
                    <div className="queueConversationInnerWrapper" style={{ height: `calc(100% - ${textBoxHeight}px` }}>
                    {newConvo && newConvo.map((message, index) => ( 
                        <div className={`messageWrapper message${message.direction}`} key={`newConvo${index}`}>
                            <div className={`convoBubble background${message.direction}`}>
                            {message.image && message.image != 0 && (
                                <div className="sentImageWrapper">
                                    <img src={message.image} alt="Preview" className="sendingImage"/>
                                </div>
                            )}
                            {message.message}</div>
                        </div>
                    ))}
                    </div>
                    <div className="sendingImageWrapper">
                        {attachedImage && (
                            <div className="innerImageWrapper">
                                <div className="removeImage" onClick={()=>handleRemoveImage()}>x</div>
                                <img src={attachedImage} alt="Preview" className="sendingImage"/>
                            </div>
                        )}
                    </div>
                   
                    {parseInt(expiration) > 0  || !expiration ? (
                        <div className="sendBar">
                            <div className="inputBarWrapper">
                                <TextInputBar handleHeight={handleHeight} handleCursorPosition={handleCursorPosition} inputValue={inputValue} handleChange={handleChange}/>
                            </div>
                            <div className="sendButton" onClick={()=>sendText()}><FontAwesomeIcon icon={faPaperPlaneTop} /></div>
                            <div className="sendBarOptions">
                            <div className="optionButton"><FontAwesomeIcon icon={faFaceSmile} onClick={() => handleEmojiPicker()}/></div>
                            <div className="optionButton " onClick={handleImageClick}><FontAwesomeIcon icon={faImage} />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            </div>
                            <div className="optionButton"><FontAwesomeIcon icon={faClock} /></div>
                            <div className="optionButton" onClick={()=>openvars()}><FontAwesomeIcon icon={faBracketsCurly} /></div>
                            {variables && (
                                <div className="variableWindow">
                                    <div className="innerWrapper">
                                        {keysWithValue.map((variable, index) => (
                                            <div class="leadInfoLine" key={`variable${index}`}><span class="dataFieldTitle">{variable.key.replace('_',' ')}</span><span class="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{cursor:'pointer'}}>Click To Use</span></div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className="optionButton" onClick={()=>openTemplates()}><FontAwesomeIcon icon={faFileInvoice} /></div>
                                {textsTemplates && (
                                    <div className="variableWindow">
                                        <input
                                            type="text"
                                            placeholder="Search templates..."
                                            className="templateSearch"
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                                filterTexts(e.target.value);
                                            }}
                                        />
                                        <div className="innerWrapper">
                                            {filteredTexts.map((text, index) => (
                                                <div className="leadInfoLine" key={`texttemplate${index}`}>
                                                    <span className="dataFieldTitle">{text.name}</span>
                                                    <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : expiration > -1209600 ? (
                        <div className="expiredText">You can no longer text this lead. To resume messaging, call them and ask them to text you back to "continue working" together. You have {Math.round((expiration + 1209600)/86400)} days left to do this before texting is permanently disabled</div>
                    ) : (
                        console.log(expiration),
                        <div className="expiredText">Your ability to message this lead has been removed.</div>
                    )}
                    {showEmojiPicker && (
                        <div className="emojiWrapper" style={{ position: 'absolute',bottom: '100px'
                        }} >
                        <Picker
                            data={data} onEmojiSelect={handleEmojiSelect}
                        />
                        </div>
                    )}
                    
                </div>
            ) : addConvo=== true &&(
                <div className="queueConversationWrapper">
                    <div className="conversationTitle">
                        <span className="fromNumWrapper">
                            <select className="numberSelectBox" value={fromNumber} onChange={handleFromChange}>
                                {textingNumbers && (
                                    textingNumbers.map((number, index) => ( 
                                        <option key={`textingNumber${index}`} value={number.number}>{number.friendlyName}</option>
                                    ))
                                )}
                            </select>
                        </span>
                        &
                        <span className="toNumWrapper">
                            <select className="numberSelectBox" value={toNumber} onChange={handleToChange}>
                                {theirNumbers && (
                                    theirNumbers.map((number, index) => ( 
                                        <option key={`theirNumber${index}`}>{formatPhoneNumber(number.toString())}</option>
                                    ))
                                )}
                            </select>
                        </span>
                    </div>
                    <div className="queueConversationInnerWrapper" style={{ height: `calc(100% - ${textBoxHeight}px` }}>
                    {newConvo && newConvo.map((message, index) => ( 
                        <div className={`messageWrapper message${message.direction}`} key={`newConvo${index}`}>
                            <div className={`convoBubble background${message.direction}`}>
                            {message.image && message.image != 0 && (
                                <div className="sentImageWrapper">
                                    <img src={message.image} alt="Preview" className="sendingImage"/>
                                </div>
                            )}
                            {message.message}</div>
                        </div>
                    ))}
                    </div>
                    <div className="sendingImageWrapper">
                        {attachedImage && (
                            <div className="innerImageWrapper">
                                <div className="removeImage" onClick={()=>handleRemoveImage()}>x</div>
                                <img src={attachedImage} alt="Preview" className="sendingImage"/>
                            </div>
                        )}
                    </div>
                    <div className="sendBar">
                        <div className="inputBarWrapper">
                            <TextInputBar handleHeight={handleHeight} handleCursorPosition={handleCursorPosition} inputValue={inputValue} handleChange={handleChange}/>
                        </div>
                        <div className="sendButton" onClick={()=>sendText()}><FontAwesomeIcon icon={faPaperPlaneTop} /></div>
                        <div className="sendBarOptions">
                        <div className="optionButton"><FontAwesomeIcon icon={faFaceSmile} onClick={() => handleEmojiPicker()}/></div>
                        <div className="optionButton " onClick={handleImageClick}><FontAwesomeIcon icon={faImage} />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                            />
                        </div>
                        <div className="optionButton"><FontAwesomeIcon icon={faClock} /></div>
                        <div className="optionButton" onClick={()=>openvars()}><FontAwesomeIcon icon={faBracketsCurly} /></div>
                        {variables && (
                            <div className="variableWindow">
                                <div className="innerWrapper">
                                    {keysWithValue.map((variable, index) => (
                                        <div class="leadInfoLine" key={`variable${index}`}><span class="dataFieldTitle">{variable.key.replace('_',' ')}</span><span class="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{cursor:'pointer'}}>Click To Use</span></div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="optionButton" onClick={()=>openTemplates()}><FontAwesomeIcon icon={faFileInvoice} /></div>
                            {textsTemplates && (
                                <div className="variableWindow">
                                    <input
                                        type="text"
                                        placeholder="Search templates..."
                                        className="templateSearch"
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            filterTexts(e.target.value);
                                        }}
                                    />
                                    <div className="innerWrapper">
                                        {filteredTexts.map((text, index) => (
                                            <div className="leadInfoLine" key={`texttemplate${index}`}>
                                                <span className="dataFieldTitle">{text.name}</span>
                                                <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {showEmojiPicker && (
                        <div className="emojiWrapper" style={{ position: 'absolute',bottom: '100px'
                        }} >
                        <Picker
                            data={data} onEmojiSelect={handleEmojiSelect}
                        />
                        </div>
                    )}
                    
                </div>
            )}
            {conversations && (
                conversations.map((convo, index) => ( 
                    <div className={`queueConversationWrapper ${convoDisplay===(index+1) ? '' : 'hidden'}`} key={`coversation`+(index+1)}>
                        {Object.keys(convo).map((key) => (
                            <span key={`anotherConvo${key}`}>
                                <div className="conversationTitle">{formatConvoId(atob(key))}</div>

                                <div className="queueConversationInnerWrapper" style={{ height: `calc(100% - ${textBoxHeight}px` }}>
                                    {convo[key].map((message, index) => ( 
                                        <div key={`specConfo${index}`} className={`messageWrapper message${message.direction}`}>
                                            <div className={`convoBubble background${message.direction}`}>
                                                {message.image && message.image != 0 && (
                                                    <div className="sentImageWrapper">
                                                        <img src={message.image} alt="Preview" className="sendingImage"/>
                                                    </div>
                                                )}
                                                {message.message}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                           </span>
                        ))}
                        <div className="sendingImageWrapper">
                            {attachedImage && (
                                <div className="innerImageWrapper">
                                    <div className="removeImage" onClick={()=>handleRemoveImage()}>x</div>
                                    <img src={attachedImage} alt="Preview" className="sendingImage"/>
                                </div>
                            )}
                        </div>

                        {parseInt(expiration) > 0 || !expiration ? (
                        <div className="sendBar">
                            <div className="inputBarWrapper">
                                <TextInputBar handleHeight={handleHeight} handleCursorPosition={handleCursorPosition} inputValue={inputValue} handleChange={handleChange}/>
                            </div>
                            <div className="sendButton" onClick={()=>sendText()}><FontAwesomeIcon icon={faPaperPlaneTop} /></div>
                            <div className="sendBarOptions">
                            <div className="optionButton"><FontAwesomeIcon icon={faFaceSmile} onClick={() => handleEmojiPicker()}/></div>
                            <div className="optionButton " onClick={handleImageClick}><FontAwesomeIcon icon={faImage} />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            </div>
                            <div className="optionButton"><FontAwesomeIcon icon={faClock} /></div>
                            <div className="optionButton" onClick={()=>openvars()}><FontAwesomeIcon icon={faBracketsCurly} /></div>
                            {variables && (
                                <div className="variableWindow">
                                    <div className="innerWrapper">
                                        {keysWithValue.map((variable, index) => (
                                            <div class="leadInfoLine" key={`variable${index}`}><span class="dataFieldTitle">{variable.key.replace('_',' ')}</span><span class="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{cursor:'pointer'}}>Click To Use</span></div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className="optionButton" onClick={()=>openTemplates()}><FontAwesomeIcon icon={faFileInvoice} /></div>
                                {textsTemplates && (
                                    <div className="variableWindow">
                                        <input
                                            type="text"
                                            placeholder="Search templates..."
                                            className="templateSearch"
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                                filterTexts(e.target.value);
                                            }}
                                        />
                                        <div className="innerWrapper">
                                            {filteredTexts.map((text, index) => (
                                                <div className="leadInfoLine" key={`texttemplate${index}`}>
                                                    <span className="dataFieldTitle">{text.name}</span>
                                                    <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : expiration > -1209600 ? (
                        <div className="expiredText">You can no longer text this lead. To resume messaging, call them and ask them to text you back to "continue working" together. You have {Math.round((expiration + 1209600)/86400)} days left to do this before texting is permanently disabled</div>
                    ) : (
                        <div className="expiredText">Your ability to message this lead has been removed.</div>
                    )}

                    </div>
                ))
            )}
        </div>
    );   
}
// <input type="text" className="messageInput" />
export default QueueConvo;
