import React, {} from 'react';
import '../../css/leaderBoard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faUserLarge } from '@fortawesome/pro-regular-svg-icons'



function BoardSlot({data, place}) {

    return (
        <div className="leaderWrapper">
            {place=== 0 || place=== 1 || place=== 2 ? (
                <div className="innerLeaderWrapper">
                    <span className="leaderMedal"><span className={`innerLeaderMedal place${place}`} style={{fontSize:'20px'}}><FontAwesomeIcon icon={faMedal} /></span></span>
                    <span className="leaderAvatar"><span className="innerLeaderAvatar"><FontAwesomeIcon icon={faUserLarge} /></span></span>
                    <span className="leaderName">{data.first_name} {data.last_name}</span>
                    <span className="leaderCount">{data.count}</span>
                </div>
            ) : (
                <div className="innerLeaderWrapper">
                    <span className="leaderMedal"><span className="innerLeaderMedal">{(place+1)}</span></span>
                    <span className="leaderAvatar"><span className="innerLeaderAvatar"><FontAwesomeIcon icon={faUserLarge} /></span></span>
                    <span className="leaderName">{data.first_name} {data.last_name}</span>
                    <span className="leaderCount">{(data.count)}</span>
                </div>
            )}
        </div>
    );
}

export default BoardSlot;
