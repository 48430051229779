import React, {useState} from 'react';
import '../../css/conversation.css';
import { SuccessAlert } from '../successAlert';
import ContactInfo from '../callQueue/elements/sections/contactInfo';
import HomeInfo from '../callQueue/elements/sections/homeInfo';
import HealthInfo from '../callQueue/elements/sections/healthInfo';
import DispositionBlock from '../callQueue/elements/sections/dispositions';
import TagComponent from '../tags/tagComponent';
import LeadCardTopBar from '../callQueue/elements/sections/leadCardTopBar';
import LeadCardTags from '../leads/tags';
import { removeTagFromLead } from '../../utilities/tagFunctions';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';
import Qualifier from '../qualifier/qualifier';
import EditEvent from '../callQueue/elements/sections/editEvent';
import AddEvent from '../callQueue/elements/sections/addEvent';
import WorkflowInfo from '../workflow/workflowInfo';


function LeadData({lead,makeDial, handleLeadData,setNewAppt,newAppt}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const agentInfo=useSelector((state) => state.agent.userData);
    
    const [successTrigger, setSuccessTrigger] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [qualifier, setQualifier] = useState(false);
    const [calendar, setCalendar] = useState(false);
    const [newEvent, setNewEvent] = useState(false);
    const [date, setDate] = useState(false);
    const [time, setTime] = useState(false);
    const [event, setEvent] = useState(false);
    const newEventPackage=[{setNewEvent:setNewEvent, setTime:setTime, setDate:setDate}]
    const calendarUsing= async (id)=>{
        setCalendar(id);
    }
    const handleSetQualifier = (link) => {
        setQualifier(!qualifier);
    };
    const updateStatusName=(name)=>{
        handleLeadData('status_name',name);
        setSuccessTrigger(true);
    }
    const removeTag= async (tagIdToRemove)=>{
        const tagRemoved=await removeTagFromLead(tagIdToRemove, agentId, lead[0].lead_id);
        if(tagRemoved){
            const updatedTags = selectedTags.filter(tag => tag.id !== tagIdToRemove);
            // Update the state with the filtered array
            setSelectedTags(updatedTags);
        }
    }
    const setAppt= async (event,video)=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    team: agentInfo.teamId,
                    leadId:lead[0].lead_id,
                    event:event,
                    video:video
                }),
            };
            const response = await fetchWithDefaults('/setAppointment', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setNewAppt(jsonData.event)
            setNewEvent(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <div className="quilityParentBlockContent">
            <SuccessAlert message={'Status Changed'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger}/>
            {lead && lead.map((leadItem, index) => (
                <span key={`leadDataBlockitem${index}`} style={{textAlign: 'left'}}>
                    <div className="leadName" key={`leadDataItem${index}`} style={{textIndent: 'unset'}}>{leadItem.borrower_first} {leadItem.borrower_last}</div>
                    {leadItem.status_name && (
                        <span className="statusName">{leadItem.status_name}</span>
                    )}
                    {leadItem.wf_name && (
                        <WorkflowInfo type='workflow' title={leadItem.wf_name} workflowId={leadItem.wf_id} hopperId={leadItem.wf_hopper_id} />
                    )}
                    {leadItem.convoFlowName && (
                        <WorkflowInfo type='conversation' title={leadItem.convoFlowName} workflowId={leadItem.pathway} hopperId={leadItem.wf_hopper_id} />
                    )}
                    <div>
                        <div style={{position: 'absolute',right: '3px',marginTop: '-30px'}}><TagComponent selectedTags={selectedTags} setSelectedTags={setSelectedTags} leadId={leadItem.lead_id}/></div>
                        <LeadCardTags tagIds={leadItem.tag_ids} leadId={leadItem.lead_id}/>
                        {selectedTags.length > 0 && (
                            selectedTags.map((tag, index) => (
                                <span className="tagWrapper" key={`taggedLead${tag.id}`}>
                                    {tag.name}
                                    <span className="removeTag solidQuilityDestructiveButton" onClick={()=>removeTag(tag.id)}>x</span>
                                </span>
                            ))
                        )}
                    </div>
                    <LeadCardTopBar setQualifier={handleSetQualifier} qualifier={qualifier} calendarUsing={calendarUsing} newAppt={newAppt} setNewAppt={setNewAppt} newEventPackage={newEventPackage} setEvent={setEvent} leadData={lead[0]} />
                </span>
            ))}
            
            {lead.length > 0 && lead[0].pathway && (
                null
            )}
            {lead && (
                qualifier ? (
                    <Qualifier handleLeadData={handleLeadData} handleSetQualifier={handleSetQualifier} lead={lead} agentId={agentId}/>
                ) : (
                    <>
                        <ContactInfo handleLeadData={handleLeadData} makeDial={makeDial} leadData={lead}  />
                        <HomeInfo handleLeadData={handleLeadData} leadData={lead} />
                        <HealthInfo handleLeadData={handleLeadData} leadData={lead} />                   
                        <DispositionBlock from="dash" leadId={lead[0].lead_id} updateStatusName={updateStatusName}/>
                    </>
                )
            )}
            {event &&(
                <EditEvent setEvent={setEvent} calendar={calendar} event={event} />
            )}
            {newEvent &&(
                <AddEvent setAppt={setAppt} calendar={calendar} setNewEvent={setNewEvent} agentTimezone={agentInfo.timezone} date={date} time={time} currentLead={lead[0]} />
            )}
        </div>
    );
}

export default LeadData;

/*
<LeadCardTopBar setQualifier={handleSetQualifier} qualifier={qualifier} calendarUsing={calendarUsing} newAppt={newAppt} setNewAppt={setNewAppt} newEventPackage={newEventPackage} setEvent={setEvent} leadData={lead[0]} />
*/