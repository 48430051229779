import React, { useState } from "react";
import '../../css/templates.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalkingArrowLoopLeft } from "@fortawesome/pro-regular-svg-icons";
import SelectBox from "../elements/selectBox";
import DataLine from "../elements/dataLine";
import { useSelector } from 'react-redux';



function FollowUp({ data,formatDate }) {
    const statuses=useSelector((state) => state.status.status);
    const workflows=useSelector((state) => state.workflows.workflows);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const [editingTemplate, setEditTemplate] = useState(false);
    const [addScript, setAddScript] = useState(false);
    const [followUps, setFollowUps] = useState(data[0]);

    let noResult=false;
    if(followUps.status==false){
        noResult=true;
    }
    
    const updateLeadStatus = async (status, lead, index) => {
        
        const foundWorkflow = statuses.find(workflow => workflow.id === status);
        const exitWorkflow = [];
        const workflowInfo = workflows.find(workflow => {
            if (workflow.exitConditions != undefined) {
                if(workflow.exitConditions.includes(foundWorkflow.id)){
                    exitWorkflow.push(workflow.id);
                };
            } 
        });
        
    };
  
    const outOfDate=(last)=>{
        let current=currentTimestamp - last;
        let period=Math.round(current/86400);
        return period;
    } 
      
    const setUpStatusUpdate=(status,options)=>{
        updateLeadStatus(status.id, options.lead, options.index);
    }
    return (
        <div className="outerWrapper" style={{textAlign:'center'}}>
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">
                    <FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft} className="colorIcon" />
                </span>
                Follow-Ups
            </span>


            <div className={`frontPageQuilityParentBlockWrapper ${addScript ? 'closeMainBlock' : editingTemplate ? 'closeMainBlock' : ''}`} style={{width: '70%'}}>
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">Leads That Need a Follow Up</div>
                </div>
                <div className="quilityParentBlockContent">
                    {followUps.length < 1 ? (
                        <span className="noResults">You are all caught up!</span>
                    ) : (
                        noResult ? (
                            null
                        ) : (
                            followUps.map((lead, index) => (
                                <div className="quilityNestedBlockWrapper " key={`followUp${index}`} style={{marginTop: '25px'}}>
                                    <div class="quilityNestedBlockHeader">
                                        <div class="quilityParentBlockTitle">{lead.borrower_first} {lead.borrower_last}</div>
                                    </div>
                                    <div className="infoWrapper">
                                        <div className="innerContentWrapper">
                                            <DataLine title="Appointment Date"  value={formatDate(lead.app_date)}/>
                                            <DataLine title="Last Status Change" value={formatDate(lead.last_change)}/>
                                            <DataLine title="Current Status" value={lead.name}/>
                                            <DataLine title="Day's Out of Date" value={outOfDate(lead.app_date)}/>
                                            <SelectBox handleChange={setUpStatusUpdate} title="Lead Status" options={statuses} passBack={{lead:lead.app_lead,index:index}}/>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                    )}
                </div>
                <div className="blockFooter">
                    <div
                        className="buttonWrapper"
                        style={{ marginTop: " 5px", marginLeft: "10px" }}
                    >
                        <div className="dataButtonBlock">Follow Ups: {followUps.length}</div>
                        <div className="actionButtonBlock" style={{width: '150px'}}>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default FollowUp;