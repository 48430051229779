import React, { useState } from "react";
import '../../css/workflow.css';
import DraggableElement from './draggingElement';

function WorkflowSpecTime() {
    const [open, setOpen] = useState(false);
    const openUp= ()=>{
        
        setOpen(!open);
    }
    const timeSlots = [];
    const startTime = new Date();
    startTime.setHours(9, 0, 0, 0); // Set the start time to 9 AM

    for (let i = 0; i <= 43; i++) {
        // 36 slots for 15-minute intervals from 9 AM to 9 PM
    const time = new Date(startTime.getTime() + i * 15 * 60 * 1000);
    const formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

    timeSlots.push({
      name: formattedTime,
      id: formattedTime.replace(/\s/g, ''), // Remove spaces for unique IDs
    });
    }
   
    
    
    return (
        <div className="workflowBuilderSelectionBoxWrapper" >
            <div className="workflowBuilderSelectionBoxHeader" onClick={()=>openUp()}>
                Send at Specific Time
            </div>
            <div className={`workflowBuilderSelectionContent ${open && 'expandWorkflowBlock'}`}>
                {timeSlots && timeSlots.map((item, index) => (    
                    <DraggableElement name={item.name} id={item.id} key={`timeSlot${index}`} type="Wait until the next time it's"/>
                ))}
            </div>
        </div>
    );
}

export default WorkflowSpecTime;

