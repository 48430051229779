import { fetchWithDefaults } from '../config/fetchDefault';
const unixTommddyyyy = (timestamp) => {
    
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;

};
const timestampConvert = (inputDate) => {
    if (inputDate === null) {
        return 'Never Contacted';
    }
    const date = new Date(inputDate);

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = ((date.getHours() % 12) || 12).toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'pm' : 'am';

    const formattedDate = `${month}/${day} @ ${hours}:${minutes}${ampm}`;

    return formattedDate;
};
  
const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/^\+1/, '');

    // Remove non-numeric characters
    const numericOnly = cleaned.replace(/\D/g, '');
  
    // Check if the numeric part has at least 10 digits
    if (numericOnly.length >= 10) {

      return `(${numericOnly.substring(0, 3)}) ${numericOnly.substring(3, 6)}-${numericOnly.substring(6, 10)}`;
    }
  
    // If the numeric part doesn't have at least 10 digits, return the original input
    return phoneNumber;
};
const unixTommddhia = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);

    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${
    date.getDate().toString().padStart(2, '0')
    } ${hours.toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')} ${date.getHours() >= 12 ? 'pm' : 'am'}`;

    return formattedDate;
};
const getConvo = async (lead, setConvos, setActiveConvo) =>{
    try {
        const response = await fetchWithDefaults(`/getConversationForLead?lead=${lead}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData.status===true){
            //setConversations([newItem, ...conversation])
        }
        if(jsonData.length > 0){
            setConvos(jsonData);
            setActiveConvo(Object.keys(jsonData[0])[0]);
        }

    } catch (error) {
        console.error('Error fetching data:', error);
    }

}
const getLeadOrders = async (setPastOrders, agent) => {
    try {
        const response = await fetchWithDefaults(`/getLeadOrders?agent=${agent}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setPastOrders(jsonData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
export { unixTommddyyyy,timestampConvert,formatPhoneNumber,unixTommddhia, getConvo, getLeadOrders };