import React from "react";
import '../../css/workflow.css';
import DraggableElement from './draggingElement';

function WorkflowExitCondition({ status }) {
    
    return (
        <>
            <div className="workflowBuilderSelectionBoxWrapper" >
                <div className="workflowBuilderSelectionBoxHeader">
                    Lead Status Changes To...
                </div>
                <div className={`workflowBuilderSelectionContent expandWorkflowBlock`}>
                    {status && status.map((item, index) => (    
                        <DraggableElement name={item.name} id={item.id} type="Status Lead"/>
                    ))}
                </div>
            </div>
            <div className="workflowBuilderSelectionBoxWrapper" >
                <div className="workflowBuilderSelectionBoxHeader">
                    Lead Responds
                </div>
                <div className={`workflowBuilderSelectionContent expandWorkflowBlock`} style={{height:'fit-content'}}>
                    <DraggableElement name='Lead Texts Back' id="text response" type=""/>
                </div>
            </div>
        </>

    );
}

export default WorkflowExitCondition;

