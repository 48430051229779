import React, { useState, useEffect, useRef } from 'react';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector } from 'react-redux';

function ContactField({leadData, handleLeadData, field}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const numbers=['borrower_cell','borrower_home','borrower_work','co_borrower_home','co_borrower_cell','co_borrower_work','borrower_age','co_borrower_age']
    const [myLeadData, setMyLeadData] = useState(leadData[0][field]);
    const [editMode, setEditMode] = useState(false);
    const [editedValue, setEditedValue] = useState('');
    const inputRef = useRef(null);
    const handleEdit = (initialValue) => {
        setEditMode(true);
        setEditedValue(initialValue);
    };
    
    const handleBlur = (field) => {
        setEditMode(false);
        saveEditedValue(field, myLeadData);
        handleLeadData(field, myLeadData)
        updateLeadData(field, myLeadData)
        setEditedValue('');
    };
    
    const saveEditedValue = (field, value) => {
        setMyLeadData(value)
        setEditedValue(value);
        updateLeadData(field, value);
        handleLeadData(field, value)
    };
    function formatPhoneNumber(phoneNumber) {
        // Remove any non-digit characters
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        
        // Format the number
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        
        // Return original if it doesn't match the format
        return phoneNumber;
    }
    
    const updateLeadData = async (field, value) => {
        try {        
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    lead:leadData[0].lead_id,
                    column:field,
                    value:value
                }) 
            };
            const response = await fetchWithDefaults('/updateLead', requestOptions);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
           
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const formatField=(inputString)=>{
        if (inputString.includes('co_borrower_')) {
            // Remove "borrower_" and capitalize the remaining string
            return inputString.replace('co_borrower_', 'Co Lead ').replace(/\b\w/g, (c) => c.toUpperCase());
       
        } else if (inputString.includes('borrower_')) {
        // Remove "co_borrower_" and capitalize the remaining string
            return inputString.replace('borrower_', 'Lead ').replace(/\b\w/g, (c) => c.toUpperCase());
        } else if (inputString.includes('_')) {
        // Capitalize the entire string
            return inputString.replace('_', ' ').replace(/\b\w/g, (c) => c.toUpperCase());
        } else {
        // Capitalize the entire string
            return inputString.replace(/\b\w/g, (c) => c.toUpperCase());
        }
    }
    useEffect(() => {
        if (editMode && inputRef.current) {
          inputRef.current.focus();
        }
    }, [editMode]);
    return (
        leadData && (
            <div className="leadInfoLine"><span className="dataFieldTitle">{formatField(field)+": "} </span>
                <span className="leadData"  onClick={() =>handleEdit(leadData[0][field])}> 
                    {editMode ? (
                        <input
                            className="qualifierFieldInput"
                            type="text"
                            value={editedValue}
                            onChange={(e) => saveEditedValue(field,e.target.value)}
                            onBlur={() => handleBlur(field)}
                            ref={inputRef}
                        />
                    ) : (
                        <>{
                            numbers.includes(field) ? (
                                <>
                                    {leadData[0][field] !== null ? formatPhoneNumber(leadData[0][field]) : "Click To Add"}
                                </> 
                            ) : (
                                <>
                                    {leadData[0][field] !== null ? leadData[0][field].replace(/\\/g, '') : "Click To Add"}
                                </>
                            )}
                        </>
                    )}
                </span>
            </div>
        )       
    );
    
}

export default ContactField;
