import React, { useState } from 'react';

const ToggleSwitch = ({isToggled, handleToggle, title}) => {


  return (
    <div className={`${title ? 'actualToggleWrapper':'' }`}>
      <label className="switch">
        <input type="checkbox" checked={isToggled} onChange={()=>handleToggle(false)} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;