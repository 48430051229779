import { fetchWithDefaults } from '../config/fetchDefault';

const gatherLeadAppointmentData = async (lead) => {
    try {
        
        const response = await fetchWithDefaults(`/gatherLeadAppointmentData?lead=${lead}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        if(jsonData.status===false){
            return false;
        }
        else{
            return jsonData.results;
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
    }
};

export {gatherLeadAppointmentData};