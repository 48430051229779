import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faComment } from '@fortawesome/pro-regular-svg-icons';


function MessageQueue({data, formatPhoneNumber, getLead,messageModal}) {
    
    return (
      <>
      {data.map((item, index) => (
        //console.log(item),
        <div className="leadQueueLead" id={item.lead_id} onClick={()=>getLead(item.lead_id)}>
          


        {!item.borrower_first && !item.borrower_last && item.message_to ? (
            <div className={`leadQueueName ${item.message_read == 'no' ? 'unreadMessage' : ''}`}>{formatPhoneNumber(item.message_to)}</div>
        ):(
            <div className={`leadQueueName ${item.message_read == 'no' ? 'unreadMessage' : ''}`}>
            <span className="leadQueueNameInner">{item.borrower_first} {item.borrower_last}</span>
            <span className="leadQueueOptionHolder">
                <span className="leadQueueOptionInner">
                <span className="leadQueueOption" onClick={(e)=>{e.stopPropagation();messageModal(item.lead_id);}}><FontAwesomeIcon icon={faComment}/></span>
                <span className="leadQueueOption"><FontAwesomeIcon icon={faTag}/></span>
                </span>
            </span>
            </div>
        )}

        <div className="leadQueueMessagePreview" dangerouslySetInnerHTML={{ __html: item.message }}></div>

          
        </div>
      ))}
      </>
    );
}

export default MessageQueue;