import React, { useState, useEffect } from 'react';
import App from './App';
//import './appThemeDark.css';
//import './appThemeLight.css';
import './appThemeFunnel.css';
import LoginPage from './template/login';
import { fetchWithDefaults } from './config/fetchDefault';
import ActionAlert from './actionAlert';
import { useDispatch } from 'react-redux';
import { setMissedCall } from './features/missedCallSlice';
import { setUserData } from './features/agentSlice';
import Signup from './template/signup/signup';

const { io } = require("socket.io-client");

function Init() {

    const dispatch = useDispatch();
    const [theme, setTheme] = useState('light');
    const [signedIn, setSignedIn] = useState(false);
    const [agentUsername, setAgentUsername] = useState('');
    const [agentIdNumber, setAgentIdNumber] = useState(null);
    const [agentPw, setAgentPw] = useState('');
    const [loginError, setLoginError] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [newMessages, setNewMessages] = useState([]);
    //const [missedCalls, setMissedCalls] = useState([]);
    const [currentURL, setCurrentURL] = useState(false);
    const [currentSearch, setCurrentSearch] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(false);
    const saveData = async (cipher) => {
      localStorage.setItem('keepMeAlive', cipher);
    };
    const saveSSO = async (cipher) => {
      localStorage.setItem('keepMeAlive', cipher);
      window.location.href = '/'
    };
    
    const [ stylePath, setStylePath ] = useState("/appThemeDark.css");
    useEffect(() => {
        // Get the current URL
        const currentUrl = window.location.pathname;
        const currentQuery=window.location.search;
        setCurrentSearch(currentQuery.split("code=")[1]);
        setCurrentURL(currentUrl);
    }, []);
  const handleButtonClick = () => {
    setStylePath({stylePath: 'style2.css'});
  }

    /*useEffect(() => {
      var head = document.head;
      var link = document.createElement("link");

      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = stylePath;
      console.log(link);
      head.appendChild(link);

      return () => { head.removeChild(link); }

    }, [stylePath]);*/
    useEffect(() => {
     /* var link = document.createElement("link");
      link.rel = "icon";
      link.href = 'https://app.ninjadialer.com/favicon.ico';
      document.head.appendChild(link);*/
      document.title = "Funnel";
    }, []);
    const signOut = () => {
      localStorage.removeItem('keepMeAlive');
      setSignedIn(false)
    };
    const loginUser = async () => {
      try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                username: agentUsername,
                password: agentPw,
            }) 
        };
      
        const response = await fetchWithDefaults('/loginUser', requestOptions);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData[0].permissions);
        if(jsonData[0].status=='update'){
          setUpdatePassword(true);
        }
        else if(jsonData[0].status==='beta'){
          window.location.href = 'https://app.ninjadialer.com/login';
        }
        else if(jsonData[0].status===true){
          await saveData(jsonData[0].cipher);
          setAgentIdNumber(jsonData[0].id)
          setSignedIn(true);
          let admin=false;
          if(jsonData[0].admin===1){
            admin=true;
          } 
          dispatch(setUserData({
            agentId: jsonData[0].id,
            organization: jsonData[0].organization,
            admin:admin,
            organizationName:jsonData[0].orgName,
            permissions:jsonData[0].permissions
          }))
        }
        else {
          setLoginError('Your username or password is incorrect!');
          setUpdatePassword(false);
          setSignedIn(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const handleSetUnread=(amount)=>{

      setUnreadMessages(amount)
    }
    useEffect( () => {
      if(agentIdNumber){
        const socketURL = `https://${window.location.hostname}:3333`;

        const socket = io(socketURL);
        //const socket = io('https://localhost:3333');
        socket.on("connect", (sock) => {
            console.log('socket set');
            socket.emit('initialization', agentIdNumber);
        });
        socket.on("newText", (arg) => {
          setNewMessages(prevMessages => [arg,...prevMessages]);
          let newUnread;

          if(unreadMessages===false){
            newUnread=1;
          }
          else{
            newUnread=parseInt((parseInt(unreadMessages) + 1));
          }
          handleSetUnread(newUnread);
        });

        socket.on("sentText", (arg) => {
            setNewMessages(prevMessages => [arg,...prevMessages]);
        });
        socket.on("missedCall", (arg)=>{
          dispatch(setMissedCall(arg.count)); 
        })
        socket.onAny((eventName, ...args) => {
          //console.log(eventName);
          //console.log(args);
        });
      }
      return () => {
        //setSignedIn(false);
        //notes
      };
    },[signedIn])

    return (
        signedIn && currentURL !=='/quilityAuth' && currentURL !=='/ssoLogin'  && currentURL !=='/signup' ? (
          <>
            <ActionAlert newMessages={newMessages}/>
            <App unreadMessages={unreadMessages} handleSetUnread={handleSetUnread} newMessages={newMessages} signedIn={signedIn} agentId={agentIdNumber} signOut={signOut}/>
          </>
        ) : currentURL==='/quilityAuth' ? ( 
            window.location.href='https://quility.auth0.com/authorize?response_type=code&client_id=kCMuOYXm4BSuAII1sJPoEeRjTgH9Pr1J&redirect_uri=https://app.ninjadialer.com/api/quility/authorize&scope=profile email read:Agents&audience=http://api.quility.com&state=init'
        ) : currentURL==='/ssoLogin' && currentSearch ? (
            saveSSO(currentSearch)
        ) : currentURL==='/signup' ? ( 
              <Signup/>
        ) : (
            <LoginPage setUpdatePassword={setUpdatePassword} saveData={saveData} loginError={loginError} setLoginError={setLoginError} setSignedIn={setSignedIn} setAgentIdNumber={setAgentIdNumber} updatePassword={updatePassword} agentPw={agentPw} loginUser={loginUser} agentUsername={agentUsername} setAgentUsername={setAgentUsername} setAgentPw={setAgentPw} />
        )
    )
}

export default Init;