import React from 'react';
import '../css/topBar.css';
import Block from './block';
import { faArrowProgress, faFiles, faGear, faMoneyCheckDollar, faObjectsColumn, faPeopleGroup, faPhone, faRotate, faScaleBalanced, faShuffle, faSquareCheck, faStore, faUserPlus, faWavePulse } from '@fortawesome/pro-regular-svg-icons';

function TopBar(props) {
    const barState=props.barState;
    function fetchPage(path){
       props.fetchPage(path);
    }
    
    const leads=[
        //{"name":"New Leads",'path':'New_Leads'},
        //{"name":"Active Leads",'path':'Active_Leads'},
        //{"name":"Clients",'path':'Clients'},
        //{"name":"Unresponsive",'path':'Workflow_followup'},
        //{"name":"Duplicate Leads",'path':'template/leads/new'},
        {"name":"Manage Leads",'path':'Add_Leads'},
        //{"name":"Delete Leads",'path':'template/leads/new'},
    ]
    const sync=[
        {"name":"Sync Leads", 'path':'syncLeads'}
    ]
    const todo=[
        {"name":"Follow-Ups",'path':'Follow_Ups'},
        //{"name":"Tasks",'path':'Tasks'},
        //{"name":"Goals",'path':'Goals'},
        {"name":"Calls",'path':'Scheduled_Calls'},
        {"name":"Appointments",'path':'Scheduled_Appointments'},
    ]
    const options=[
        //{"name":"Bulk Workflows",'path':'Bulk_Operations'},
        //{"name":"Bulk Tag",'path':'template/leads/new'},
        //{"name":"Bulk Status",'path':'template/leads/new'},
        //{"name":"Tutorials",'path':'template/leads/new'},
        {"name":"Subscription",'path':'Subscription'}
        //{"name":"Audio Input",'path':'template/leads/new'},
        //{"name":"Export Leads",'path':'template/leads/new'},
    ]

////////////
    const phone=[
        {"name":"Trust",'path':'Trust_Hub'},
        {"name":"Numbers",'path':'Number'},
        {"name":"Voicemail",'path':'Voicemail'},
        {"name":"Voice Drops",'path':'VoiceDrop'},
        //{"name":"Recordings",'path':'template/leads/new'},
        {"name":"Forwarding",'path':'Call_Forwarding'}
    ]
    const leadSettings=[
        {"name":"Statuses",'path':'Lead_Statuses'},
        {"name":"Tags",'path':'Tags'},
        {"name":"Types",'path':'Lead_Types'}
    ]
    const templates=[
        {"name":"Text",'path':'Text_Templates'},
        {"name":"Email",'path':'Email_Templates'},
        {"name":"Script",'path':'Scripts'},
        {"name":"Objection",'path':'Objections'}
    ]
    const appSettings=[
        {"name":"Integrations",'path':'Integrations'},
        //{"name":"Alerts",'path':'template/leads/new'}
    ]

    const dash=[{"name":"Dashboard",'path':'dash'}];
    const team=[{"name":"Teams",'path':'Teams'}]
    const market=[{"name":"Marektplace",'path':'Market'}]
    const overview=[{"name":"Overview",'path':'Overview'}]
    //const overview=[]
    const activity=[{"name":"Acitivy",'path':'Activity'}]
    const workflows=[{"name":"Workflows",'path':'Workflows'}];
    const referrals=[]
    
  return (
        <div className="leftHandBar">
            {barState ? (
                <>
                    <Block title="Dashboard" fetchPage={fetchPage} data={dash} icon={faObjectsColumn}/>
                    <Block title="Phone" fetchPage={fetchPage} data={phone} icon={faPhone}/>
                    <Block title="Lead Settings" fetchPage={fetchPage} data={leadSettings} icon={faUserPlus}/>
                    <Block title="Templates" fetchPage={fetchPage} data={templates} icon={faFiles}/>
                    <Block title="App Settings" fetchPage={fetchPage} data={appSettings} icon={faGear}/>
                </>
            ) : (
                <>
                    <Block title="Dashboard" fetchPage={fetchPage} data={dash} icon={faObjectsColumn}/>

                    <Block title="Add Leads" fetchPage={fetchPage} data={leads} icon={faUserPlus}/>
                    <Block title="To-Dos" fetchPage={fetchPage} data={todo} icon={faSquareCheck}/>
                    <Block title="Team" fetchPage={fetchPage} data={team} icon={faPeopleGroup}/>
                    <Block title="Subscription" fetchPage={fetchPage} data={options} icon={faMoneyCheckDollar}/>
                    <Block title="Activity" fetchPage={fetchPage} data={activity} icon={faWavePulse}/>
                    <Block title="Market" fetchPage={fetchPage} icon={faStore} data={market}/>
                    
                </>
            )}
        </div>
    );
}

export default TopBar;

/*
<Block title="Workflows" fetchPage={fetchPage} data={workflows} icon={faArrowProgress}/>
<Block title="Referrals" data={referrals} icon={faScaleBalanced}/>

<Block title="Overview" fetchPage={fetchPage} icon={faStore} data={overview}/>
<Block title="Market" fetchPage={fetchPage} data={market} icon={faStore}/>
<Block title="Overview" data={overview}/>
<Block title="Market" fetchPage={fetchPage} data={market}/>
*/