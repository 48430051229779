import React, { useState, useEffect } from "react";
import '../../css/templates.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/pro-regular-svg-icons";
import Reminder from './reminder';
import Workflow from './workflow';
import CreateWorkflow from './createWorkflow';
import EditWorkflow from "./editWorkflow";

function WorkflowMain({ data,formatDate, appInfo }) {
    const [editType, setEditType] = useState(false);
    const [addFlow, setAddFlow] = useState(false);
    const [newFlow, setAddNewFlow] = useState([]);
    const [editFlow, setEditFlow] = useState(false);
    const [workflows, setWorkflows] = useState(false);

    const handleNewFlow= (item) =>{
        setAddNewFlow((prevFlow) => [...prevFlow, item]);
    }

    const addAFlow = (type) => {
        setAddFlow(!addFlow);
        setEditType(type)
    };
    useEffect(() => {
        
        if(!workflows){
            setWorkflows(data[0].workflow);
        }
    },[workflows]);
    return (
        <div className="outerWrapper" style={{width:'92%', marginLeft:'5.5%'}}>
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">
                    <FontAwesomeIcon icon={faClipboardList} className="colorIcon" />
                </span>
                Workflows And Reminders
            </span>
            <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
            >
                {addFlow && <CreateWorkflow handleNewFlow={handleNewFlow} appInfo={appInfo} addAFlow={addAFlow} type={editType}/>}
                {editFlow ? (
                    <EditWorkflow setWorkflows={setWorkflows} workflows={workflows} editFlow={editFlow} setEditFlow={setEditFlow} />
                ) : (
                    <>
                        <Workflow setEditFlow={setEditFlow} addAFlow={addAFlow} newFlow={newFlow} addFlow={addFlow} setWorkflows={setWorkflows} workflows={workflows} formatDate={formatDate} appInfo={appInfo}/>
                        <Reminder addAFlow={addAFlow} addFlow={addFlow} data={[data[0].reminder]} formatDate={formatDate} appInfo={appInfo}/>
                    </>
                )}
            </div>
        </div>

    );
}

export default WorkflowMain;

