import { createSlice } from '@reduxjs/toolkit'

export const queueSlice = createSlice({
    name: 'queue',
    initialState: {
        queue: [],
        wholeQueue: [],
        originalQueue: []
    },
    reducers: {
        setQueue(state, action) {
            state.queue = action.payload;
        },
        setWholeQueue(state, action) {
            state.wholeQueue = action.payload;
        },
        addQueue(state, action) {
            state.queue.unshift(action.payload);
        },
        addToBackup(state, action) {
            //state.wholeQueue = [action.payload, ...state.wholeQueue];
            //state.wholeQueue = [...action.payload, ...state.wholeQueue];
            const combinedArray = [...action.payload, ...state.wholeQueue];
            const uniqueArray = combinedArray.reduce((acc, current) => {
                const x = acc.find(item => item.lead_id === current.lead_id);
                if (!x) {
                return acc.concat([current]);
                } else {
                return acc;
                }
            }, []);
            state.wholeQueue = uniqueArray;
        },
        setBackup(state, action) {
            state.originalQueue = action.payload;
        }
    },
})
export const { setQueue, addQueue, setBackup, setWholeQueue, addToBackup } = queueSlice.actions;

export default queueSlice.reducer;