import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';



const TermsOfService = ({terms, setTerms}) => {

  const toggleTerms= ()=>{
    setTerms(!terms);
  }
  useEffect(() => {
    
  }, []);

  return (
    <div>
      <h2>Terms of Service</h2>
      <div className="description">You will need to view our Terms of Service before you can sign up. Please click the link below</div>
      <div className="terms">
        {terms ? (
          <FontAwesomeIcon icon={faSquareCheck} style={{fontSize: '16px',color:'#008c00'}}/>
        ) : (
          <FontAwesomeIcon icon={faSquare} style={{fontSize: '16px'}}/>
        )}
        
          <a className="termsLink" target="_blank" href='https://hq.quility.com/api/public/document/315272/view/general-terms-of-service' onClick={()=>toggleTerms()}>Terms of Service</a>
        </div>
    </div>

  );
};

export default TermsOfService;
