import React, { useState } from "react";

function CreateCampaign({ setNewCampaign, handleSubmitBrand, brandInfo, service }) {
    const [formFields, setFormFields] = useState([
        { name: 'Website used to opt in', value: '', field: 'website', size: 'small' },
        { name: 'Scheduling Calendar', value: '', field: 'schedulingCalendar', size: 'small' },
        { name: 'Campaign Description', value: 'This campaign sends appointment information, confirmation of receipt of request and reminder messages to our customers once they have booked an appointment with us on our website and/or opted-in to receive promotional and notification SMS from us. As an independently contracted insurance agent with fewer resources than larger insurance corporations, we rely on a high level of customer service and reliable communication to maintain a profitable business. We use SMS to ensure customers who ask to be contacted are followed up with quickly. An example of this would be sending appointment reminders once my customers book an appointment on my online calendar. Whenever an appointment is booked and we receive consent to contact, I send an immediate SMS to confirm the appointment time with the customer. Another example would be sending an introductory text message after the customer provides their information and consents to be contacted, with follow up messages based on the customers response. All of our initial texts include opt-out information', field: 'description', size: 'big' },
        { name: 'How people opt in', value: 'End users opt-in by filling out a form in a social media ad that they view. They fill out the form, put in their phone number, select the ways they want to be contacted, and submit it in. They can also opt-in by calling the phone number provided on the ad, following the prompts on the phone to consent to be text messaged and called. This is displayed on my forms, online appointment booking, and more. Additionally, directly texting my number that appears in the ad.', field: 'messageFlow', size: 'big' },
        { name: 'Sample Message #1', value: 'Hello John Smith, this is Hendricks agency. I am the field underwriter following up on the life insurance quotes you requested, I can call you later today or I can ask you a few questions over text to get your quote - are you ok with answering a few questions over text? Reply STOP to opt out of texting', field: 'sample1', size: 'big' },
        { name: 'Sample Message #2', value: 'Hi John, this is just a confirmation for our insurance appointment on 11/01 at 10:00am.', field: 'sample2', size: 'big' }
    ]);

    const [brandSelection, setBrandSelection] = useState(false);
    const [type, setType] = useState(false);

    const handleBrandSelection = (brand, type) => {
        setBrandSelection(brand);
        setType(type);
    }

    // Function to handle input change
    const handleInputChange = (index, event) => {
        const { value } = event.target;
        const updatedFormFields = [...formFields];
        updatedFormFields[index].value = value;
        setFormFields(updatedFormFields);
    };

    return (
        <div className="quilityPopupFormWrapper">
            <div className="quilityPopupFormContent">
                <div className="quilityCloseWindow" onClick={() => setNewCampaign(false)}>X</div>
                <div className="formTitle">Create New Texting Campaign</div>

                {brandSelection ? (
                    <>
                        <div className="solidQuilityActiveButton startOverButton" onClick={() => setBrandSelection(false)}>Start Over</div>
                        <div className="campaignTrustFormWrapper">
                            <div className="subTitle" style={{ fontWeight: '700', textAlign: 'center' }}>Please review/alter/add to the information below. Scroll to bottom to submit</div>
                            {formFields.map((field, index) => (
                                field.size === 'small' ? (
                                    <div className="qualifierFieldWrapper" key={index}>
                                        <div className="campaignFormTitle">{field.name}</div>
                                        <input
                                            type="text"
                                            className="qualifierFieldInput"
                                            value={field.value}
                                            onChange={(event) => handleInputChange(index, event)} // Call handleInputChange when input changes
                                            placeholder="(Optional)"
                                        />
                                    </div>
                                ) : (
                                    <div className="campaignItemWrapper" key={index}>
                                        <div className="campaignFormTitle">{field.name}</div>
                                        <div className="largeFieldWrapper">
                                            <textarea
                                                className="campaignLargeInput"
                                                value={field.value}
                                                readOnly
                                                onChange={(event) => handleInputChange(index, event)} // Call handleInputChange when input changes
                                            />
                                        </div>
                                    </div>
                                )
                            ))}
                            <div className="formButtonWrapper">
                                <div className="solidQuilityDestructiveButton submitButton" onClick={() => setBrandSelection(false)}>Cancel</div>
                                <div className="solidQuilityActiveButton submitButton" onClick={()=>handleSubmitBrand(formFields,brandSelection,type)}>Submit</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {brandInfo ? (
                            <>
                                <div className="subTitle">Select the brand to use for this campaign</div>
                                {brandInfo.map((brand, index) => (
                                    <div key={index}>
                                        {brand.status === 'APPROVED' && (
                                            <div className="brandSelection solidQuilityActiveButton" onClick={() => handleBrandSelection(brand.sid, brand.brandType)}>Type: {brand.brandType} Brand - Status: {brand.status}</div>
                                        )}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>You need to create brand</>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default CreateCampaign;
