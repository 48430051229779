import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeterDroplet } from "@fortawesome/pro-regular-svg-icons";
import AudioElement from './audioElement';
import '../../css/recording.css';
import AddRecording from "./addVoiceRecording";


function VoiceDrops({data}) {
    const [voicemails, setVoicemails] = useState([]);
    const [addRecording, setAddRecording] = useState(false);
    const handAddRecording= () => {
        setAddRecording(!addRecording);
    }
    useEffect(() => {
        setVoicemails(data[0]);
    }, []);
    return (
        <div className="outerWrapper">
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">
                    <FontAwesomeIcon icon={faMeterDroplet} className="colorIcon" />
                </span>
                Voicemail Drops
            </span>
            <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
            >
           
                <div className="frontPageQuilityParentBlockWrapper" style={{width:'50%',position: 'relative'}}>
                    <div className="quilityParentBlockHeader">
                        <div className="quilityParentBlockTitle">Voice Drops</div>

                    </div>
                   {addRecording ? (
                        <div className="quilityParentBlockContent">
                            <div className="quilityCloseWindow" onClick={()=>setAddRecording(false)}>X</div>
                            <AddRecording setVoicemails={setVoicemails} voicemails={voicemails} setAddRecording={setAddRecording} type='voiceDrop'/>
                        </div>
                   ) : (
                       <div className="quilityParentBlockContent">
                            {voicemails && voicemails.map((item, index) => (
                                <AudioElement type="voicemails" key={`voicemails${index}`} index={index} audio={item}/>
                            ))}
                        </div>
                   )}
                    
                    <div className="blockFooter">
                        <div
                            className="buttonWrapper"
                            style={{ marginTop: " 5px", marginLeft: "10px" }}
                        >
                            <div className="dataButtonBlock">Recordings: {data[0].length}</div>
                            <div className="actionButtonBlock" style={{width: '150px'}}>
                                <div className="accentButton solidQuilityActiveButton" onClick={()=>handAddRecording()}>
                                +  New Recording
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VoiceDrops;


