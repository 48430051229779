import React, {useState, useEffect} from 'react';
import '../../css/leadCard.css';
import LeadCardTopBar from './leadCardTopBar';
import EditEvent from '../callQueue/elements/sections/editEvent';
import AddEvent from '../callQueue/elements/sections/addEvent';
import ClientCard from './clientCard';
import { fetchWithDefaults } from '../../config/fetchDefault';
import LeadData from './leadData';
import { useSelector } from 'react-redux';




function LeadCard({setLoading, data, setCall, textingNumbers,handleSoftPhone, device, setCallerId, maxList, newAppt, setNewAppt}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [qualifier, setQualifier] = useState(false);
    const [alreadyTagged, setAlreadyTagged] = useState(false);
    const [customColumns, setCustomColumns] = useState([{left:5,right:null,column:'height',display_name:'Height'},{left:6,right:null,column:'weight',display_name:'Weight'},{left:7,right:null,column:'borrower_dob',display_name:'D.O.B.'},{left:8,right:null,column:'borrower_age',display_name:'Age'},{left:9,right:null,column:'lender',display_name:'Mortgage Lender'},{left:10,right:null,column:'mortgage',display_name:'Mortgage Amount'},{left:11,right:null,column:'home_value',display_name:'Home Value'},{left:12,right:null,column:'term_length',display_name:'Loan Term Length'},{left:13,right:null,column:'loan_type',display_name:'Loan Type'},{left:14,right:null,column:'payment',display_name:'Loan Payment Amount'},{left:15,right:null,column:'tobacco',display_name:'Tobacco Use'},{left:16,right:null,column:'email',display_name:'Email'},{left:null,right:18,column:'co_borrower_home',display_name:'Co-Contact Home Number'},{left:null,right:19,column:'co_borrower_cell',display_name:'Co-Contact Cell Number'},{left:null,right:20,column:'co_borrower_work',display_name:'Co-Contact Work Number'},{left:null,right:21,column:'co_borrower_sex',display_name:'Co-Contact Sex'},{left:null,right:22,column:'co_borrower_height',display_name:'Co-Contact Height'},{left:null,right:23,column:'co_borrower_weight',display_name:'Co-Contact Weight'},{left:null,right:24,column:'co_borrower_tobacco',display_name:'Co-Contact Tobacco'},{left:null,right:25,column:'co_borrower_dob',display_name:'Co-Contact D.O.B.'},{left:null,right:26,column:'co_borrower_age',display_name:'Co-Contact Age'},{left:null,right:27,column:'co_borrower_occupation',display_name:'Co-Contact Occupation'},{left:null,right:28,column:'co_borrower_email',display_name:'Co-Contact Email'},{left:3,right:null,column:'borrower_work',display_name:'Work'},{left:2,right:null,column:'borrower_home',display_name:'Home'},{left:1,right:null,column:'borrower_cell',display_name:'Cell'},{left:4,right:null,column:'borrower_sex',display_name:'Sex'}])
    const [leftRows, setLeftRows] = useState([]);
    const [rightRows, setRightRows] = useState([]);
    const agentId=agentInfo.agentId;
    const [newEvent, setNewEvent] = useState(false);
    const [date, setDate] = useState(false);
    const [time, setTime] = useState(false);
    const [event, setEvent] = useState(false);
    const [calendar, setCalendar] = useState(agentInfo.defaultCalendar);
    const [calendarUsing, setCalendarUsing] = useState(false);

    const calendarChange=(calendar)=>{
        setCalendar(calendar);
    }
    ///format numbers
    function formatNumber(phoneNumber){
        const cleaned = phoneNumber.toString().replace(/\D/g, ''); // Remove all non-digit characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber; 
    }
    const handleQualifier=()=>{
        setQualifier(!qualifier);
    }
    /*
    const openLead = async () => {
        try {
            
            const response = await fetchWithDefaults(`/findColumns?agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setCustomColumns(jsonData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    */
    const setAppt= async (event,video)=>{
        try {

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    team: agentInfo.teamId,
                    leadId:data[0].lead_id,
                    event:event,
                    video:video
                }),
            };
            const response = await fetchWithDefaults('/setAppointment', requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setNewAppt(jsonData.event)
            setNewEvent(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }


    function formatBirthDate(inputDate) {
        // Create a Date object from the input date string
        const date = new Date(inputDate);
      
        // Get the month, day, and year
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
      
        // Format the date as mm/dd/YYYY
        const formattedDate = `${month}/${day}/${year}`;
      
        return formattedDate;
    }
    function transformTags(input) {
        // Split the input string by comma to get individual pairs
        const pairs = input.split(',');
      
        // Transform each pair into the desired format
        const transformedArray = pairs.map(pair => {
          // Split each pair by '*' to get id and name
          const [id, name] = pair.split('*');
          
          return { name, id };
        });
      
        return transformedArray;
    }
    useEffect(() => {
        categorizeRows();

    }, [customColumns]);
    useEffect(() => {
        if(data[0].tag_ids){
            const tag=transformTags(data[0].tag_ids); 
            setAlreadyTagged(tag);
        }

    }, [data[0].tag_ids]);
    
    const categorizeRows = () => {
        // Reset leftRows and rightRows
        setLeftRows([]);
        setRightRows([]);

        // Iterate through the data and categorize rows
        customColumns.length > 0 && customColumns.forEach((leadItem) => {
            if (leadItem.left === null) {
                // Push to the 'right' element
                setRightRows((prevRightRows) => [...prevRightRows, leadItem]);
            } else if (leadItem.right === null) {
                // Push to the 'left' element
                setLeftRows((prevLeftRows) => [...prevLeftRows, leadItem]);
            }
        });
    };
    const makeDial= async (toNumber, callee, fromNum)=>{

        if(!fromNum){
            fromNum=data[0].call_from
        }

        const call= await device.connect({ params: { To: '+1'+toNumber , callerId: fromNum, user:agentId, tone: "default", leadId:data[0].lead_id }});
        setCall(call);
        handleSoftPhone(call.status());
        setCallerId({name:callee, lead_id:data[0].lead_id});
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    team: agentInfo.teamId,
                    agentNumber:fromNum,
                    clientNumber:toNumber,
                    leadId:data[0].lead_id
                }),
            };
            
            const response = await fetchWithDefaults('/queueDial', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const newEventPackage=[{setNewEvent:setNewEvent, setTime:setTime, setDate:setDate}]
    const [timestamp, setTimestamp] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTimestamp = Math.floor(Date.now() / 1000);
            setTimestamp(currentTimestamp);
        }, 1000);
  
        return () => clearInterval(interval);
    }, []);
    
    const expiration=(parseInt(data[0].exclusivity) - parseInt(timestamp));
    return (
        <div className="leadCardWrapper">
            <div className="closeWindow" onClick={() => maxList()}>X</div>
            <LeadCardTopBar expiration={expiration} setLoading={setLoading} textingNumbers={textingNumbers} qualifier={qualifier} handleQualifier={handleQualifier} calendarChange={calendarChange} setCalendarUsing={setCalendarUsing} setEvent={setEvent} newEventPackage={newEventPackage} newAppt={newAppt} setNewAppt={setNewAppt} setNewEvent={setNewEvent} data={data}/>
            {data[0].parent_status==='client' ? (
                <ClientCard agentId={agentId} data={data}formatNumber={formatNumber} formatBirthDate={formatBirthDate}/>
            ):(   
                <LeadData expiration={expiration} alreadyTagged={alreadyTagged} qualifier={qualifier} makeDial={makeDial} lead={data} handleQualifier={handleQualifier}/>
            )}
            {event &&(
                <EditEvent setEvent={setEvent} calendar={calendar} event={event} />
            )}
            {newEvent &&(
                <AddEvent setAppt={setAppt} calendar={calendar} setNewEvent={setNewEvent} date={date} time={time} currentLead={data[0]} />
            )}
        </div>
    );
}

export default LeadCard;