import React, {useState, useEffect} from 'react';
import Warning from '../../../warning';
import MarketInfo from '../../marketInfo';
import PurchasePage from '../../purchasePage';

function SpecType({ title, data, setupNavTiles}) {
    const [newData, setData] = useState(false);    
    const [warning, setWarning] = useState(false);
    const [purchase, setPurchase] = useState(false);
    const cancel = () => {
        setWarning(false);
    };
    
    const removeItem = (id,index) => {
        setWarning(id);
    };
    const handleClose=()=>{
        setWarning(false);
    }
    const gatherData= async()=>{

    }
    useEffect(() => {
        gatherData();
        const matchingItem = data[0].types[0].find((item) => item.name === title);

    if (matchingItem) {
      setData(matchingItem);
    }
    },[])
    const purchaseNotice = ()=>{
        setPurchase(!purchase)
    }
    return (
        <>
            {purchase ? (
                <PurchasePage purchaseNotice={purchaseNotice} title={title} data={newData}/>
            ) : (
                <div className={`quilityParentBlockWrapper`} style={{width: '50%', marginTop:'50px'}}>
                    <div className="quilityParentBlockHeader">
                        <div className="quilityParentBlockTitle">{title}</div>
                    </div>
                    
                    <div className="quilityParentBlockContent">
                        {newData ? (
                            
                            <div className={`quilityNestedBlockWrapper `} key={'index12'} style={{marginTop: '25px'}}>
                                <div className="quilityParentBlockHeader">
                                    <div className="quilityParentBlockTitle">{title}</div>
                                </div>
                                <div className="innerWrapper">
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '150px'}}>Lead Vendor: </span>
                                        <span className="quilityDataLineValue">{newData.vendorName}</span>
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '150px'}}>Type of Lead: </span>
                                        <span className="quilityDataLineValue">{newData.display_type}</span>
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '150px'}}>Minimum Order: </span>
                                        <span className="quilityDataLineValue">{newData.min_order}</span>
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '150px'}}>Fulfillment Period: </span>
                                        <span className="quilityDataLineValue">{newData.fulfillment} Days</span>
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '150px'}}>Price Per Lead: </span>
                                        <span className="quilityDataLineValue">$ {newData.price}</span>
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle" style={{display: 'inline-block',width: '150px'}}>Description: </span>
                                        <div style={{margin:'5px 0px'}}>{newData.description}</div>
                                    </div>
                                    
                                    <span className="schedulefunctionButtons">

                                    </span>
                                </div>
                            </div>
                        ) : (
                            null
                        )}
                    </div>
                    <div className="blockFooter">
                        <div
                            className="buttonWrapper"
                            style={{ marginTop: " 5px", marginLeft: "10px" }}
                        >
                            <div className="accentButton solidQuilityActiveButton" style={{display:'inline-block'}} onClick={()=>purchaseNotice()}>Purchase Leads+</div>
                            <div className="actionButtonBlock" style={{width: '150px'}}>

                            </div>
                        </div>
                    </div>
                    {warning && (
                        <MarketInfo handleClose={handleClose} setupNavTiles={setupNavTiles} title="New To LevelUp" message="It looks like you're new to LevelUp Leads! That is great, but before you are able to receive your leads, there are a couple of things you need to do first!" />
                    )}
                </div>
            )}
        </>
    );
}

export default SpecType;
