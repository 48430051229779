import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faComment } from '@fortawesome/pro-regular-svg-icons';


function DialQueue({data, formatDate, getLead,messageModal}) {
    return (
      <>
      {data.map((item, index) => (
        //console.log(item),
        <div className="leadQueueLead" id={item.lead_id} onClick={()=>getLead(item.lead_id)}>
            

            <div className={`leadQueueName ${item.message_read == 'no' ? 'unreadMessage' : ''}`}>
            <span className="leadQueueNameInner">{item.borrower_first} {item.borrower_last}</span>
            <span className="leadQueueOptionHolder">
                <span className="leadQueueOptionInner">
                <span className="leadQueueOption" onClick={(e)=>{e.stopPropagation();messageModal(item.lead_id);}}><FontAwesomeIcon icon={faComment}/></span>
                <span className="leadQueueOption"><FontAwesomeIcon icon={faTag}/></span>
                </span>
            </span>
            <div className="leadQueueStatus">{item.name ?? "No Status"}</div>
            <div className="leadQueueContact">{formatDate(item.last_contacted)}</div>
            
            </div>

        </div>
      ))}
      </>
    );
}

export default DialQueue;