import React from "react";
import '../../css/workflow.css';


function NameFlow({flowName, flowNameError, handleFlowName, status, setAttachedStatus, attachedStatus}) {

    return (
        <div className="bigBlockAdjustment">
            <div className="bigBlock" style={{marginTop: '25px'}}>
                <div className="leadCardSubTitle">
                    {flowName != '' ? (
                       flowName
                    ) : (
                        "Name Workflow"
                    )}
                </div>
                <div className="leadCardBlock">
                    <div className="innerContentWrapper">
                        <div className="systemInfoItem">
                            <div className="errorMessage">{flowNameError}</div>
                            <span className="itemTitle">Name: </span>
                            <input type="text" className="standardInputBox" value={flowName&&(flowName)} onChange={(e) => handleFlowName(e.target.value)}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bigBlock" style={{marginTop: '25px'}}>
                <div className="leadCardSubTitle">
                    Attach Workflow To Status
                </div>
                <div className="leadCardBlock">
                    <div className="innerContentWrapper">
                        <div className="systemInfoItem">
                            <span className="itemTitle">Attach a Status (optional) </span>
                            <div style={{marginTop:'15px'}}>
                                <select type="text" className="standardInputBox" value={attachedStatus ? (attachedStatus):(null)} onChange={(e)=>setAttachedStatus(e.target.value)}>
                                    <option value="none">Optionally Attach to a status</option>
                                    {status.map((item, index) => (    
                                        item.workflow != null ? (
                                            <option value={item.id} key={`nameFlow${index}`}>{item.name}</option>
                                        ) :(
                                            <option value={item.id}  key={`nameFlow${index}`} disabled>{item.name}</option>
                                        )
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NameFlow;

