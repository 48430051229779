import React, {useEffect, useState} from 'react';
import '../css/login.css';
import { fetchWithDefaults } from '../config/fetchDefault';


function ResetPassword({handleResetPassword}) {
    const [username, setUsername] = useState('');
    const [sent, setSent] = useState(false);
    const handleUsername = (e) => {
        const usernameData = e.target.value;
        setUsername(usernameData);
    };
    const submitReset = async ()=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    username:username
                }) 
            };
            const response = await fetchWithDefaults('/resetPassword', requestOptions);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            setSent(true);
            const jsonData = await response.json();
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleClose=()=>{
        handleResetPassword(false);
    }
    useEffect(() => {

    },[])
    return (
        <div className="warningWrapper">
        <div className="warningBody" style={{height: '350px',maxWidth:'750px'}}>
            <div className="quilityCloseWindow" onClick={()=> handleClose()} style={{color: '#ffffff',borderColor: '#ffffff'}}>X</div>
            <div className="warningHeader">
                Reset Password
            </div>
            {sent ? (
                <>
                    <div className="warningContent">
                        Your reset request has been processed. You should receive and email shortly with a reset link!
                    </div>
                    <div className="warningButtonHolder">
                        <div className="warningButtonWrapper">

                            <div className="accentButton warningButton" key={'warning2'} onClick={()=> handleClose()}>Done</div>

                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="warningContent">
                        Please Provide your username, and we will send a reset link to your email on file!
                        <div className="inputHolder">
                            <div>
                                <div style={{margin: '20px 0px 10px'}}>Username:</div>
                                <input
                                    type="text"
                                    className="qualifierFieldInput"
                                    value={username}
                                    onChange={handleUsername}
                                />
                            </div>
                        
                        </div>
                    </div>
                    <div className="warningButtonHolder">
                        <div className="warningButtonWrapper">

                            <div className="accentButton warningButton" key={'warning2'} onClick={()=> submitReset()}>Submit</div>

                        </div>
                    </div>
                </>
            )}
           
            
        </div>
    </div>
    );
}

export default ResetPassword;