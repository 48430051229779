import React, {useState} from 'react';
import '../../css/teams.css';
import AgentDetail from './agentDetail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/pro-regular-svg-icons';
import BigTeamBlock from './bigTeamBlock';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';
import AddTeam from './addTeam';


function Teams({ data, isMin, openLead,maxList}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const agentId=agentInfo.agentId;
    const [agencies, setAgencies] = useState((data && data[1] && data[1].agencies) || []);
    const [imoEmail, setImoEmail] = useState('');
    const [teams, setTeams] = useState((data && data[0] && data[0].teams) || []);
    const [imoLink, setImoLink] = useState(false);
    const [myImo, setMyImo] = useState(null);
    const imo = ['Quility'];
    const [smallTeam, setSmallTeam] = useState(false);
    const [addTeam, setAddTeam] = useState(false);
    const [agentName, setAgentName] = useState();
    const [linked, setLinked] = useState(false);
    const [agentDetail, setAgentDetail] = useState([]);
    console.log(data);
    const handleInputChange = (e) => {
        setImoEmail(e.target.value);
    };
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert to milliseconds
    
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
    
        const formattedDate = `${month}/${day} @ ${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}${hours >= 12 ? 'pm' : 'am'}`;
    
        return formattedDate;
    };
    const details= async (agent, name, range)=>{

        setAgentName(name);
        const currentDate = new Date();
        const dateRange = new Date(currentDate);
        dateRange.setDate(currentDate.getDate() - range);
        const yyyymmdd = dateRange.toISOString().split('T')[0];
    
        // Calculate the date and time 7 days ago
    
        dateRange.setDate(currentDate.getDate() - range);
        dateRange.setHours(0, 0, 0, 0); // Set time to midnight
    
        // Get the Unix timestamp (in seconds) for the calculated date and time
        const unixTimestamp = Math.floor(dateRange.getTime() / 1000);
        try {
            const response = await fetchWithDefaults(`/agentDetail?agent=${agent}&unixTimestamp=${unixTimestamp}&yyyymmdd=${yyyymmdd}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setAgentDetail(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setSmallTeam(true);
    }
    const findAgent= async ()=>{
        try {
            const response = await fetchWithDefaults(`/searchImo?email=${imoEmail}&imo=${myImo}&agent=${agentId}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status){
                setLinked({status:true, message:'Your IMO has been linked! Please Refresh the page to see your team.', color:'#008000'});
                setMyImo(null);
                setImoLink(false);
                setImoEmail('');
            }
            else{
                setLinked({status:false, message:'Your email could not be found in your IMO', color:'#b80f03'});
                setMyImo(null);
                setImoLink(false);
                setImoEmail('');
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const enlarge=()=>{
        setAgentDetail([]);
    }
    const closeLink=()=>{
        setMyImo(null);
        setImoLink(false);
        setImoEmail('');
    }
    const handleAddTeam=()=>{
        setAddTeam(!addTeam);
    }
    return (
        <div className="outerWrapper" style={{width: '90%',marginLeft: '5%',height: 'calc(100% - 200px)'}}>
            <span className="outerWrapperTitle"><span className="outerWrapperIcon"><FontAwesomeIcon icon={faPeopleGroup} className="colorIcon" /></span>Teams</span>
            {addTeam && (
                <AddTeam handleAddTeam={handleAddTeam}/>
            )}
            <div className="teamsWrapper mainBlockOuterWrapper">
                {agentDetail.length > 0 ? ( 
                    <AgentDetail 
                        enlarge={enlarge} 
                        smallTeam={smallTeam}
                        agentDetail={agentDetail}
                        formatDate={formatDate}
                        agentName={agentName}
                        openLead={openLead}
                        isMin={isMin}
                        maxList={maxList}
                    />
                ) : (
                    <>
                        <div className="dashSplit">
                            {}
                            <BigTeamBlock details={details} data={agencies} linked={linked} setImoLink={setImoLink} title={`${agentInfo.organizationName} Team`} />
                        </div>
                        <div className="dashSplit">
                            <BigTeamBlock handleAddTeam={handleAddTeam} details={details} data={teams} title="Your Teams"/>
                        </div>
                    </>
                )}
                
            </div>
            {imoLink && (
                <div className="imoLinkWrapper">
                    <div className="imoLinkContent">
                        <div className="quilityCloseWindow" onClick={()=>closeLink()}>x</div>
                        {myImo===null ? imo.map((imo, index) => (
                            <>
                                <div className="title">Choose Your IMO</div>
                                <div className="imoOptionButton solidQuilityActiveButton" key={`imo${index}`} onClick={()=>setMyImo(imo)}>{imo}</div>
                            </>
                        )) : (
                            <>
                                <div className="title">Find yourself</div>
                                <div className="inputWrapper" style={{textAlign: 'center'}}>
                                    <div className="subTitle">Enter email associated with {myImo}</div>
                                    <input 
                                        className="emailInput qualifierFieldInput"
                                        value={imoEmail}
                                        onChange={handleInputChange}
                                        placeholder="Enter Email"
                                    />
                                    <div className="imoOptionButton solidQuilityActiveButton" onClick={()=>findAgent()}>Find Me</div>
                                </div>
                            </>
                        )} 
                    </div>
                </div>
            )}
        </div>
    );
}

export default Teams;


